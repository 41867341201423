const menu = [
  {
    title: 'Dashboard',
    path: '/',
    icon: '/images/dashboard1.svg',
    roleaccess: [1, 3],
  },
  {
    title: 'Vendor Dashboard',
    path: '/vendor-dashboard',
    icon: '/images/dashboard.svg',
    roleaccess: [2],
  },
  {
    title: 'Users',
    path: '/user',
    icon: '/images/user1.svg',
    roleaccess: [1],
  },
  {
    title: 'Departments',
    path: '/department',
    icon: '/images/department.svg',
    roleaccess: [1],
  },
  {
    title: 'Tenders',
    path: '/tender',
    icon: '/images/tender.svg',
    roleaccess: [1, 2, 3],
  },
  {
    title: 'Vendors',
    path: '/vendor',
    icon: '/images/vendor.svg',
    roleaccess: [1, 3],
  },
  {
    title: 'Employees',
    path: '/employee',
    icon: '/images/employee.svg',
    roleaccess: [1, 2, 3],
  },
  {
    title: 'Employee Salary Details',
    path: '/report',
    icon: '/images/employee_salary.svg',
    roleaccess: [1, 2, 3],
  },
  {
    title: 'Employee Attendence',
    path: '/bulk-attendence',
    icon: '/images/empty-task.svg',
    roleaccess: [1, 2],
  },
  {
    title: 'Log Out',
    path: '#',
    icon: '/images/logout.svg',
    submenu: [],
    roleaccess: [1, 2, 3],
  },
];

export default menu;
