import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Card, Col, Row, Image, Accordion } from "react-bootstrap";
import { TextInput, Helmet } from "../../components/FormInputs";
import Breadcrumb from "../../components/Breadcrumb/breadcrumb.component";
import swal from "sweetalert";
import Api from "../../helper/api";
import AuthApi from "../../helper/authApi";
import customFunctions from "../../helper/customFunctions";
import Loader from "../../components/Loader";
import Table from "../../components/Table/simpletable.component";
import SweetAlert from "react-bootstrap-sweetalert";

const UserListing = () => {
  const navigate = useNavigate();
  const title = "Users";
  const [isShowLoader, setShowLoader] = useState<any>(false);
  const [rows, setRows] = useState<any>([]);
  const [filterErr, setFilterErr] = useState<any>("");
  const [searchKeyword, setSearchKeyword] = useState<any>("");
  const [roleId, setRoleId] = useState<any>(null);
  const [userData, setUserData] = useState<any>([]);
  const [statusToggle, setStatusToggle] = useState<boolean>(false);
  const [currentUserId, setCurrentUserId] = useState<number>(0);
  const [applyToggle, setApplyToggle] = useState<any>(false);
  const [statusId, setStatusId] = useState<any>("");
  const [userId, setUserId] = useState<any>(null);

  const items = [{ to: "/", label: "Dashboard" }, { label: title }];

  const initialPagination = {
    currentPage: 1,
    itemsPerPage: 10,
    totalItems: 1,
  };
  const [pagination, setPagination] = useState(initialPagination);

  const columns = [
    {
      label: "Name",
      id: "first_name",
    },
    {
      label: "Role",
      id: "role",
    },
    {
      label: "Department Name",
      id: "department_name",
    },
    {
      label: "Mobile",
      id: "mobile_number",
    },
    {
      label: "Email",
      id: "email",
    },
    // {
    //   label: "Status",
    //   id: "status",
    // },
    {
      label: "Action",
      id: "action",
    },
  ];
  useEffect(() => {
    getUserRole();
    getUserListData();
  }, []);

  useEffect(() => {
    setTableData();
  }, [userData]);

  const getUserRole = async () => {
    const roleId: any = await customFunctions.getLocalStorage("role_id");
    setRoleId(JSON.parse(roleId));
    const currentUserId: any = customFunctions.getLocalStorage("id");
    setCurrentUserId(Number(currentUserId));
  };

  const getUserListData = async (pageNo?: any, clearFilter?: boolean) => {
    // if (!currentUserId || (!applyToggle && clearFilter && !searchKeyword))
    // return;
    const page: any = pageNo || 1;
    setShowLoader(true);
    setApplyToggle(false);
    const endPoint: any = `${Api.userUrl}/getAllUser`;

    const params: any = {
      page: page,
      limit: pagination.itemsPerPage,
      search: !clearFilter && searchKeyword ? searchKeyword.trim() : "",
      sort_direction: "descending",
    };

    const url: any = customFunctions.generateUrl(endPoint, params);
    const { data, message } = await AuthApi.getDataFromServer(url);
    const Arr: any = [];
    if (data && data.success === true) {
      setShowLoader(false);
      if (data && data.data) {
        setPagination({
          itemsPerPage: Number(10),
          currentPage: data.data.page,
          totalItems: data.data.count,
        });
        data.data.response.map((item: any) => {
          if (item?.roleId != 2) {
            Arr.push({
              id: item.id,
              first_name: item.fname,
              last_name: item.lname,
              adhar_card: item.adhar_card,
              email: item.email,
              password: item.password,
              mobile_number: item.phone_no,
              role: item.role.name,
              roleId: item.roleId,
              status: item.status,
              department_name: item?.dept_user_meta[0]?.department?.name
            });
          }
        });
        setUserData(Arr);
      } else {
        setUserData([]);
      }
    } else {
      setShowLoader(false);
      setUserData([]);
      swal(message, "", "error");
    }
  };

  const deleteUser = async (id: any) => {
    // const payload:any={
    //   id:id
    // }
    setShowLoader(true);
    const endpoint: any = `${Api.userUrl}/?id=${id}`;
    const { data, message } = await AuthApi.deleteDataFromServer(endpoint);
    if (data && data.success === true) {
      setStatusToggle(false);
      swal("User deleted successfully!", "", "success", {
        closeOnClickOutside: false,
      }).then(() => {
        setUserId(null);
        setStatusId("");
        getUserListData(1);
      });
    } else {
      setShowLoader(false);
      swal(message, "", "error").then(() => {
        setUserId(null);
        setStatusId("");
        getUserListData(1);
      });
    }
  };

  const setTableData = () => {
    const rowsData: any = [];

    userData.map((dt: any, index: any) => {
      rowsData.push({
        id: dt?.id,
        department_name: dt?.roleId == 3 && dt?.department_name ? dt?.department_name : "-",
        mobile_number: dt?.mobile_number,
        first_name:
          dt && dt?.first_name
            ? customFunctions.capitalizeFirstLetter(dt?.first_name) +
            " " +
            customFunctions.capitalizeFirstLetter(dt?.last_name)
            : null,
        middle_name: dt?.middle_name,
        last_name: dt?.last_name,
        adhar_card: dt?.adhar_card,
        role: dt?.role,
        email: dt?.email,
        // status: (
        //   <>
        //     <div className="switch-check">
        //       <div
        //         className="form-check form-switch"
        //         style={{ marginBottom: "0" }}
        //       >
        //         <input
        //           className="form-check-input"
        //           type="checkbox"
        //           role="switch"
        //           id="flexSwitchCheckDefault"
        //           checked={dt?.status == "1" ? true : false}
        //           onClick={() => {
        //             setStatusToggle(true);
        //             setStatusId(dt?.status == "2" ? "1" : "2");
        //             setUserId(dt?.id);
        //           }}
        //           title={dt?.status == "1" ? "Active" : "Inactive"}
        //         />
        //       </div>
        //     </div>
        //   </>
        // ),
        action: (
          <>
            <a
              href="/"
              className="pl-2 ms-1"
              title={"Edit"}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                navigate("/edit-user", {
                  state: { data: dt, id: dt.id, pageType: "edit" },
                });
              }}
            >
              <Image src="images/edit-button.svg" alt="edit" />
            </a>
            {dt?.roleId != 1 &&
              <Image
                src="images/delete.png"
                alt="delete"
                title='Delete'
                className="pl-2 ms-1"
                onClick={(e: any) => {
                  e.preventDefault();
                  e.stopPropagation();
                  swal({
                    title: "Are you sure?",
                    text: "you want to delete this user record.",
                    icon: "warning",
                    buttons: ["Cancel", true],
                    dangerMode: true,
                  }).then((willDelete) => {
                    if (willDelete) {
                      deleteUser(dt?.id);
                    }
                  });
                  setStatusToggle(true);
                }}
              />
            }
          </>
        ),
      });
      return dt;
    });
    setRows([...rowsData]);
  };

  const changeStatus = async () => {
    // const postData: any = {
    //   id: Number(userId),
    //   status: statusId,
    // };
    // if (!userId) return;
    // setShowLoader(true);
    // const endpoint: any = `${Api.userUrl}/?id=${userId}`;
    // const { data, message } = await AuthApi.deleteDataFromServer(endpoint, postData);
    // if (data && data.success === true) {
    //   setStatusToggle(false);
    //   swal("User status changed successfully!", "", "success", {
    //     closeOnClickOutside: false,
    //   }).then(() => {
    //     setUserId(null)
    //     setStatusId("");
    //     getUserListData(1);
    //   });
    // } else {
    //   setShowLoader(false);
    //   swal( message, "", "error").then(() => {
    //     setUserId(null)
    //     setStatusId("");
    //     getUserListData(1);
    //   });
    // }
  };

  const filterSearch = (e: any) => {
    e.preventDefault();
    let filterSearchErr: string = "";
    let isValid: boolean = true;
    if (customFunctions.checkIfEmpty(searchKeyword)) {
      filterSearchErr = "Please enter user name";
      setFilterErr(filterSearchErr);
      isValid = false;
    }
    if (isValid) {
      setFilterErr("");
      getUserListData(1);
    }
  };

  const onClear = (e: any) => {
    e.preventDefault();
    setFilterErr("");
    setSearchKeyword("");
    getUserListData(1, true);
  };

  return (
    <>
      <>
        <Helmet title={title} />
        <div className="page-header">
          <div className="title-breadcrumb-section">
            <h2 className="main-content-title tx-24 mg-b-5">{title}</h2>
            <Breadcrumb items={items} />
          </div>
        </div>
        {/* {statusToggle && (
          <div className="sweetalert">
            <SweetAlert
              title={"Are you sure, do you want to change the status ?"}
              warning
              showCancel
              confirmBtnBsStyle="success"
              cancelBtnBsStyle="danger"
              onConfirm={() => {
                setStatusToggle(false);
                changeStatus();
              }}
              onCancel={() => {
                setStatusToggle(false);
              }}
            />
          </div>
        )} */}
        <Loader showLoader={isShowLoader} needFullPage={false} />
        <section className="content-area">
          <Accordion defaultActiveKey="1">
            <Accordion.Item eventKey="1">
              <Accordion.Header>
                <h5 className="card-title mb-0">Filters</h5>
              </Accordion.Header>
              <Accordion.Body>
                {filterErr && (
                  <div className="p-3  bg-danger text-white bg-danger-main table-red-bg mb-3">
                    <div className="img-text">
                      <img
                        style={{ cursor: "pointer" }}
                        onClick={(e) => onClear(e)}
                        src="/images/fail.svg"
                        alt="Success"
                      />{" "}
                      {filterErr}
                    </div>
                  </div>
                )}
                <Row className="mt-3">
                  <Col sm={3} md={3} lg={3}>
                    <TextInput
                      id="search-keyword"
                      value={searchKeyword}
                      placeholder="Search by name"
                      onInputChange={(e: any) => {
                        setSearchKeyword(e.target.value);
                      }}
                    />
                  </Col>

                  <Col sm={3} md={3} lg={3}>
                    <div className="filter-btn pull-right filter-btn-report">
                      <Button
                        id="submit-btn"
                        variant="primary"
                        type="submit"
                        className="ms-4"
                        onClick={(e) => {
                          e.preventDefault();
                          filterSearch(e);
                        }}
                      >
                        Submit
                      </Button>
                      <Button
                        id="clear-btn"
                        variant="secondary"
                        type="button"
                        className="ms-4"
                        onClick={(e) => onClear(e)}
                      >
                        Clear
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <Card className="mt-3">
            <Card.Header>
              <div className="d-flex align-items-center justify-content-end">
                <a
                  href="/add-user"
                  className="underline"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate("/add-user", {
                      state: { pageType: "add" },
                    });
                  }}
                >
                  Add User
                </a>
              </div>
            </Card.Header>
            <Card.Body>
              <Table
                columns={columns}
                showCheckbox={false}
                rows={rows}
                showPagination={true}
                role={roleId}
                paginationProps={{
                  ...pagination,
                  activePage: pagination.currentPage,
                  onPageChange: (pageNo: any) => {
                    getUserListData(pageNo);
                  },
                }}
                dropdownContainerClass="mb-3"
                emptyRender={() => (
                  <p className="text-center mb-0">No record found</p>
                )}
              />
            </Card.Body>
          </Card>
        </section>
      </>
    </>
  );
};

export default UserListing;
