import React, { useEffect, useRef, useState } from "react";
import swal from "sweetalert";
import SimpleReactValidator from "simple-react-validator";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Card, Col, Row } from "react-bootstrap";
import {
  TextInput,
  Helmet,
  DropdownInput,
  PasswordInput,
} from "../../components/FormInputs";
import Loader from "../../components/Loader";
import Breadcrumb from "../../components/Breadcrumb/breadcrumb.component";
import Api from "../../helper/api";
import AuthApi from "../../helper/authApi";
import customFunctions from "../../helper/customFunctions";
import SelectAsyncPaginate from "../../components/FormInputs/SelectAsyncPaginate";

const AddUser = () => {
  const validator = useRef(new SimpleReactValidator());
  const navigate = useNavigate();
  const { state } = useLocation();
  const [userDetails, setUserDetails] = useState<any>({
    userId: null,
    firstName: "",
    middleName: "",
    lastName: "",
    role: null,
    mobile: "",
    email: "",
    password: "",
    status: "1",
    aadharCard: "",
    department: "",
  });

  const [title, setTitle] = useState<string>("Add User");
  const [isShowLoader, setShowLoader] = useState<boolean>(false);
  const [disableButton, setDisableButton] = useState<boolean>(false);
  const [count, forceUpdate] = useState<number>(0);
  const [pageType, setPageType] = useState<string>("add");
  const [roleArr, setRoleArr] = useState<any>([]);
  const [departmentArr, setDepartmentArr] = useState<any>([]);

  const items: any = [
    { to: "/", label: "Dashboard" },
    { to: "/user", label: "Users" },
    { label: title },
  ];

  useEffect(() => {
    getRoles()
    if (state && state?.pageType === "add") return;
    if (state && state?.pageType === "edit") {
      getDepartment();
      setPageType("edit");
      setTitle("Edit User");
      setUserDetails({
        ...userDetails,
        userId: state.id,
      });
    }
  }, [state]);

  useEffect(() => {
    if (!userDetails.userId) return;
    getUserList();
  }, [userDetails.userId]);

  const getRoles = async () => {
    setShowLoader(true);
    let id = userDetails.userId;
    const { data, message } = await AuthApi.getDataFromServer(`${Api.role}`);
    if (data && data.success === true) {
      setShowLoader(false);
      if (data && data.data) {
        let arr: any = [];
        data.data.map((item: any) => {
          if (item.id == 1 || item.id == 3) {
            arr.push({ label: item.name, value: item.id });
          }
        });
        setRoleArr(arr);
      }
    } else {
      setShowLoader(false);
      swal(message, "", "error");
    }
  };

  const getDepartment = async () => {
    // setShowLoader(true);
    const { data, message } = await AuthApi.getDataFromServer(
      `${Api.departmentUrl}`
    );
    if (data && data.success === true) {

      setShowLoader(false);
      let Arr: any = [];
      if (data && data.data) {
        let Data: any = data.data.response;
        Data &&
          Data.length > 0 &&
          Data.map((i: any) => {
            Arr.push({ label: i.name, value: i.id });
          });
      }
      setDepartmentArr(Arr);
    } else {
      setShowLoader(false);
      swal(message, "", "error");
    }
  };

  const getUserList = async () => {
    setShowLoader(true);
    let id = userDetails.userId;
    const { data, message } = await AuthApi.getDataFromServer(
      `${Api.userUrl}/?id=${id}`
    );
    if (data && data.success === true) {
      setShowLoader(false);
      if (data && data.data) {
        let userData: any = data.data;

        setUserDetails({
          userId: userData.id,
          firstName: userData.fname,
          middleName: userData.mname,
          lastName: userData.lname,
          role: { label: userData?.role?.name, value: userData?.role?.id },
          mobile: userData?.phone_no,
          email: userData.email,
          password: userData.password,
          status: userData.status,
          aadharCard: userData.adhar_card,
          department: userData?.dept_user_meta[0] ?
            {
              name: userData?.dept_user_meta[0]?.department?.name,
              id: userData?.dept_user_meta[0]?.departmentId
            } : null,
        });
      }
    } else {
      setShowLoader(false);
      swal(message, "", "error");
    }
  };

  const onSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if(userDetails?.role?.value == 1){
      validator.current.errorMessages.department = null;
      validator.current.fields.department = true;
    }
    // if (disableButton) return;
    // setShowLoader(true);
    if (userDetails?.userId) {
      validator.current.errorMessages.password = null;
      validator.current.fields.password = true;
    }
    if (validator.current.allValid()) {
      AddUser();
      validator.current.hideMessages();
    } else {
      setDisableButton(false);
      validator.current.showMessages();
      forceUpdate(count + 1);
    }
  };

  const AddUser = async () => {
    setShowLoader(true);
    let id = userDetails.userId;
    const postData: any = {
      fname: userDetails?.firstName,
      mname: userDetails?.middleName ? userDetails?.middleName : null,
      lname: userDetails?.lastName,
      phone_no: userDetails?.mobile,
      adhar_card: userDetails?.aadharCard,
      status: userDetails?.status,
      departmentId : userDetails?.role?.value == 3
        ? userDetails?.department?.id
        : "",
      roleId : userDetails?.role?.value,
    };
    if (id) {
      postData.id = id;
    } else {
      postData.email = userDetails?.email;
      postData.password = userDetails?.password;
      // postData.roleId = userDetails?.role?.value;
      // postData.departmentId = userDetails?.role?.value == 3
      //   ? userDetails?.department?.id
      //   : "";
    }

    setShowLoader(false);
    const endPoint: any = `${Api.userUrl}/`;
    const callback: any = id
      ? AuthApi.putDataToServer
      : AuthApi.postDataToServer;
    const { data, message } = await callback(endPoint, postData);
    if (data && data.success === true) {
      setDisableButton(false);
      swal(
        id ? "User updated successfully!" : "User created successfully!",
        "",
        "success"
      ).then(() => {
        navigate('/user');
      });
    } else {
      setDisableButton(false);
      setShowLoader(false);
      swal(message, "", "error");
    }
  };

  return (
    <>
      <Helmet title={title} />
      <div className="page-header">
        <div className="title-breadcrumb-section">
          <h2 className="main-content-title tx-24 mg-b-5">{title}</h2>
          <Breadcrumb items={items} />
        </div>
      </div>
      <Loader showLoader={isShowLoader} needFullPage={false} />
      <section className="content-area"></section>
      <Card>
        <Card.Body>
          <Row>
            <Col sm={4} md={4} lg={4}>
              <TextInput
                onInputChange={(
                  e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                ) => {
                  const re = /^[a-z A-Z ]*$/;
                  if (!e.target.value || re.test(e.target.value)) {
                    setUserDetails({
                      ...userDetails,
                      firstName: e.target.value,
                    });
                  }
                }}
                label="First Name"
                asterisk="*"
                id="first_name"
                containerClass="form-group"
                type="text"
                name="firstName"
                value={userDetails.firstName}
                placeholder="Enter first name"
                errorMessage={validator.current.message(
                  "first name",
                  userDetails.firstName,
                  "required"
                )}
              />
            </Col>
            <Col sm={4} md={4} lg={4}>
              <TextInput
                onInputChange={(
                  e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                ) => {
                  const re = /^[a-z A-Z ]*$/;
                  if (!e.target.value || re.test(e.target.value)) {
                    setUserDetails({
                      ...userDetails,
                      middleName: e.target.value,
                    });
                  }
                }}
                label="Middle Name"
                id="middle_name"
                containerClass="form-group"
                type="text"
                name="middleName"
                value={userDetails.middleName}
                placeholder="Enter middle name"
              />
            </Col>
            <Col sm={4} md={4} lg={4}>
              <TextInput
                onInputChange={(
                  e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                ) => {
                  const re = /^[a-z A-Z ]*$/;
                  if (!e.target.value || re.test(e.target.value)) {
                    setUserDetails({
                      ...userDetails,
                      lastName: e.target.value,
                    });
                  }
                }}
                label="Last Name"
                asterisk="*"
                id="last_name"
                containerClass="form-group"
                type="text"
                name="lastName"
                value={userDetails.lastName}
                placeholder="Enter last name"
                errorMessage={validator.current.message(
                  "last name",
                  userDetails.lastName,
                  "required"
                )}
              />
            </Col>
            <Col sm={4} md={4} lg={4}>

              <DropdownInput
                label="Role"
                asterisk="*"
                placeholder="Select role"
                options={roleArr}
                defaultValue={userDetails.role}
                // disabled={pageType === "edit"}
                onSelectHandler={(select: any) => {
                  if (select?.value == 3) {
                    getDepartment();
                  }
                  setUserDetails({ ...userDetails, role: select });
                }}
                isClearable={true}
                errorMessage={validator.current.message(
                  "role",
                  userDetails.role,
                  "required"
                )}
                id="role"
              />
            </Col>

            {
              userDetails && userDetails.role && userDetails.role.value == 3 ?
                <Col sm={4} md={4} lg={4}>

                  <SelectAsyncPaginate
                    label="Department"
                    asterisk="*"
                    // isMulti
                    value={userDetails.department}
                    // disabled={[5, 6].includes(roleId) || referralStatus == "1"}
                    clearSelected
                    onSelectChange={(select: any) => {
                      setUserDetails({ ...userDetails, department: select });
                    }}
                    selectPlaceholder="Select department"
                    endpoint={`${Api.departmentUrl}`}
                    apiname={"departmentUrl"}
                    isClearable={true}
                    key={undefined}
                    className={undefined}
                    disabled={undefined}
                    isSearch={undefined}
                    isMulti={undefined}
                    clearCacheOnID={undefined}
                    clearSelectedByParent={undefined}
                    adminType={undefined}
                    roleId={undefined}
                    currentUserId={undefined}
                    vendorId={undefined}
                    departmentId={undefined}
                    fromComponent={undefined}
                    selectedTenderId={undefined}
                    errorMessage={validator.current.message(
                      "department",
                      userDetails.department,
                      "required"
                    )}
                    id="department"
                  />
                  {/* <DropdownInput
                    label="Department"
                    asterisk="*"
                    placeholder="Select department"
                    options={departmentArr}
                    defaultValue={userDetails.department}
                    // disabled={pageType === "edit"}
                    onSelectHandler={(select: any) => {
                      setUserDetails({ ...userDetails, department: select });
                    }}
                    isClearable={true}
                    errorMessage={validator.current.message(
                      "department",
                      userDetails.department,
                      "required"
                    )}
                    id="department"
                  /> */}
                </Col>
                : null
            }
            <Col sm={4} md={4} lg={4}>
              <TextInput
                onInputChange={(e: any) => {
                  const re = /^[0-9]*$/;
                  if (!e.target.value || re.test(e.target.value)) {
                    setUserDetails({
                      ...userDetails,
                      mobile: e.target.value,
                    });
                  }
                }}
                label="Mobile"
                id="mobile"
                asterisk="*"
                inputVarible={"+91"}
                containerClass="form-group"
                type="tel"
                name="mobile"
                value={userDetails.mobile}
                placeholder="Enter mobile"
                maxlength={10}
                minlength={10}
                errorMessage={validator.current.message(
                  "mobile",
                  userDetails.mobile,
                  "required|regex:^([6-9]){1}([0-9]){9}$",
                  {
                    messages: {
                      required: "The mobile field is required",
                      regex: "Invalid mobile",
                    },
                  }
                )}
                autoComplete="new-mobile"
              />
            </Col>
            <Col sm={4} md={4} lg={4}>
              <TextInput
                onInputChange={(e: any) => {
                  const re = /^[0-9]*$/;
                  if (!e.target.value || re.test(e.target.value)) {
                    setUserDetails({
                      ...userDetails,
                      aadharCard: e.target.value,
                    });
                  }
                }}
                id="aadharNumber"
                label="Aadhar Number"
                asterisk="*"
                containerClass="form-group"
                type="text"
                name="aadharNumber"
                value={userDetails.aadharCard}
                // disabled={pageType === "edit"}
                placeholder="Enter aadhar number"
                errorMessage={validator.current.message(
                  "aadhar number",
                  userDetails.aadharCard,
                  "required|regex:^([0-9]){12}$",
                  {
                    messages: {
                      required: "The aadhar number field is required",
                      regex: "Invalid aadhar number",
                    },
                  }
                )}
                autoComplete="new-aadharNumber"
                maxlength={12}
                minlength={12}
              />
            </Col>
            <Col sm={4} md={4} lg={4}>
              <TextInput
                onInputChange={(
                  e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                ) => {
                  setUserDetails({ ...userDetails, email: e.target.value });
                }}
                label="Email"
                asterisk="*"
                containerClass="form-group"
                type="text"
                name="email"
                id="email"
                value={userDetails.email}
                disabled={pageType === "edit"}
                placeholder="Enter email"
                errorMessage={validator.current.message(
                  "email",
                  userDetails.email,
                  "required|email"
                )}
                autoComplete="new-email"
              />
            </Col>
            {pageType === "add" && (
              <Col sm={4} md={4} lg={4}>
                <PasswordInput
                  onInputChange={(
                    e: React.ChangeEvent<
                      HTMLTextAreaElement | HTMLInputElement
                    >
                  ) => {
                    setUserDetails({
                      ...userDetails,
                      password: e.target.value,
                    });
                  }}
                  name="password"
                  id="password"
                  containerClass="form-group"
                  inputClass="form-control"
                  label="Password"
                  asterisk="*"
                  value={userDetails.password}
                  type="password"
                  minlength="6"
                  placeholder="Enter password"
                  errorMessage={validator.current.message(
                    "password",
                    userDetails.password,
                    "required",
                    {
                      className: "text-danger",
                    }
                  )}
                  autoComplete="new-password"
                  switchElement="true"
                />
              </Col>
            )}
          </Row>
          <Col>
            <Button
              type="submit"
              variant="primary"
              id="submit_btn"
              onClick={(e) => {
                e.preventDefault();
                onSubmit(e);
              }}
              disabled={disableButton}
            >
              Submit
            </Button>
            <Button
              onClick={(e) => {
                e.preventDefault();
                setTitle("Add User");
                navigate('/user');
              }}
              id="cancel_btn"
              variant="secondary"
              className="ms-2"
              disabled={disableButton}
            >
              Cancel
            </Button>
          </Col>
        </Card.Body>
      </Card>
    </>
  );
};

export default AddUser;
