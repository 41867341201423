import React, { useEffect, useState } from "react";
import { Accordion, Row, Col, Button, Card, Image } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { Datepicker, DropdownInput, Helmet } from "../../components/FormInputs";
import Loader from "../../components/Loader";
import Api from "../../helper/api";
import AuthApi from "../../helper/authApi";
import customFunctions from "../../helper/customFunctions";
import Table from "../../components/Table/simpletable.component";
import Breadcrumb from "../../components/Breadcrumb/breadcrumb.component";
import swal from "sweetalert";
import UserRestrictions from "../../components/UserRestrictions/userrestrictions.component";
import SelectAsyncPaginate from "../../components/FormInputs/SelectAsyncPaginate";
import PaymentPopup from "./paymentPopUp";

const ReportComponent = () => {
  const navigate = useNavigate();
  const state = useLocation();

  const title = "Employee Salary Details";
  const [isShowLoader, setShowLoader] = useState<boolean>(false);
  const [rows, setRows] = useState<any>([]);
  const [filterErr, setFilterErr] = useState<any>("");
  const [searchKeyword, setSearchKeyword] = useState<any>("");
  const [statusToggle, setStatusToggle] = useState<boolean>(false);
  const [currentUserId, setCurrentUserId] = useState<number>(0);
  const [applyToggle, setApplyToggle] = useState<any>(false);
  const [filterData, setFilterData] = useState<any>({
    selectedDepartment: null,
    selectedTender: null,
    selectedVendorCompany: null,
    selectedDate: "",
  });

  const [departmentData, setDepartmentData] = useState<any>([]);
  const [tenderData, setTenderData] = useState<any>([]);
  const [venderData, setVenderData] = useState<any>([]);
  const [tenderDate, setTenderDate] = useState<any>({});
  const [totalPaid, setTotalPaid] = useState<any>(0);
  const [totalUnpaid, setTotalUnpaid] = useState<any>(0);
  const [totalCount, setTotalCount] = useState<any>(0);
  const [rowData, setRowData] = useState<any>([]);
  const [currentUser, updateCurrentUser] = useState<any>(0);
  const [loginVendor, updateLoginVendor] = useState<any>("");
  const [loginVendorName, updateLoginVendorName] = useState<any>("");
  const [loginDepartment, updateLoginDepartment] = useState<any>("");
  const [showPaymentPopup, setShowPaymentPopup] = useState(false);
  const [selectedAmount, setSelectedAmount] = useState<number>(0);
  const initialPagination: any = {
    currentPage: 1,
    itemsPerPage: 50,
    totalItems: 1,
  };

  console.log('selectedAmount', selectedAmount);
  console.log('rowData', rowData);
  

  const [vStartDate, setVStartDate] = useState<any>("");
  const [vEndDate, setVEndDate] = useState<any>("");

  const [pagination, setPagination] = useState<any>(initialPagination);

  const items: any = [
    {
      to: [1, 3].includes(Number(currentUser)) ? "/" : "/vendor-dashboard",
      label: "Dashboard",
    },
    { label: title },
  ];

  useEffect(() => {
    getUserRole();
  }, []);

  useEffect(() => {
    if (currentUser && Number(currentUser) == 2) {
      // getDepartmentByVendorId();
    } else if (currentUser && [1].includes(Number(currentUser))) {
      // getDepartmentList();
    }
    // else if (currentUser && Number(currentUser) == 3) {
    // getTenderByVendorId()
    // }
  }, [currentUser]);

  useEffect(() => {
    if (state && state.state && state.state.formComponent === "vendor") {
      getVenderByTender(state?.state?.tenderData?.value);
      setFilterData({
        selectedDepartment: state?.state?.departmentData,
        selectedTender: state?.state?.tenderData,
        selectedVendorCompany: state?.state?.venderData,
        selectedDate: state?.state?.selectedMonth,
      });
    }
  }, [state]);



  const getUserRole = async () => {
    const roleId = await customFunctions.getLocalStorage("role_id");
    const loginVendor = await customFunctions.getLocalStorage("login_vendor");
    const vname = await customFunctions.getLocalStorage("login_vendorName");
    const deptId = await customFunctions.getLocalStorage("dept_id");
    updateCurrentUser(Number(roleId));
    updateLoginVendor(Number(loginVendor));
    updateLoginDepartment(Number(deptId));
    updateLoginVendorName(vname);
    if (Number(roleId) == 3) {
      getTenderByDepartmentId(loginDepartment);
    }
  };

  const getDepartmentList = async () => {
    setShowLoader(true);
    setApplyToggle(false);
    const endPoint: any = Api.departmentUrl;

    const url: any = customFunctions.generateUrl(endPoint);

    const { data, message } = await AuthApi.getDataFromServer(url);

    if (data && data.success === true) {
      setShowLoader(false);
      if (data && data.data) {
        let dData: any = data.data.response;
        let dList: any = [];
        dData.map((item: any) => {
          dList.push({ label: item.name, value: item.id });
        });
        setDepartmentData(dList);
      } else {
        setDepartmentData([]);
      }
    } else {
      setShowLoader(false);
      setDepartmentData([]);
      swal(message, "", "error");
    }
  };

  const getDepartmentByVendorId = async () => {
    setShowLoader(true);
    const params = {
      // page: 1,
      // limit: 50,
      findAll: "findAll",
      vendor_id: customFunctions.getLocalStorage("login_vendor"),
    };
    const endPoint = `${Api.tenderUrl}/getTenderList?vendorId=${loginVendor}`;
    const url = customFunctions.generateUrl(endPoint);
    const { data, message } = await AuthApi.getDataFromServer(url);

    if (!customFunctions.checkIfEmpty(data, "O") && data && data.success) {
      setShowLoader(false);

      if (
        data &&
        data.data &&
        data.data.response &&
        data.data.response.length > 0
      ) {
        const deptArray: any = [];
        data.data.response.map((dept: any) => {
          deptArray.push({
            label: dept?.department?.name,
            value: dept?.department?.id,
          });
        });
        const ids = deptArray.map((o: any) => o.value);
        const filtered = deptArray.filter(
          ({ value }: any, index: any) => !ids.includes(value, index + 1)
        );
        setDepartmentData(filtered);
      } else {
        setShowLoader(false);
        setDepartmentData([]);
      }
    } else {
      setShowLoader(false);
      swal(message || "Something went wrong", "", "error");
    }
  };

  const getTenderByDepartmentId = async (dId: any) => {
    setShowLoader(true);
    setApplyToggle(false);
    const endPoint: any = [1, 3].includes(currentUser)
      ? `${Api.tenderUrl}/getTenderList?departmentId=${dId}&limit=500`
      : `${Api.tenderUrl}/getTenderList?vendorId=${loginVendor}&departmentId=${dId}&limit=500`;

    const url: any = customFunctions.generateUrl(endPoint);

    const { data, message } = await AuthApi.getDataFromServer(url);

    if (data && data.success === true) {
      setShowLoader(false);
      if (data && data.data && data.data.response) {
        let TData: any = data.data.response;
        let TList: any = [];
        TData.map((item: any) => {
          TList.push({ label: item.name, value: item.id });
        });
        setTenderData(TList);
      } else {
        setTenderData([]);
      }
    } else {
      setShowLoader(false);
      setTenderData([]);
      swal(message, "", "error");
    }
  };

  const getVenderByTender = async (vId: any) => {
    setShowLoader(true);
    setApplyToggle(false);
    const endpoint: any = `${Api.tenderUrl}/id?id=${vId}&limit=500`;
    const { data, message } = await AuthApi.getDataFromServer(endpoint);

    if (data && data.success == true) {
      setShowLoader(false);
      if (
        data &&
        data?.data &&
        data?.data?.response &&
        data?.data?.response[0]
      ) {
        // let cDate:any=data?.data?.response[0]
        // setVStartDate()
        const vData: any = data?.data?.response[0]?.tender_vendors;

        setTenderDate(data?.data?.response[0]);
        let vList: any = [];
        vData.map((item: any) => {
          // setVStartDate(new Date(item?.start_date));
          // setVEndDate(new Date(item?.end_date));
          if (loginVendor == item?.vendorId) {
            setVStartDate(item?.start_date);
            setVEndDate(item?.end_date);
          }

          if (item?.vendor) {
            vList.push({
              label: item?.vendor?.comp_name,
              value: item?.vendor?.id,
              start_date: item?.start_date,
              end_date: item?.end_date,
            });
          }
          if ([1, 2, 3].includes(Number(currentUser))) {
            // setTenderDate(item);
          }
        });
        setVenderData(vList);
      } else {
        setVenderData([]);
      }
    } else {
      setShowLoader(false);
      setVenderData([]);
      swal(message, "", "error");
    }
  };

  const columns = [
    {
      label: "Employee Full Name",
      id: "employee_name",
    },
    // {
    //   label: "Present Days",
    //   id: "days"
    // },
    {
      label: "Status",
      id: "status",
      render: (row: any) => {
        const rowData = row;
        return (
          <span
            style={{
              color: rowData.status.toLowerCase() === "paid" ? "green" : "red",
            }}
          >
            {rowData.status ? rowData.status.toUpperCase() : "Unpaid"}
          </span>
        );
      },
    },
    {
      label: "Total wages",
      id: "total_wages",
      render: (row: any) => {
        const rowData = row;
        return (
          <span
            style={{
              color: "blue",
            }}
          >
            {rowData.total_wages ? rowData.total_wages : 100}
          </span>
        );
      },
    }

  ];

  const getRowData = async (pageNo: any = 1, type: any = "") => {
    setShowLoader(true);
    const page = pageNo || pagination?.currentPage;
    //getEmployeeByTenderVender?tenderId=4&vendorId=1&salary_date=2023-02-01
    const endPoint = `${Api.employeeUrl}/getEmployeeByTenderVender`;
    const filterList =
      filterData && !customFunctions.checkIfEmpty(filterData, "O")
        ? customFunctions.jsonParse(filterData)
        : {};
    const currentMonth =
      new Date(filterData.selectedDate).getMonth() + Number(1);
    let selectedMonth: any = "";
    if (currentMonth && currentMonth.toString().length == 1) {
      selectedMonth = `0${currentMonth}`;
    } else {
      selectedMonth = currentMonth;
    }
    const currentYear = customFunctions.getDate(filterData.selectedDate);

    let params = {
      page,
      limit: 10,
      tenderId: filterData.selectedTender.value,
      salary_date: currentYear,
      // salary_date: `01-${selectedMonth}-${currentYear}`,
      //   filterData.selectedTender && filterData.selectedTender.value ? filterData.selectedTender.value : '',
      vendorId:
        filterData?.selectedVendorCompany &&
          filterData?.selectedVendorCompany?.value
          ? filterData.selectedVendorCompany.value
          : loginVendor
            ? loginVendor
            : "",
      // ...filterList,
    };
    const postData = customFunctions.cleanObject(params);
    const url = customFunctions.generateUrl(endPoint, postData);
    const { data, message } = await AuthApi.getDataFromServer(url);
    if (!customFunctions.checkIfEmpty(data, "O") && data && data.success) {
      if (data.data) {
        setShowLoader(false);
        setPagination({
          currentPage: Number(data.data.page),
          itemsPerPage: Number(10),
          totalItems: Number(data.data.Employee),
        });
        setTotalCount(data.data ? data.data.Employee : 0);
        setTotalPaid(data.data.Paid);
        setTotalUnpaid(Number(data.data.Unpaid));
        // setCurrentPage(Number(data.data.page));
      }
      if (
        data &&
        data.data &&
        data.data.response &&
        data.data.response.length > 0
      ) {
        let Arr: any = [];
        data.data.response.map((item: any) => {
          Arr.push({
            tvEmployeeId: item.tv_employees[0]?.id,
            id: item?.id,
            days: item?.working_days ? item?.working_days : "-",
            employee_name: item.mname
              ? `${item.fname} ${item.mname} ${item.lname}`
              : `${item.fname} ${item.lname}`,
            status: item.tv_employees[0].salaryStatus,
            total_wages:100
          });
        });
        setRowData(Arr || []);
        // setRowData(
        //   (data &&
        //     data.data &&
        //     data.data.response) ||
        //   []
        // );
      } else {
        setShowLoader(false);
        setRowData([]);
        swal("No record found", "", "error");
        return;
      }
    } else {
      setShowLoader(false);
      setRowData([]);
      setTotalCount(0);
      setTotalPaid(0);
      setTotalUnpaid(0);
      swal("Something went wrong", "", "error");
      return;
    }
  };

  const handleMarkPaid = async (selectedId: any) => {
    console.log('selectedId', selectedId);
    
    setShowLoader(true);
    let reportArr: any = [];
    selectedId.map((item: any) => {
      rowData.filter((it: any) => {
        if (it.id == item) {
          reportArr.push({
            tvEmployeeId: it?.tvEmployeeId,
            salary_date: customFunctions.getDate(filterData.selectedDate),
            salary_status: "Paid",
          });
        }
      });
    });
    const postData: any = {
      employee_array: reportArr,
    };

    const { data, message } = await AuthApi.postDataToServer(
      `${Api.employeeUrl}/bulk-Update-Salary-Status`,
      postData
    );
    if (data && data.success == true) {
      setShowLoader(false);
      swal("Salary status changed successfully", "", "success").then(() => {
        getRowData(pagination.currentPage);
      });
    } else {
      setShowLoader(false);
      swal(message || "Something went wrong", "", "error");
    }
  };

  const handleMarkUnpaid = async (selectedId: any) => {
    let reportArr: any = [];
    selectedId.map((item: any) => {
      rowData.filter((it: any) => {
        if (it.id == item) {
          reportArr.push({
            tvEmployeeId: it?.tvEmployeeId,
            salary_date: customFunctions.getDate(filterData.selectedDate),
            salary_status: "Unpaid",
          });
        }
      });
    });
    const postData: any = {
      employee_array: reportArr,
    };

    const { data, message } = await AuthApi.postDataToServer(
      `${Api.employeeUrl}/bulk-Update-Salary-Status`,
      postData
    );
    if (data && data.success == true) {
      setShowLoader(false);
      swal("Salary status changed successfully", "", "success").then(() => {
        getRowData(pagination.currentPage);
      });
    } else {
      setShowLoader(false);
      swal(message || "Something went wrong", "", "error");
    }
  };

  const downloadReport = async () => {
    setShowLoader(true);
    const month = new Date(filterData.selectedDate).getMonth() + Number(1);
    let selectedMonth: any = "";
    if (month && month.toString().length == 1) {
      selectedMonth = `0${month}`;
    } else {
      selectedMonth = month;
    }
    const year = new Date(filterData.selectedDate).getFullYear();
    let postData = {};
    postData = {
      page: pagination?.currentPage,
      limit: Number(500),
      tenderId:
        filterData.selectedTender && filterData.selectedTender.value
          ? filterData.selectedTender.value
          : "",
      vendorId:
        filterData.selectedVendorCompany &&
          filterData.selectedVendorCompany.value
          ? filterData.selectedVendorCompany.value
          : loginVendor
            ? loginVendor
            : "",
      salary_date: `${year}-${selectedMonth}-01`,
      // findAll: 'findAll'
    };

    const payload = customFunctions.cleanObject(postData);
    // const endPoint = Api.pdfreport;
    const endPoint = `${Api.employeeUrl}/generate-monthly-salary-pdf`;

    const url = customFunctions.generateUrl(endPoint, payload);

    const { data, message } = await AuthApi.getDataFromServer(url);
    if (data) {
      setShowLoader(false);
      window.open(`${data.data}`, "_blank");
    } else {
      setShowLoader(false);
      swal(message || "Something went wrong", "", "error");
    }
  };

  const filterSearch = (e: any) => {
    e.preventDefault();
    let filterSearchErr: string = "";
    let isValid: boolean = true;

    if (!filterData.selectedDate) {
      filterSearchErr = "Please select month and year";
      setFilterErr(filterSearchErr);
      isValid = false;
    }

    if (Number(currentUser) !== 2 && !filterData.selectedVendorCompany) {
      filterSearchErr = "Please select vendor/company";
      setFilterErr(filterSearchErr);
      isValid = false;
    }

    if (!filterData.selectedTender) {
      filterSearchErr = "Please select tender";
      setFilterErr(filterSearchErr);
      isValid = false;
    }
    if (Number(currentUser) !== 3 && filterData.selectedDepartment === null) {
      filterSearchErr = "Please select department";
      setFilterErr(filterSearchErr);
      isValid = false;
    }

    if (isValid) {
      setFilterErr("");
      getRowData(1);
      // let vDate: any = state?.state?.selectedMonth;
      // if (new Date(vDate).getTime() >= vStartDate) {
      //   setFilterErr("");
      //   getRowData(1);
      // } else {
      //   swal("Vendor is not asign for this month", "", "error");
      // }
      // setRowData([{ employee_name: "Harshad Mehta", status: "Paid" }]);
    }
  };

  const onClear = (e: any) => {
    e.preventDefault();
    setFilterData({
      selectedDepartment: null,
      selectedTender: null,
      selectedVendorCompany: null,
      selectedDate: "",
    });
    setRowData([]);
    setFilterErr("");
    // getRowData(1, true);
  };

  const handleApplyButtonClick = (selectedId: any) => {

    setShowLoader(true);
    let payableAmout: number = 0;
    selectedId.map((item: any) => {
      rowData.filter((it: any) => {
        console.log('it.id == item', it.id == item);
        
        if (it.id == item) {
          payableAmout = payableAmout + it.total_wages 
        }
      });
    });
    
    console.log('payableAmout', payableAmout);
    
    setShowLoader(false);
    setSelectedAmount(payableAmout);
    setShowPaymentPopup(true);
  };

  const handlePaymentSuccess = (response: any) => {
    // Handle success logic here
    console.log('Payment successful!', response);
    setShowPaymentPopup(false);
  };

  const handlePaymentFailure = (error: any) => {
    // Handle failure logic here
    console.error('Payment failed!', error);
    setShowPaymentPopup(false);
  };

  return (
    <>
      <Helmet title={title} />
      <div className="page-header">
        <div className="title-breadcrumb-section">
          <h2 className="main-content-title tx-24 mg-b-5">{title}</h2>
          <Breadcrumb items={items} />
        </div>
      </div>
      <Loader showLoader={isShowLoader} needFullPage={false} />
      <section className="content-area">
        <Accordion defaultActiveKey="1">
          <Accordion.Item eventKey="1">
            <Accordion.Header>
              <h5 className="card-title mb-0">Filters</h5>
            </Accordion.Header>
            <Accordion.Body>
              {filterErr && (
                <div className="p-3  bg-danger text-white bg-danger-main table-red-bg mb-3">
                  <div className="img-text">
                    <img
                      style={{ cursor: "pointer" }}
                      onClick={(e) => onClear(e)}
                      src="/images/fail.svg"
                      alt="Success"
                    />{" "}
                    {filterErr}
                  </div>
                </div>
              )}
              <Row >
                <UserRestrictions permittedUsers={[1, 2]} roleId={currentUser}>
                  <Col sm={2} md={2} lg={2}>
                    <SelectAsyncPaginate
                      label="Select Department Name"
                      asterisk="*"
                      // isMulti
                      value={filterData.selectedDepartment}
                      // disabled={[5, 6].includes(roleId) || referralStatus == "1"}
                      clearSelected
                      selectedTenderId={""}
                      onSelectChange={(select: any) => {
                        setRowData([]);
                        if (select != null) {
                          getTenderByDepartmentId(select?.id);
                        }
                        setFilterData({
                          selectedTender: null,
                          selectedVendorCompany: null,
                          selectedDate: "",
                          selectedDepartment: select,
                        });
                      }}
                      isClearable={true}
                      selectPlaceholder="Select department"
                      endpoint={
                        Number(currentUser) === 1
                          ? `${Api.departmentUrl}`
                          : `${Api.tenderUrl}/getTenderList`
                      }
                      apiname={"departmentReportsUrl"}
                      id={"department-dropdown"}
                      key={undefined}
                      className={undefined}
                      disabled={undefined}
                      isSearch={undefined}
                      clearCacheOnID={undefined}
                      clearSelectedByParent={undefined}
                      errorMessage={undefined}
                      adminType={undefined}
                      roleId={undefined}
                      currentUserId={undefined}
                      isMulti={undefined}
                      vendorId={loginVendor ? loginVendor : undefined}
                      departmentId={undefined}
                      fromComponent={undefined}
                    />
                  </Col>
                </UserRestrictions>
                <Col sm={2} md={2} lg={2}>
                  <DropdownInput
                    id="tender-dropdown"
                    asterisk="*"
                    label="Select Tender Name"
                    placeholder="Select tender"
                    options={tenderData}
                    isClearable={true}
                    defaultValue={filterData.selectedTender}
                    onSelectHandler={(select: any) => {
                      setRowData([]);
                      setFilterData({
                        ...filterData,
                        selectedVendorCompany: null,
                        selectedDate: "",
                        selectedTender: select,
                      });
                      if (select != null) {
                        getVenderByTender(select?.value);
                      }
                      // setFilterData({ ...filterData, selectedDate: "" });
                      // setFilterData({ ...filterData, selectedTender: select });
                    }}
                    disabled={
                      !filterData.selectedDepartment && !loginDepartment
                    }
                  />
                </Col>
                <UserRestrictions permittedUsers={[1, 3]} roleId={currentUser}>
                  <Col sm={2} md={2} lg={2}>
                    <DropdownInput
                      id="vendor-dropdown"
                      asterisk="*"
                      label="Select Vendor/Company"
                      placeholder="Select vendor/company"
                      options={venderData}
                      isClearable={true}
                      defaultValue={filterData.selectedVendorCompany}
                      onSelectHandler={(select: any) => {
                        setRowData([]);
                        setFilterData({
                          ...filterData,
                          selectedDate: "",
                          selectedVendorCompany: select,
                        });
                      }}
                      disabled={
                        // !filterData.selectedDepartment ||
                        !filterData.selectedTender
                      }
                    />
                  </Col>
                </UserRestrictions>
                <Col sm={2} md={2} lg={2}>
                  <Datepicker
                    id="date"
                    peekNextMonth={true}
                    showMonthDropdown={true}
                    showYearDropdown={true}
                    dropdownMode="select"
                    label="Select Month And Year"
                    className="form-control"
                    selected={filterData.selectedDate}
                    asterisk="*"
                    placeholder="Select month and year"
                    dateFormat="yyyy-MM"
                    // minDate={customFunctions.getDatefromMonth(
                    //   tenderDate?.start_date
                    // )}
                    // maxDate={customFunctions.getDatefromMonth(
                    //   tenderDate?.end_date
                    // )}
                    minDate={customFunctions.getDatefromMonth(
                      loginVendor
                        ? vStartDate
                        : filterData.selectedVendorCompany?.start_date
                    )}
                    maxDate={customFunctions.getDatefromMonth(
                      loginVendor
                        ? vEndDate
                        : filterData.selectedVendorCompany?.end_date
                    )}
                    onChange={(e: any) => {
                      setRowData([]);
                      setFilterData({ ...filterData, selectedDate: e });
                    }}
                    disabled={
                      !filterData.selectedDepartment ||
                        !filterData.selectedTender ||
                        currentUser !== 2
                        ? !filterData.selectedVendorCompany
                        : ""
                    }
                    showMonthYearPicker
                  />
                </Col>
                {/* </Row>
              <Row> */}
                <Col sm={2} md={2} lg={2}>
                  <div className="filter-btn pull-right filter-btn-report mt-4">
                    <Button
                      id="submit_btn"
                      variant="primary"
                      type="submit"
                      className="ms-4"
                      onClick={(e) => {
                        e.preventDefault();
                        filterSearch(e);
                      }}
                    >
                      Submit
                    </Button>
                    <Button
                      id="clear_btn"
                      variant="secondary"
                      type="button"
                      className="ms-4"
                      onClick={(e) => onClear(e)}
                    >
                      Clear
                    </Button>
                  </div>
                </Col>
              </Row>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        {
          // selectedTender && selectedTender.value && selectedVendor && selectedVendor.value && (
          rowData && rowData.length > 0 && (
            <div className="mt-4">
              <ul className="all-employees d-flex align-items-center list-unstyled">
                <li className="ml-3">
                  <span>{`All Employees (${totalCount}) | `}</span>
                </li>
                <li className="ml-3">
                  <span>{`Paid (${totalPaid}) |   `}</span>
                </li>
                <li className="ml-3">
                  <span>{`Unpaid (${totalUnpaid})`} </span>
                </li>
              </ul>
              <a
                id="report"
                style={{
                  float: "right",
                  marginTop: "-39px",
                  textDecoration: "underline",
                }}
                href="javascript:void(0)"
                onClick={(e) => {
                  e.preventDefault();
                  downloadReport();
                }}
              >
                Download Report
              </a>
            </div>
          )
        }
        {rowData && rowData.length > 0 && (
          <Card className="mt-3">
            <Card.Body>
              <Table
                columns={columns}
                showCheckbox={true}
                bulkActionsLabel="Apply"
                bulkActions={[
                  // { actionTitle: 'Mark paid' },
                  {
                    actionTitle: "Mark paid",
                    actionCallback: handleMarkPaid,
                  },
                  {
                    actionTitle: "Mark unpaid",
                    actionCallback: handleMarkUnpaid,
                  },
                  {
                    actionTitle: "Pay Now",
                    actionCallback:handleApplyButtonClick, // You can adjust amount as needed
                  },
                  // { actionTitle: 'Mark unpaid', actionCallback: handleMarkUnpaid },
                ]}
                rows={rowData}
                showPagination
                paginationProps={{
                  ...pagination,
                  activePage: pagination.currentPage,
                  onPageChange: (pageNo: any) => {
                    getRowData(pageNo);
                  },
                }}
                dropdownContainerClass="mb-3"
                emptyRender={() => (
                  <p className="text-center mb-0">No record found</p>
                )}
              />
            </Card.Body>
          </Card>
        )}
        {/* Payment Popup */}
        <PaymentPopup
          show={showPaymentPopup}
          handleClose={() => setShowPaymentPopup(false)}
          amount={selectedAmount}
          onPaymentSuccess={handlePaymentSuccess}
          onPaymentFailure={handlePaymentFailure}
        />
      </section>
    </>
  );
};

export default ReportComponent;
