import React, { useEffect, useRef, useState } from "react";
import { Row, Col, Button, Card, Image } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Datepicker,
  DropdownInput,
  Helmet,
  TextInput,
} from "../../components/FormInputs";
import Loader from "../../components/Loader";
import Api from "../../helper/api";
import AuthApi from "../../helper/authApi";
import customFunctions from "../../helper/customFunctions";
import Breadcrumb from "../../components/Breadcrumb/breadcrumb.component";
import swal from "sweetalert";
import SelectAsyncPaginate from "../../components/FormInputs/SelectAsyncPaginate";
import { saveAs } from "file-saver";
import SimpleReactValidator from "simple-react-validator";
// import Table from "../../components/Table/simpletable.component";
import { Table } from "react-bootstrap";
const BulkEmployeeAttendenceReportComponent = () => {
  const navigate = useNavigate();
  const inputRef: any = useRef([]);
  const validator = useRef(new SimpleReactValidator());
  const title = "Employee Attendence";
  const [isShowLoader, setShowLoader] = useState<boolean>(false);
  const [autofocus, setAutofocus] = useState<boolean>(false);
  const [currentUser, updateCurrentUser] = useState<any>(0);
  const [loginVendor, updateLoginVendor] = useState<any>("");
  const [loginVendorName, updateLoginVendorName] = useState<any>("");
  const [loginDepartment, updateLoginDepartment] = useState<any>("");
  const [rows, setRows] = useState<any>([]);
  const [employeeData, setEmployeeData] = useState<any>([]);
  const [onchangeData, setOnchangeData] = useState<any>([]);
  const [uploadDocument, setUploadDocument] = useState<any>({
    file: "",
    name: "",
    file_url: "",
    file_name: "",
    id: "",
  });
  const [count, forceUpdate] = useState<number>(0);
  const [selectedMonth, setSelectedMonth] = useState<any>("");
  const [selectedVendor, setSelectedVendor] = useState<any>("");
  const [selectedTender, setSelectedTender] = useState<any>("");
  const [vendorValues, setVendorValues] = useState<any>([]);
  const [tenderErrMsg, setTenderErrMsg] = useState<string>("");
  const [vendorErrMsg, setVendorErrMsg] = useState<string>("");
  const [monthErrMsg, setMonthErrMsg] = useState<string>("");
  const initialPagination = {
    currentPage: 1,
    itemsPerPage: 10,
    totalItems: 1,
  };
  const [pagination, setPagination] = useState<any>(initialPagination);
  const items: any = [
    {
      to: [1, 3].includes(Number(currentUser)) ? "/" : "/vendor-dashboard",
      label: "Dashboard",
    },
    { label: title },
  ];

  useEffect(() => {
    getUserRole();
  }, []);

  const getUserRole = async () => {
    const roleId = await customFunctions.getLocalStorage("role_id");
    const loginVendor = await customFunctions.getLocalStorage("login_vendor");
    const vname = await customFunctions.getLocalStorage("login_vendorName");
    const deptId = await customFunctions.getLocalStorage("dept_id");
    updateCurrentUser(Number(roleId));
    updateLoginVendor(Number(loginVendor));
    updateLoginDepartment(Number(deptId));
    updateLoginVendorName(vname);
  };

  const onsubmit = () => {
    let isValid = true;

    if (!selectedTender) {
      isValid = false;
      setTenderErrMsg("The tender field is required");
    }

    if (selectedTender && !selectedVendor) {
      isValid = false;
      setVendorErrMsg("The company/vendor field is required");
    }

    if (selectedTender && selectedVendor && !selectedMonth) {
      isValid = false;
      setMonthErrMsg("The month and year field is required");
    }

    if (isValid) {
      setTenderErrMsg("");
      setVendorErrMsg("");
      setMonthErrMsg("");
      getAttendenceData();
    }
  };

  useEffect(() => {
    if (!selectedTender) return;
    if ([1, 2].includes(currentUser)) {
      getVenderData();
    }
  }, [selectedTender]);

  const getVenderData = async () => {
    setShowLoader(true);
    const endPoint: any = `${Api.tenderUrl}/id?id=${selectedTender?.id}&limit=50`;

    const url: any = customFunctions.generateUrl(endPoint);

    const { data, message } = await AuthApi.getDataFromServer(url);
    if (data && data.success === true) {
      let vender = data.data.response[0]?.tender_vendors;
      setShowLoader(false);
      if (data && data.data) {
        let Arr: any = [];
        vender &&
          vender.length > 0 &&
          vender.map((item: any) => {
            if (currentUser == 2) {
              setSelectedVendor({
                label: item?.vendor?.comp_name,
                value: item?.vendor?.id,
                start_date: item?.start_date,
                end_date: item?.end_date,
              });
              return;
            } else {
              if (item?.vendor) {
                Arr.push({
                  label: item?.vendor?.comp_name,
                  value: item?.vendor?.id,
                  start_date: item?.start_date,
                  end_date: item?.end_date,
                });
              }
            }
          });
        setVendorValues(Arr);
      } else {
        setVendorValues([]);
      }
    } else {
      setShowLoader(false);
      setVendorValues([]);
      swal(message, "", "error");
    }
  };

  const getAttendenceData = async () => {
    setShowLoader(true);

    const endPoint = Api.sampleDownloadCSVUrl;
    const params = {
      tender_id: selectedTender && selectedTender?.id ? selectedTender?.id : "",
      vendor_id:
        currentUser == 2
          ? loginVendor
          : selectedVendor?.value
          ? selectedVendor?.value
          : "",
      salary_month: customFunctions.getDate(selectedMonth),
    };

    const url = customFunctions.generateUrl(endPoint, params);
    const { data, message } = await AuthApi.getDataFromServer(url);

    if (data && data.success == true) {
      setShowLoader(false);
      if (data && data.data?.Employee_Data?.length) {
        let newData: any = [];
        data.data?.Employee_Data &&
          data.data?.Employee_Data.map((item: any) => {
            newData.push({
              ...item,
              autofocus: false,
            });
          });
          newData.reverse();
        setEmployeeData([...newData]);
      } else {
        setEmployeeData([]);
        swal("No record found", "", "error");
      }
    } else {
      setShowLoader(false);
      setEmployeeData([]);
      swal(message, "", "error");
      validator.current.hideMessages();
      forceUpdate(count + 1);
    }
  };

  const onDocumentUpload = () => {
    let isWorkingHourValid = false;
    employeeData &&
      employeeData?.length &&
      employeeData.filter((item: any) => {
        if (
          Number(item?.no_of_days) >
          new Date(
            selectedMonth.getFullYear(),
            selectedMonth.getMonth() + 1,
            0
          ).getDate()
        ) {
          return (isWorkingHourValid = true);
        }
      });
    if (validator.current.allValid() && !isWorkingHourValid) {
      bulkUploadAttendence();
    } else {
      validator.current.showMessages();
      forceUpdate(count + 1);
    }
  };

  const bulkUploadAttendence = async () => {
    setShowLoader(true);
    let arr: any = [];
    employeeData &&
      employeeData.length &&
      employeeData.map((item: any) => {
        arr.push({
          tvEmployeeId: item?.tvEmployeeId,
          // employeeName: item?.employeeName,
          salary_month: item?.salary_month,
          no_of_days: Number(item?.no_of_days),
        });
      });
    const endPoint: any = `${Api.attendenceAddUrl}`;
    const params = {
      employee_array: arr,
    };
    const callback: any = AuthApi.postDataToServer;

    const { data, message } = await callback(endPoint, JSON.stringify(params));

    if (data && data.success === true) {
      setShowLoader(false);
      swal("Employee Attendence Updated Successfully!", "", "success");
      forceUpdate(count + 1);
    } else {
      setShowLoader(false);
      swal(message, "", "error");
    }
  };

  const onChangeValue = (e: any, rowDt: any, index: number) => {
    const re = /^[0-9]*\.?[0-9]*$/;
    if (!e.target.value || re.test(e.target.value)) {
      let newValue = e.target.value;
      if (newValue.toString().charAt(0) == ".") {
        newValue = e.target.value.replace(".", "0.5");
        rowDt[index].no_of_days = newValue;
        setEmployeeData([...rowDt]);
      } else {
        rowDt[index].no_of_days = e.target.value;
        setEmployeeData([...rowDt]);
      }
      validator.current.showMessages();
      forceUpdate(count + 1);
    }
  };

  return (
    <>
      <Helmet title={title} />
      <div className="page-header">
        <div className="title-breadcrumb-section">
          <h2 className="main-content-title tx-24 mg-b-5">{title}</h2>
          <Breadcrumb items={items} />
        </div>
      </div>
      <Loader showLoader={isShowLoader} needFullPage={false} />
      <section className="content-area accordion-main">
        <Card className="mt-3">
          <Card.Body>
            <div className="d-flex align-items-center justify-content-end">
              <a
                id="add-btn-link"
                href="/add-employee"
                className="underline"
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/attendence",{
                    state:{
                      selectedTender: selectedTender ? selectedTender : "",
                      selectedVendor: selectedVendor ? selectedVendor : "",
                      selectedMonth: selectedMonth ? selectedMonth : ""
                    }
                  });
                }}
              >
                Download CSV
              </a>
            </div>
            <h6
              className="section-title m-1"
              style={{ marginTop: "-16px" }}
            ></h6>
            <hr />
            <Row>
              <Col sm={3}>
                <div
                  className={
                    currentUser == 1 && !isShowLoader
                      ? "select-role-option"
                      : ""
                  }
                >
                  <SelectAsyncPaginate
                    label="Select Tender"
                    asterisk="*"
                    value={selectedTender}
                    clearSelected
                    selectedTenderId={""}
                    onSelectChange={(select: any) => {
                      setTenderErrMsg("");
                      setSelectedTender(select);
                      setSelectedVendor("");
                      setSelectedMonth("");
                      setVendorValues([]);
                      setEmployeeData([]);
                    }}
                    selectPlaceholder="Select tender"
                    endpoint={`${Api.tenderUrl}/getTenderList`}
                    apiname={"tenderUrl"}
                    key={undefined}
                    isClearable={selectedTender}
                    className={undefined}
                    disabled={undefined}
                    isSearch={undefined}
                    clearCacheOnID={undefined}
                    clearSelectedByParent={undefined}
                    // errorMessage={validator.current.message(
                    //   "tender",
                    //   selectedTender,
                    //   "required"
                    // )}
                    errorMessage={tenderErrMsg ? tenderErrMsg : ""}
                    id={undefined}
                    adminType={undefined}
                    roleId={undefined}
                    currentUserId={undefined}
                    isMulti={undefined}
                    vendorId={currentUser == 2 ? loginVendor : undefined}
                    departmentId={undefined}
                    fromComponent={undefined}
                  />
                </div>
              </Col>
              {currentUser == 1 && (
                <Col sm={3}>
                  <div
                    className={
                      currentUser == 1 && !isShowLoader
                        ? "select-role-option"
                        : ""
                    }
                  >
                    <DropdownInput
                      id="vendorValue"
                      label="Select Company/Vendor"
                      asterisk="*"
                      placeholder="Select company/vendor"
                      options={vendorValues}
                      defaultValue={selectedVendor}
                      onSelectHandler={(select: any) => {
                        setVendorErrMsg("");
                        setSelectedVendor(select);
                        setSelectedMonth("");
                        setEmployeeData([]);
                      }}
                      isClearable={true}
                      // errorMessage={validator.current.message(
                      //   "vendor",
                      //   selectedVendor,
                      //   "required"
                      // )}
                      errorMessage={vendorErrMsg ? vendorErrMsg : ""}
                      disabled={!selectedTender}
                    />
                  </div>
                </Col>
              )}
              <Col sm={3}>
                <div
                  className={
                    currentUser == 2 && !isShowLoader
                      ? "select-role-option"
                      : ""
                  }
                >
                  <Datepicker
                    peekNextMonth={true}
                    showMonthDropdown={true}
                    isClearable={selectedMonth}
                    showYearDropdown={true}
                    dropdownMode="select"
                    label="Select Month And Year"
                    className="form-control"
                    selected={selectedMonth}
                    asterisk="*"
                    placeholder="Select month and year"
                    dateFormat="yyyy-MM"
                    minDate={
                      selectedVendor?.start_date &&
                      customFunctions.getDatefromMonth(
                        selectedVendor?.start_date
                      )
                    }
                    maxDate={
                      selectedVendor?.end_date &&
                      customFunctions.getDatefromMonth(selectedVendor?.end_date)
                    }
                    onChange={(e: any) => {
                      setMonthErrMsg("");
                      setSelectedMonth(e);
                      setEmployeeData([]);
                      forceUpdate(count + 1);
                    }}
                    disabled={
                      (currentUser == 2 && !selectedTender) || !selectedVendor
                    }
                    showMonthYearPicker
                    // errorMessage={validator.current.message(
                    //   "month and year",
                    //   selectedMonth,
                    //   "required"
                    // )}
                    errorMessage={monthErrMsg ? monthErrMsg : ""}
                  />
                </div>
              </Col>
              <Col sm={1}>
                <Button
                  className="mt-4"
                  onClick={(e: any) => {
                    e.preventDefault();
                    onsubmit();
                  }}
                >
                  Search
                </Button>
              </Col>
            </Row>

            {employeeData && employeeData.length ? (
              <>
                <center>
                  {" "}
                  <h6 className="section-title mt-3">
                    Employee Attendence Data
                  </h6>
                </center>
                <hr />
                <div className="mb-4">
                  <Table>
                    <thead>
                      <tr>
                        <th>Sr. No.</th>
                        <th>Employee Name</th>
                        <th>Working Days</th>
                      </tr>
                    </thead>
                    <tbody>
                      {employeeData &&
                        employeeData.length > 0 &&
                        employeeData.map((item: any, index: any) => (
                          <>
                            <tr>
                              <td>{index + 1}</td>
                              <td>{item?.employeeName}</td>
                              <td>
                                {
                                  <>
                                    <input
                                      style={{ height: "35px", width: "72px" }}
                                      maxLength={4}
                                      type="text"
                                      placeholder="Enter count"
                                      onChange={(e: any) => {
                                        let rowDt = [...employeeData];
                                        onChangeValue(e, rowDt, index);
                                      }}
                                      value={item?.no_of_days}
                                    />

                                    <p style={{ color: "red" }}>
                                      {" "}
                                      {validator.current.message(
                                        "days",
                                        item?.no_of_days,
                                        item?.no_of_days 
                                          ? `required|max:${new Date(
                                              selectedMonth.getFullYear(),
                                              selectedMonth.getMonth() + 1,
                                              0
                                            ).getDate()},num`
                                          : "",
                                        {
                                          messages: {
                                            required: "",
                                            max: `The days may not be greater than ${new Date(
                                              selectedMonth.getFullYear(),
                                              selectedMonth.getMonth() + 1,
                                              0
                                            ).getDate()}.`,
                                          },
                                        }
                                      )}{" "}
                                    </p>
                                  </>
                                }
                              </td>
                            </tr>
                          </>
                        ))}
                    </tbody>
                  </Table>
                  <Button
                    className="mt-4 float-end"
                    onClick={(e: any) => {
                      e.preventDefault();
                      onDocumentUpload();
                    }}
                  >
                    Submit
                  </Button>
                </div>
              </>
            ) : (
              ""
            )}
          </Card.Body>
        </Card>
      </section>
    </>
  );
};

export default BulkEmployeeAttendenceReportComponent;
