import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import swal from "sweetalert";
import AuthApi from "../../../helper/authApi";
import Api from "../../../helper/api";
import Loader from "../../../components/Loader";

const TokenVerificationComponent = (props) => {
  const navigate = useNavigate();
  const [token, setToken] = useState(null);
  const [searchParams] = useSearchParams();
  const [isShowLoader, setShowLoader] = useState(false);

  useEffect(() => {
    localStorage.clear();
    const currentParams = Object.fromEntries([...searchParams]);
    setToken(currentParams.token);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  // useEffect(()=>{
  //   navigate("/update-password", { state: { token: token, email : 'abc@gmail.com' } });
  // },[token]);

  useEffect(() => {
    if (!token) return;
    async function fetchMyAPI() {
      if (!token) {
        window.location = "/login";
        return;
      } else {
        verifyTo();
      }
    }
    fetchMyAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const verifyTo = async () => {
    setShowLoader(true);
    const { data, message } = await AuthApi.getDataFromServer(
      `${Api.tokenVerifyUrl}?token=${token}`
    );
    if (data && data.success === true && data.data) {
      setShowLoader(false);
      navigate("/update-password", {
        state: { token: token, email: data.data.email },
      });
    } else {
      setShowLoader(false);
      swal(message[0] || "Invalid token", "", "error").then(() => {
        navigate("/login");
      });
    }
  };

  return (
    <>
      <Loader showLoader={isShowLoader} needFullPage={false} />
      <div className="login-page recover-password">
        <h5>Verify Token</h5>
      </div>
    </>
  );
};

export default TokenVerificationComponent;
