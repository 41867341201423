import React from "react";
import Select from "react-select";
import { Form } from "react-bootstrap";

export default function SelectDropdown(props: any) {
  const {
    label,
    defaultValue,
    name,
    inputClass,
    isMulti,
    onSelectHandler,
    options,
    containerClass,
    errorMessage,
    asterisk,
    placeholder,
    disabled,
    isOptionDisabled,
    isClearable,
    id,
    onMenuOpen,
  } = props;

  return (
    <>
      {label && (
        <Form.Label>
          {" "}
          {label} <span style={{ color: "red" }}>{asterisk}</span>
        </Form.Label>
      )}
      <Form.Group className={containerClass}>
        <Select
          value={defaultValue}
          onChange={(e:any) => {
            if (onSelectHandler) onSelectHandler(e);
          }}
          onMenuOpen={() => {
            if (onMenuOpen) onMenuOpen();
          }}
          name={name}
          className={inputClass}
          placeholder={placeholder}
          options={options}
          isMulti={isMulti}
          isDisabled={disabled}
          isOptionDisabled={isOptionDisabled}
          isClearable={isClearable}
          inputId={id}
        />
        <p style={{ color: "red" }}> {errorMessage} </p>
      </Form.Group>
    </>
  );
}
