import { Routes, Route } from 'react-router-dom';
import Layout from './components/Layout/layout.component';
import Login from './containers/authentication/Login/loginComponent';
import ChangePassword from './containers/Profile/changePasswordComponent';
import Profile from './containers/Profile/profileComponent';
import AdminDashboardComponent from './containers/dashboard/DashboardComponent';
import AddEmployee from './containers/employee/addEmployeeComponent';
import EmployeeListing from './containers/employee/employeeList'
import DepartmentListing from './containers/department/departmentList';
import AddDepartment from './containers/department/addDepartmentComponent';
import VendorCompanyList from './containers/vendor/vendorCompanyList';
import AddVendorCompany from './containers/vendor/addVendorCompanyComponent';
import UserListing from './containers/user/userList';
import AddUser from './containers/user/addUserComponent';
import TenderListing from './containers/tender/tenderList';
import AddTender from './containers/tender/addTenderComponent';
import ReportComponent from './containers/reports/reportComponent';
import VendorDashboard from './containers/vendor/vendorDashboardComponent';
import EmployeeAttendenceComponent from './containers/reports/employeeAttendence';
import EmployeeAttendenceReportComponent from './containers/reports/employeeAttendenceReport';
import BulkEmployeeAttendenceReportComponent from './containers/reports/bulkUploadAttendence';

function RouterFile() {
  return (
    <Layout>
      <Routes>
        <Route path="/" element={<AdminDashboardComponent />} />
        <Route path="/login" element={<Login />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/change-password" element={<ChangePassword />} />
        <Route path="/employee" element={<EmployeeListing />} />
        <Route path="/add-employee" element={<AddEmployee />} />
        <Route path="/edit-employee" element={<AddEmployee />} />
        <Route path="/department" element={<DepartmentListing />} />
        <Route path="/add-department" element={<AddDepartment />} />
        <Route path="/vendor-dashboard" element={<VendorDashboard />} />
        <Route path="/vendor" element={<VendorCompanyList />} />
        <Route path="/add-vendor" element={<AddVendorCompany />} />
        <Route path="/edit-vendor" element={<AddVendorCompany />} />
        <Route path="/user" element={<UserListing />} />
        <Route path="/add-user" element={<AddUser />} />
        <Route path="/edit-user" element={<AddUser />} />
        <Route path="/tender" element={<TenderListing />} />
        <Route path="/add-tender" element={<AddTender />} />
        <Route path="/edit-tender" element={<AddTender />} />
        <Route path="/report" element={<ReportComponent />} />
        {/* <Route path="/attendence" element={<EmployeeAttendenceComponent />} /> */}
        <Route path="/attendence" element={<EmployeeAttendenceReportComponent />} />
        <Route path="/bulk-attendence" element={<BulkEmployeeAttendenceReportComponent />} />
        
      </Routes>
    </Layout>
  );
}

export default RouterFile;
