import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import SimpleReactValidator from "simple-react-validator";
import { TextInput, Helmet } from "../../components/FormInputs";
import { Row, Col, Button, Form, Card, Image } from "react-bootstrap";
import AuthApi from "../../helper/authApi";
import Api from "../../helper/api";
import customFunctions from "../../helper/customFunctions";
import Loader from "../../components/Loader/index";
import Breadcrumb from "../../components/Breadcrumb/breadcrumb.component";

const Profile = () => {
  const navigate = useNavigate();
  const validator = useRef(new SimpleReactValidator());
  const title = 'Profile';
  const [isShowLoader, setShowLoader] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [count, forceUpdate] = useState(0);
  const [currentUserId, setCurrentUserId] = useState<Number>();
  const [profiletToggle, setProfiletToggle] = useState(false);
  const [roleId, setRoleId] = useState<number>(0);
  const [profileDetails, setProfileDetails] = useState<any>({
    firstName: "",
    lastName: "",
    userMobile: "",
    userEmail: "",
    userAdharNumber: "",
    status: "",
  });

  const items = [{ to: "/", label: "Dashboard" }, { label: title }];

  useEffect(() => {
    const role = customFunctions.getLocalStorage("role_id");
    setRoleId(Number(role));
    const currentUserId = customFunctions.getLocalStorage("id");
    setCurrentUserId(Number(currentUserId));
  }, []);

  useEffect(() => {
    getUser();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUserId]);

  const getUser = async () => {
    if (!currentUserId) return;
    setShowLoader(true);
    const { data, message } = await AuthApi.getDataFromServer(
      `${Api.userUrl}/?id=${currentUserId}`
    );

    if (data && data.success === true) {
      setShowLoader(false);
      let profileData = data.data;
      await customFunctions.setLocalStorage(
        "userName",
        `${customFunctions.capitalizeFirstLetter(
          profileData.fname
        )} ${customFunctions.capitalizeFirstLetter(profileData.lname)}`
      );
      setProfileDetails({
        firstName: profileData.fname,
        lastName: profileData.lname,
        userMobile: profileData.phone_no,
        userEmail: profileData.email,
        userAdharNumber: profileData.adhar_card,
        status: profileData.status,
      });
    } else {
      setShowLoader(false);
      swal(message || "Something went wrong", "", "error");
    }
  };

  const onSubmit = (e: any) => {
    e.preventDefault();
    if (disableButton) return;
    setDisableButton(true);
    if (validator.current.allValid()) {
      addUser();
    } else {
      validator.current.showMessages();
      forceUpdate(count + 1);
      setDisableButton(false);
    }
  };

  const addUser = async () => {
    setShowLoader(true);
    const postData: any = {
      id: currentUserId,
      fname: profileDetails.firstName,
      lname: profileDetails.lastName,
      phone_no: profileDetails.userMobile,
      adhar_card: profileDetails.userAdharNumber,
      status: profileDetails.status,
    };

    const api = `${Api.userUrl}`;
    const url = customFunctions.generateUrl(api);
    const callback = AuthApi.putDataToServer;
    const { data, message } = await callback(url, postData);

    if (data && data.success === true) {
      setShowLoader(false);
      await customFunctions.setLocalStorage(
        "userName",
        `${customFunctions.capitalizeFirstLetter(
          profileDetails.firstName
        )} ${customFunctions.capitalizeFirstLetter(profileDetails.lastName)}`
      );

      swal("Profile updated successfully!", "", "success").then(() => {
        setDisableButton(false);
        validator.current.hideMessages();
        navigate("/");
      });
    } else {
      setDisableButton(false);
      setShowLoader(false);
      swal(message, "", "error");
    }
  };

  return (
    <>
      <Helmet title={title} />
      <div className="page-header">
        <div className="title-breadcrumb-section">
          <h2 className="main-content-title tx-24 mg-b-5">{title}</h2>
          <Breadcrumb items={items} />
        </div>
      </div>
      <Loader showLoader={isShowLoader} needFullPage={false} />
      <section className="content-area">
        <Card>
          <Card.Body>
            <Form
              autoComplete="off"
              onSubmit={(e) => {
                onSubmit(e);
              }}
            >
              <div className="d-flex justify-content-between">
                <div>
                <h5>Profile Details</h5>
                </div>
                <div>
                  {!profiletToggle && (
                   <a
                   href="/"
                   className="pl-2 ms-1"
                   title={"Edit Profile"}
                   onClick={(e) => {
                     e.preventDefault();
                     setProfiletToggle(true);
                   }}
                 >
                   <Image src="images/edit-button.svg" alt="edit" />
                 </a>
                  )}
                </div>
              </div>
              <Row className="mt-2">
                <Col>
                  <>
                    <Row>
                      <Col sm={6}>
                        <TextInput
                          onInputChange={(
                            e: React.ChangeEvent<
                              HTMLTextAreaElement | HTMLInputElement
                            >
                          ) => {
                            const re = /^[a-zA-Z ]*$/;
                            if (!e.target.value || re.test(e.target.value)) {
                              setProfileDetails({
                                ...profileDetails,
                                firstName: e.target.value,
                              });
                            }
                          }}
                          id="firstName"
                          label="First Name"
                          asterisk="*"
                          containerClass="form-group"
                          type="text"
                          name="firstName"
                          value={profileDetails.firstName}
                          placeholder="Enter first name"
                          disabled={!profiletToggle}
                          errorMessage={validator.current.message(
                            "First Name",
                            profileDetails.firstName,
                            "required"
                          )}
                          autocomplete="new-firstName"
                        />
                      </Col>
                      <Col sm={6} className="">
                        <TextInput
                          onInputChange={(
                            e: React.ChangeEvent<
                              HTMLTextAreaElement | HTMLInputElement
                            >
                          ) => {
                            setProfileDetails({
                              ...profileDetails,
                              lastName: e.target.value,
                            });
                          }}
                          id="lastName"
                          label="Last Name"
                          asterisk="*"
                          containerClass="form-group"
                          type="text"
                          name="lastName"
                          value={profileDetails.lastName}
                          placeholder="Enter last name"
                          disabled={!profiletToggle}
                          errorMessage={validator.current.message(
                            "Last Name",
                            profileDetails.lastName,
                            "required"
                          )}
                          autocomplete="new-lastName"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={6} className="">
                        <TextInput
                          onInputChange={(
                            e: React.ChangeEvent<
                              HTMLTextAreaElement | HTMLInputElement
                            >
                          ) => {
                            setProfileDetails({
                              ...profileDetails,
                              userEmail: e.target.value,
                            });
                          }}
                          id="email"
                          label="Email"
                          asterisk="*"
                          containerClass="form-group"
                          type="text"
                          name="userEmail"
                          disabled
                          value={profileDetails.userEmail}
                          placeholder="Enter user email"
                          errorMessage={validator.current.message(
                            "User email",
                            profileDetails.userEmail,
                            "required|email"
                          )}
                        />
                      </Col>
                      <Col sm={6}>
                        <TextInput
                          onInputChange={(
                            e: React.ChangeEvent<
                              HTMLTextAreaElement | HTMLInputElement
                            >
                          ) => {
                            setProfileDetails({
                              ...profileDetails,
                              userMobile: e.target.value,
                            });
                          }}
                          id="mobileNumber"
                          disabled
                          label="Mobile Number"
                          asterisk="*"
                          containerClass="form-group"
                          type="text"
                          name="userMobile"
                          value={profileDetails.userMobile}
                          maxlength={10}
                          placeholder="Enter mobile number"
                          errorMessage={validator.current.message(
                            "Mobile number",
                            profileDetails.userMobile,
                            "required|regex:^([6-9]){1}([0-9]){9}$",
                            {
                              messages: {
                                required: "The mobile number field is required",
                                regex: "Invalid mobile number number",
                              },
                            }
                          )}
                        />
                      </Col>
                      <Col sm={6}>
                        <TextInput
                          onInputChange={(
                            e: React.ChangeEvent<
                              HTMLTextAreaElement | HTMLInputElement
                            >
                          ) => {
                            setProfileDetails({
                              ...profileDetails,
                              userAdharNumber: e.target.value,
                            });
                          }}
                          id="aadharNumber"
                          disabled
                          label="Aadhar Number"
                          asterisk="*"
                          containerClass="form-group"
                          type="text"
                          name="aadharNumber"
                          value={profileDetails.userAdharNumber}
                          maxlength={12}
                          placeholder="Enter aadhar number"
                          errorMessage={validator.current.message(
                            "aadhar number",
                            profileDetails.userAdharNumber,
                            "required|regex:^([0-9]){12}$",
                            {
                              messages: {
                                required: "The aadhar number field is required",
                                regex: "Invalid aadhar number",
                              },
                            }
                          )}
                        />
                      </Col>
                    </Row>
                    <Row>
                      {profiletToggle && (
                        <Col>
                          <div
                            style={{ float: "right" }}
                            className="form-group mt-3"
                          >
                            <Button
                              type="submit"
                              variant="primary"
                              disabled={disableButton}
                            >
                              Submit
                            </Button>
                            <Button
                              onClick={(e) => {
                                e.preventDefault();
                                setProfiletToggle(false);
                              }}
                              variant="secondary"
                              className="ms-2"
                            >
                              Cancel
                            </Button>
                          </div>
                        </Col>
                      )}
                    </Row>
                  </>
                </Col>
              </Row>
            </Form>
          </Card.Body>
        </Card>
      </section>
    </>
  );
};

export default Profile;
