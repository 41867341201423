import React, { useEffect, useState } from "react";
import { Card, Row, Col, Form, Button, Image } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import swal from "sweetalert";
import ReactDatePicker from "react-datepicker";
import Loader from "../../components/Loader/index";
import Breadcrumb from "../../components/Breadcrumb/breadcrumb.component";
import { Helmet } from "../../components/FormInputs";
import { DropdownInput } from "../../components/FormInputs";
import AuthApi from "../../helper/authApi";
import Api from "../../helper/api";
import customFunctions from "../../helper/customFunctions";
import UserRestrictions from "../../components/UserRestrictions/userrestrictions.component";
import VendorDashboardPopUp from "./vendorDashboardPopup";
import Table from "../../components/Table/simpletable.component";
import SelectAsyncPaginate from "../../components/FormInputs/SelectAsyncPaginate";
import { useDispatch, useSelector } from "react-redux";
import { filter, filterValue } from "../../store/redux_slice";
import AttendencePopUp from "./attendencePopup";

const VendorDashboard = () => {
  const state = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const selector = useSelector<any, any>((state) => state);

  const titleHeading = "Vendor Dashboard";
  const [isShowLoader, setShowLoader] = useState(false);
  const [tenderListArr, setTenderListArr] = useState<any>([]);
  const [selectedTender, setSelectedTender] = useState<any>(null);
  const [employeeListdata, setEmployeeListdata] = useState<any>([]);
  const [salaryReportData, setSalaryReportData] = useState<any>([]);
  const [tenderDetails, setTenderDetails] = useState<any>({});
  const [selectedTenderDetails, setSelectedTenderDetails] = useState<any>({});
  const [tenderErr, setTenderErr] = useState("");
  const [vendorErr, setVendorErr] = useState("");
  const [deptErr, setdeptErr] = useState("");
  const [vendorList, setVendorList] = useState<any>([]);
  const [departmentList, setDepartmentList] = useState<any>([]);
  const [selectedVendor, setSelectedVendor] = useState<any>(null);
  const [selectedDepartment, setSelectedDepartment] = useState<any>(null);
  const [currentUser, updateCurrentUser] = useState<any>(null);
  const [loginVendor, updateLoginVendor] = useState<any>("");
  const [loginVendorName, updateLoginVendorName] = useState<any>("");
  const [loginDepartment, updateLoginDepartment] = useState<any>("");
  const [gererateMonth, setGererateMonth] = useState<any>(false);
  const [items, updateItems] = useState<any>([]);
  const [tenderVendorId, setTenderVendorId] = useState<any>(null);
  const [monthYear, setMonthYear] = useState<any>("");
  const [monthYearErr, setMonthYearErr] = useState<any>("");
  const [selectedmonthYear, setSelectedmonthYear] = useState<any>("");
  const [rows, setRows] = useState<any>([]);
  const [attendencePopUP, setAttendencePopUP] = useState<any>(false);
  const [employeeData, setEmployeeData] = useState<any>("");

  useEffect(() => {
    getUserRole();
  }, []);

  const getUserRole = async () => {
    const roleId = await localStorage.getItem("role_id");
    const loginVendor = await localStorage.getItem("login_vendor");
    const vname = await localStorage.getItem("login_vendorName");
    const deptId = await localStorage.getItem("dept_id");
    updateLoginDepartment(deptId);
    updateCurrentUser(Number(roleId));
    updateLoginVendor(Number(loginVendor));
    updateLoginVendorName(vname);
  };

  const columns = [
    {
      id: "no",
      label: "No",
    },
    {
      id: "full_name",
      label: "Name",
    },
    // {
    //   id: "attendence",
    //   label: "Attendence",
    // },
  ];

  const initialPagination = {
    currentPage: 1,
    itemsPerPage: 10,
    totalItems: 1,
  };
  const [pagination, setPagination] = useState<any>(initialPagination);

  useEffect(() => {
    if (!state?.state) return;

    if (state?.state?.departmentData) {
      setSelectedDepartment({
        name: state?.state?.departmentData?.label,
        id: state?.state?.departmentData?.value,
      });
      setSelectedTender({
        label: state?.state?.tenderData?.label,
        value: state?.state?.tenderData?.value,
      });
      setSelectedVendor({
        label: state?.state?.vendorData?.label,
        value: state?.state?.vendorData?.value,
        vendor_tender_id: state?.state?.vendorData?.vendor_tender_id,
        start_date: state?.state?.start_date,
        end_date: state?.state?.end_date
      });
      // dispatch(filterValue({
      //   selectedDepartment: state?.state?.departmentData,
      //   selectedTender: state?.state?.tenderData,
      //   selectedVendor: state?.state?.vendorData
      // }))
    }
  }, [state]);

  // useEffect(() => {
  //   if (!selector) return;
  //   if (selector && selector?.data_reducer) {
  //     let dptName:any={
  //       name:selector?.data_reducer?.filterValue?.selectedDepartment?.label,
  //       id:selector?.data_reducer?.filterValue?.selectedDepartment?.value
  //     }
  //     // setSelectedDepartment(dptName)
  //     // setSelectedTender(selector?.data_reducer?.filterValue?.selectedTender)
  //     // setSelectedVendor(selector?.data_reducer?.filterValue?.selectedVendor)
  //     // setEmployeeListdata(selector?.data_reducer?.employeeList);
  //     // setTenderDetails(selector?.data_reducer?.filter);
  //     // setSalaryReportData(selector?.data_reducer?.salaryList)
  //   }
  // }, [selector]);

  useEffect(() => {
    if (currentUser && currentUser == 2) {
      // getDepartmentByVendorId();
    } else {
      var breadcrumitems: any = [
        { to: "/", label: "Dashboard" },
        { to: "/vendor", label: "Vendors" },
        { label: "Vendor Dashboard" },
      ];
      if (currentUser == 1) {
        // getDepartment();
        updateItems(breadcrumitems);
      } else if (currentUser == 3) {
        getTendorListDataByVendor(loginDepartment);
        updateItems(breadcrumitems);
      }
    }
  }, [currentUser]);

  const getDepartmentByVendorId = async () => {
    setShowLoader(true);
    const params = {
      // page: 1,
      // limit: 50,
      findAll: "findAll",
      vendor_id: customFunctions.getLocalStorage("login_vendor"),
    };
    const endPoint = `${Api.tenderUrl}/getTenderList?vendorId=${loginVendor}`;
    const url = customFunctions.generateUrl(endPoint);
    const { data, message } = await AuthApi.getDataFromServer(url);

    if (!customFunctions.checkIfEmpty(data, "O") && data && data.success) {
      setShowLoader(false);

      if (
        data &&
        data.data &&
        data.data.response &&
        data.data.response.length > 0
      ) {
        const deptArray: any = [];
        data.data.response.map((dept: any) => {
          deptArray.push({
            label: dept?.department?.name,
            value: dept?.department?.id,
          });
        });
        const ids = deptArray.map((o: any) => o.value);
        const filtered = deptArray.filter(
          ({ value }: any, index: any) => !ids.includes(value, index + 1)
        );
        setDepartmentList(filtered);
      } else {
        setShowLoader(false);
        setDepartmentList([]);
      }
    } else {
      setShowLoader(false);
      swal(message || "Something went wrong", "", "error");
    }
  };

  const getEmployeeList = async (pageNo?: any) => {
    let venderId = selectedVendor?.value ? selectedVendor?.value : loginVendor;
    setShowLoader(true);
    const page: any = pageNo || 1;

    let endPoint = `${Api.employeeUrl}/getAllEmployee`;
    // let endPoint = `${Api.employeeUrl}/getAllEmployee?vendor_id=${venderId}&tender_id=${selectedTender?.value}`;
    // endPoint += currentUser == 2 ? `vendor_id=${loginVendor}` : `vendor_id=${selectedVendor.value}`
    const params: any = {
      page: page,
      limit: 10,
      tender_id: selectedTender?.value,
      vendor_id: venderId,
      // salary_date: customFunctions.getDate(monthYear)
    };
    const url: any = customFunctions.generateUrl(endPoint, params);
    const { data, message } = await AuthApi.getDataFromServer(url);
    if (data && data.success === true) {
      setShowLoader(false);
      setPagination({
        itemsPerPage: Number(10),
        currentPage: data.data.page,
        totalItems: data.data.count,
      });
      if (
        data &&
        data.data &&
        data.data.response &&
        data.data.response.length > 0
      ) {
        const Arr: any = [];
        // data.data.response.map((item: any, index: any) => {
        //   Arr.push({
        //     no: (data.data.page - 1) * pagination.itemsPerPage + (index + 1),
        //     full_name: `${customFunctions.capitalizeFirstLetter(item?.fname)} ${item?.mname
        //       ? customFunctions.capitalizeFirstLetter(item?.mname)
        //       : ""
        //       } ${customFunctions.capitalizeFirstLetter(item?.lname)}`,
        //     ...item,
        //   });
        // });
        setEmployeeListdata(data.data.response);
        // dispatch(employeeList(Arr))
      } else {
        setShowLoader(false);
        setEmployeeListdata([]);
        swal("No record found", "", "error");
        return;
      }
    } else {
      setShowLoader(false);
      swal(message || "Something went wrong", "", "error");
      return;
    }
  };

  const getSalaryReport = async () => {
    const selectedYear = new Date(monthYear).getFullYear();
    const tv_id =
      currentUser && Number(currentUser == 2)
        ? selectedTender.vendor_tender_id
        : currentUser && [1, 3, 4].includes(Number(currentUser))
          ? selectedVendor.vendor_tender_id
          : "";
    const params = {
      year: selectedYear,
      vendor_tender_id:
        currentUser && Number(currentUser == 2)
          ? selectedTender.vendor_tender_id
          : currentUser && [1, 3, 4].includes(Number(currentUser))
            ? selectedVendor.vendor_tender_id
            : "",
    };
    const endPoint = `${Api.employeeUrl}/salary-report?tender_vendor_id=${tv_id}&year=${selectedYear}`;
    const url = customFunctions.generateUrl(endPoint);
    const { data, message } = await AuthApi.getDataFromServer(url);
    if (data && data.success === true) {
      if (data && data.data && data.data.length > 0) {
        // data.data.map((item)=>{
        //   const sdata = {...item}
        //  const currentMonth = new Date().getMonth() + 1;
        //  if(currentMonth > customFunctions.getMonthName(item.date)){
        //  return sdata.status = '-';
        //  }
        // })
        setSalaryReportData(data.data);
        // dispatch(salaryList(data.data))
      } else {
        setSalaryReportData([]);
      }
    } else {
      swal(message || "Something went wrong", "", "error");
      return;
    }
  };

  const getTenderData = async () => {
    const { data, message } = await AuthApi.getDataFromServer(
      `${Api.tenderUrl}/id?id=${selectedTender.value}`
    );
    if (data && data.success === true) {
      if (data && data?.data?.response?.length > 0) {
        setTenderDetails(data?.data?.response[0]);
        // dispatch(filter(data.data.response[0]))
      } else {
        setTenderDetails({});
      }
    } else {
      swal(message || "Something went wrong", "", "error");
      return;
    }
  };

  // const downloadReport = async (date: any) => {
  //   setShowLoader(true);
  //   const selectedYear = new Date(selectedmonthYear).getFullYear();
  //   const month = customFunctions.getMonthName(date);
  //   const year = selectedYear;
  //   const postData = {
  //     salary_date: `01-${month}-${year}`,
  //     tender_id: selectedTender.value,
  //     vendor_id: currentUser && Number(currentUser == 2) ? loginVendor : selectedVendor ? selectedVendor.value : '',
  //     // findAll: 'findAll'
  //   }
  //   const payload = customFunctions.cleanObject(postData);
  //   const endPoint = Api.pdfreport;
  //   const url = customFunctions.generateUrl(endPoint, payload);
  //   const { data, message } = await AuthApi.getDataFromServer(url);
  //   if (data) {
  //     setShowLoader(false);
  //     window.open(`http://${data.data}`, '_blank');
  //   }
  //   else {
  //     setShowLoader(false);
  //     swal(message || 'Something went wrong', '', 'error');
  //   }
  // };

  const downloadReport = async (date: any) => {
    setShowLoader(true);
    // const month = new Date(monthYear).getMonth() + Number(1);
    // let selectedMonth: any = "";
    // if (month && month.toString().length == 1) {
    //   selectedMonth = `0${month}`;
    // } else {
    //   selectedMonth = month;
    // }
    const month = customFunctions.getMonthName(date);
    const year = new Date(monthYear).getFullYear();
    let postData = {};
    postData = {
      tenderId:
        selectedTender && selectedTender.value ? selectedTender.value : "",
      vendorId:
        selectedVendor && selectedVendor.value
          ? selectedVendor.value
          : loginVendor
            ? loginVendor
            : "",
      salary_date: `${year}-${month}-01`,
      // findAll: 'findAll'
    };

    const payload = customFunctions.cleanObject(postData);
    // const endPoint = Api.pdfreport;
    const endPoint = `${Api.employeeUrl}/generate-monthly-salary-pdf`;

    const url = customFunctions.generateUrl(endPoint, payload);

    const { data, message } = await AuthApi.getDataFromServer(url);
    if (data) {
      setShowLoader(false);
      window.open(`${data.data}`, "_blank");
    } else {
      setShowLoader(false);
      swal(message || "Something went wrong", "", "error");
    }
  };

  useEffect(() => {
    if (selectedDepartment && selectedDepartment?.id) {
      if (currentUser && currentUser == 1) {
        getTendorListData(selectedDepartment.id);
      } else if (currentUser && currentUser == 2) {
        getTendorListDataByVendor(selectedDepartment.id);
      }
    }
  }, [selectedDepartment]);

  useEffect(()=>{
    setTableData();
  },[employeeListdata]);

  const setTableData = () => {
    const rowsData:any = [];
    employeeListdata.map((dt:any, i:number) => {
      rowsData.push({
        no:(pagination.currentPage - 1) * pagination.itemsPerPage + (i + 1),
        full_name: `${customFunctions.capitalizeFirstLetter(dt?.fname)} ${dt?.mname
                ? customFunctions.capitalizeFirstLetter(dt?.mname)
                : ""
                } ${customFunctions.capitalizeFirstLetter(dt?.lname)}`,
        // attendence:(<>
        // <Image
        //           src="/images/notes.svg"
        //           title={"Add Attendence"}
        //           alt="edit"
        //           // disabled={dt?.isDeactive == 1 ? true : false}
        //           className=""
        //           id="flexSwitchCheckDefault"
        //           onClick={(e) => {
        //             e.preventDefault();
        //             setAttendencePopUP(true);
        //             setEmployeeData(dt);
        //           }}
        //         />
        // </>)
        
      });
      return dt;
    });
    setRows([...rowsData]);
  };

  const getDepartment = async () => {
    const { data, message } = await AuthApi.getDataFromServer(
      `${Api.departmentUrl}`
    );
    if (data && data.success === true) {
      const dpOptions: any = [];
      const dt: any = data?.data?.response;
      if (dt && dt.length > 0) {
        dt &&
          dt.map((op: any) => {
            dpOptions.push({ label: op.name, value: op.id });
            return op;
          });
        setDepartmentList(dpOptions);
      }
    } else {
      swal(message || "Something went wrong", "", "error");
    }
  };

  const getTendorListData = async (depid: any) => {
    const endPoint = `${Api.tenderUrl}/getTenderList?departmentId=${depid}&limit=50`;
    // const url = customFunctions.generateUrl(endPoint, params)
    const { data, message } = await AuthApi.getDataFromServer(
      // `${Api.departmentUrl}?departmentId=${depid}`
      endPoint
    );
    if (data && data.success === true) {
      const Arr: any = data?.data?.response;
      const dpOptions: any = [];
      if (Arr && Arr.length > 0) {
        Arr &&
          Arr.map((op: any) => {
            dpOptions.push({
              label: op.name,
              value: op.id,
              vendor_tender_id: "",
            });
            return op;
          });
        setTenderListArr(dpOptions);
      }
    } else {
      swal(message || "Something went wrong", "", "error");
    }
  };

  const getTendorListDataByVendor = async (depid?: any) => {
    let endPoint = loginDepartment
      ? `${Api.tenderUrl}/getTenderList?departmentId=${depid}&limit=50`
      : `${Api.tenderUrl}/getTenderList?vendorId=${loginVendor}&departmentId=${selectedDepartment?.id}&limit=50`;
    // if (currentUser && Number(currentUser) == 2)
    //   endPoint += `getTenderList?departmentId=${depid}`;
    // if (currentUser && Number(currentUser) == 3)
    //   endPoint += `getTenderList?departmentId=${depid}`;
    const { data, message } = await AuthApi.getDataFromServer(endPoint);
    if (data && data.success === true) {
      const dpOptions: any = [];
      if (
        data &&
        data.data &&
        data.data.response &&
        data.data.response.length > 0
      ) {
        data.data.response.map((op: any) => {
          dpOptions.push({
            label: op?.name,
            value: op.id,
            vendor_tender_id: op?.tender_vendors[0]?.id,
          });
          return op;
        });
        setTenderListArr(dpOptions);
      }
    } else {
      swal(message || "Something went wrong", "", "error");
    }
  };

  const getVendors = async (value: any) => {
    const { data, message } = await AuthApi.getDataFromServer(
      `${Api.tenderUrl}/id?id=${value}&limit=50`
    );

    if (!data) {
      swal(message || "Something went wrong", "", "error");
      return;
    }
    const dt: any = data?.data?.response[0]?.tender_vendors;
    const dpOptions: any = [];
    dt &&
      dt.length > 0 &&
      dt.map((op: any) => {
        if (loginVendor && loginVendor == op?.vendorId) {
          setSelectedVendor({
            start_date: op?.start_date,
            end_date: op?.end_date
          })
        }
        dpOptions.push({
          label: op?.vendor?.comp_name,
          value: op?.vendorId,
          vendor_tender_id: op?.id,
          start_date: op?.start_date,
          end_date: op?.end_date
        });
        return op;
      });
    setVendorList(dpOptions);
  };

  useEffect(() => {
    if (!selectedTender) return;
    getVendors(selectedTender.value);
    if (state && state.state && state.state.tenderData) {
      getTenderDetails(state.state.tenderData);
    }
  }, [selectedTender]);

  const getTenderDetails = async (select: any) => {
    const endPoint = `${Api.tenderUrl}/id?id=${select.value}`;
    const { data, message } = await AuthApi.getDataFromServer(endPoint);

    if (data && data?.data && data?.success == true) {
      if (data?.data?.response?.length > 0 && data?.data?.response[0]) {
        const tenderData = {
          start_date: data?.data?.response[0]?.start_date,
          end_date: data?.data?.response[0]?.end_date,
        };
        setSelectedTenderDetails(tenderData);
        if (currentUser && Number(currentUser) == 2) {
          data.data.response[0] &&
            data.data.response[0].tender_vendors &&
            data.data.response[0].tender_vendors.length > 0 &&
            data.data.response[0].tender_vendors.map((op: any) => {
              if (op.vendorId === loginVendor) {
                setTenderVendorId(op?.id);
              }
            });
        }
      }
    } else {
      swal(message || "Something went wrong", "", "error");
      return;
    }
  };

  const filterSearch = async () => {
    // e.preventDefault();
    let filterSearchErr = "",
      tenderErr = "",
      vendorErr = "",
      monthYearErr = "",
      deptErr = "";
    let isValid = true;
    // if (
    //   customFunctions.checkIfEmpty(selectedTender) &&
    //   customFunctions.checkIfEmpty(keyword) &&
    //   customFunctions.checkIfEmpty(selectedVendor) &&
    //   customFunctions.checkIfEmpty(monthYear)
    // ) {
    //   filterSearchErr = 'Please select atleast one filter';
    //   setFilterErr(filterSearchErr);
    //   isValid = false;
    // }

    if (!selectedTender) {
      tenderErr = "Please select tender";
      isValid = false;
    }

    if (currentUser && currentUser !== 2 && !selectedVendor) {
      vendorErr = "Please select vendor";
      isValid = false;
    }

    if (!selectedDepartment && currentUser && currentUser !== 3) {
      deptErr = "Please select department";
      isValid = false;
    }

    if (!monthYear) {
      monthYearErr = "Please select year";
      isValid = false;
    }

    setTenderErr(tenderErr);
    setVendorErr(vendorErr);
    setdeptErr(deptErr);
    setMonthYearErr(monthYearErr);
    if (isValid) {
      await setSelectedmonthYear(monthYear);
      setTenderErr("");
      setVendorErr("");
      getTenderData();
      getEmployeeList();
      getSalaryReport();
      setMonthYearErr("");
      // setFilterErr({});
      // getRowData(1);
    }
  };

  const resetData = () => {
    // setFilterErr({});
    setSelectedDepartment(null);
    // setTenderDetails({});
    setSelectedTender(null);
    setSelectedVendor(null);
    setTenderErr("");
    setVendorErr("");
    setdeptErr("");
    setEmployeeListdata([]);
    setSalaryReportData([]);
    setTenderDetails({});
    setMonthYearErr("");
    setMonthYear("");
    // navigate(-1);
    // getRowData(1, true);
  };

  return (
    <>
      <Helmet title={titleHeading} />
      <div className="title-breadcrumb-section">
        <h2 className="main-content-title tx-24 mg-b-5">Vendor Dashboard</h2>
        <Breadcrumb items={items} />
      </div>
      <Loader showLoader={isShowLoader} needFullPage={false} />

      <Card>
        <Card.Body>
          <Row className="d-flex">
            <UserRestrictions permittedUsers={[1, 2]} roleId={currentUser}>
              <Col sm={3}>
                {/* <DropdownInput
                  label="Select Department"
                  placeholder="Select department"
                  options={departmentList}
                  defaultValue={selectedDepartment}
                  onSelectHandler={(select: any) => {
                    setSelectedTender(null);
                    setSelectedVendor(null);
                    setSelectedDepartment(select);
                    setMonthYear("");
                    setdeptErr("");
                  }}
                  errorMessage={deptErr}
                  asterisk={"*"}
                /> */}

                <SelectAsyncPaginate
                  label="Select Department"
                  asterisk="*"
                  value={selectedDepartment}
                  clearSelected
                  selectedTenderId={""}
                  onSelectChange={(select: any) => {
                    setEmployeeListdata([]);
                    setSalaryReportData([]);
                    setTenderDetails({});
                    setSelectedTender(null);
                    setSelectedVendor(null);
                    setSelectedDepartment(select);
                    setMonthYear("");
                    setdeptErr("");
                  }}
                  isClearable={undefined}
                  selectPlaceholder="Select department"
                  endpoint={
                    Number(currentUser) === 1
                      ? `${Api.departmentUrl}`
                      : `${Api.tenderUrl}/getTenderList`
                  }
                  apiname={"departmentReportsUrl"}
                  id={"department-dropdown"}
                  errorMessage={deptErr}
                  key={undefined}
                  className={undefined}
                  disabled={undefined}
                  isSearch={undefined}
                  clearCacheOnID={undefined}
                  clearSelectedByParent={undefined}
                  adminType={undefined}
                  roleId={undefined}
                  currentUserId={undefined}
                  isMulti={undefined}
                  vendorId={loginVendor ? loginVendor : undefined}
                  departmentId={undefined}
                  fromComponent={undefined}
                />
              </Col>
            </UserRestrictions>
            <Col sm={3}>
              <DropdownInput
                label="Select Tender Name"
                placeholder="Select tender name"
                options={tenderListArr}
                defaultValue={selectedTender}
                onSelectHandler={(select: any) => {
                  setEmployeeListdata([]);
                  setSalaryReportData([]);
                  setTenderDetails({});
                  setSelectedVendor(null);
                  setSelectedTender(select);
                  getTenderDetails(select);
                  setMonthYear("");
                  setTenderErr("");
                }}
                disabled={
                  !selectedDepartment &&
                  currentUser &&
                  Number(currentUser) !== 3
                }
                errorMessage={tenderErr}
                asterisk={"*"}
              />
            </Col>
            <UserRestrictions permittedUsers={[1, 3]} roleId={currentUser}>
              <Col sm={3}>
                <DropdownInput
                  label="Select Vendor / Company"
                  placeholder="Select vendor / company"
                  options={vendorList}
                  defaultValue={selectedVendor}
                  onSelectHandler={(select: any) => {
                    setEmployeeListdata([]);
                    setSalaryReportData([]);
                    setTenderDetails({});
                    setSelectedVendor(select);
                    setMonthYear("");
                    setVendorErr("");
                  }}
                  disabled={!selectedTender}
                  errorMessage={vendorErr}
                  asterisk={"*"}
                />
              </Col>
            </UserRestrictions>
            <Col sm={3}>
              <Form.Label>
                Select year <span className="text-danger">*</span>{" "}
              </Form.Label>
              <ReactDatePicker
                className="form-control"
                placeholderText="Select year"
                selected={monthYear}
                onChange={(date: any) => {
                  setEmployeeListdata([]);
                  setSalaryReportData([]);
                  setTenderDetails({});
                  setMonthYear(date);
                  setMonthYearErr("");
                }}
                dateFormat="yyyy"
                showYearPicker
                disabled={loginVendor ? !selectedTender : !selectedVendor}
                minDate={
                  // selectedTenderDetails.start_date
                  //   ? customFunctions.getDatefromMonth(
                  //       selectedTenderDetails.start_date
                  //     )
                  //   : null
                  selectedVendor?.start_date
                    ? customFunctions.getDatefromMonth(
                      selectedVendor?.start_date
                    )
                    : null
                }
                maxDate={
                  // selectedTenderDetails.end_date
                  //   ? customFunctions.getMaxDatefromMonth(
                  //       selectedTenderDetails.end_date
                  //     )
                  //   : null
                  selectedVendor?.end_date
                    ? customFunctions.getMaxDatefromMonth(
                      selectedVendor?.end_date
                    )
                    : null
                }
              />
              <span style={{ color: "red" }}> {monthYearErr} </span>
            </Col>
          </Row>
          <Row>
            <Col className="report-btn">
              <Button
                style={{ marginTop: "10px" }}
                onClick={(e) => {
                  e.preventDefault();
                  filterSearch();
                }}
              >
                Submit
              </Button>
              <Button
                variant="secondary"
                style={{ marginTop: "10px", marginLeft: "10px" }}
                onClick={(e) => {
                  e.preventDefault();
                  resetData();
                }}
              >
                Clear
              </Button>
            </Col>
          </Row>
          {tenderDetails && Object.keys(tenderDetails).length ? (
            <>
              <hr />
              <Row className="d-flex mt-4">
                <Col className="mb-2" sm={3}>
                  Tender Id :
                </Col>
                <Col className="mb-2" sm={9}>
                  {tenderDetails?.tender_no}
                </Col>
                <Col className="mb-2" sm={3}>
                  Tender Name :
                </Col>
                <Col className="mb-2" sm={9}>
                  {tenderDetails?.name}
                </Col>
                <Col className="mb-2" sm={3}>
                  Department :
                </Col>
                <Col className="mb-2" sm={9}>
                  {tenderDetails?.department?.name}
                </Col>
                <Col className="mb-2" sm={3}>
                  No Of Labour Allowed :
                </Col>
                <Col className="mb-2" sm={9}>
                  {tenderDetails?.labour_count}
                </Col>
                <Col className="mb-2" sm={3}>
                  Work Order Start Date :
                </Col>
                <Col className="mb-2" sm={9}>
                  {tenderDetails &&
                    customFunctions.getDate(tenderDetails.start_date)}
                </Col>
                <Col className="mb-2" sm={3}>
                  Work Order End Date :
                </Col>
                <Col className="mb-2" sm={9}>
                  {tenderDetails &&
                    customFunctions.getDate(tenderDetails.end_date)}
                </Col>
              </Row>
            </>
          ) : null}
        </Card.Body>
      </Card>
      {rows && rows.length > 0 && (
        <Row className="vender-table-wrapper">
          <Col sm={5}>
            <Card>
              <Card.Header style={{ textAlign: "center" }}>
                List of employees
              </Card.Header>
              <Card.Body className="list-of-employees">
                {/* <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th scope="col">No</th>
                      <th scope="col">Name</th>
                    </tr>
                  </thead>
                  <tbody>
                    {employeeListdata && employeeListdata.length > 0
                      ? employeeListdata.map((item: any, i: any) => (
                          <tr>
                            <td>{i + 1}</td>
                            <td>
                              {customFunctions.capitalizeFirstLetter(
                                item?.fname
                              )}{" "}
                              {item.mname
                                ? customFunctions.capitalizeFirstLetter(
                                    item?.mname
                                  )
                                : ""}{" "}
                              {customFunctions.capitalizeFirstLetter(
                                item?.lname
                              )}
                            </td>
                          </tr>
                        ))
                      : "No record found"}
                  </tbody>
                </table> */}

                <Table
                  columns={columns}
                  showCheckbox={false}
                  rows={rows}
                  showPagination
                  role={currentUser}
                  paginationProps={{
                    ...pagination,
                    activePage: pagination.currentPage,
                    onPageChange: (pageNo: any) => {
                      getEmployeeList(pageNo);
                    },
                  }}
                  dropdownContainerClass="mb-3"
                  emptyRender={() => (
                    <p className="text-center mb-0">No record found</p>
                  )}
                />
              </Card.Body>
              <Card.Footer>
                <Row>
                  <Col>
                    <a
                      href="javascript:void(0)"
                      onClick={(e) => {
                        e.preventDefault();
                        navigate("/add-employee", {
                          state: {
                            fromComponent: "vendorDashboard",
                            tenderData: {
                              name: selectedTender?.label,
                              id: selectedTender?.value,
                            },
                            venderData: {
                              label:
                                currentUser && Number(currentUser) == 2
                                  ? loginVendorName
                                  : selectedVendor?.label
                                    ? selectedVendor?.label
                                    : "",
                              value:
                                currentUser && Number(currentUser) == 2
                                  ? loginVendor
                                  : selectedVendor?.value
                                    ? selectedVendor?.value
                                    : "",
                              vendor_tender_id:
                                currentUser &&
                                  Number(currentUser) !== 2 &&
                                  selectedVendor.vendor_tender_id
                                  ? selectedVendor.vendor_tender_id
                                  : "",
                            },
                            tenderVendorId: tenderVendorId,
                          },
                        });
                      }}
                    >
                      Add Employee
                    </a>
                  </Col>
                  {/* {
                      employeeListdata && employeeListdata.length > 10 && (
                        <Col>
                          <a href='javascript:void(0)'
                            onClick={(e) => {
                              e.preventDefault();
                              navigate('/employee', {
                                state: {
                                  formComponent: 'vendor',
                                  tenderData: {
                                    label: selectedTender.label,
                                    value: selectedTender.value
                                  },
                                  venderData: {
                                    label: state.state.name,
                                    value: vendorId
                                  },
                                  formComponent: "vendorDashboard"
                                }
                              })
                            }}
                          >
                            View All Employee
                          </a>
                        </Col>
                      )
                    } */}
                </Row>
              </Card.Footer>
            </Card>
          </Col>
          <Col>
            <Card>
              <Card.Header>
                <div className="d-flex justify-content-between">
                  <div className="">
                    {`Salary report of year - ${selectedmonthYear
                      ? new Date(selectedmonthYear).getFullYear()
                      : ""
                      }`}
                  </div>
                  <div className="">
                    <a
                      href="javascript:void(0)"
                      style={{ textDecoration: "underline" }}
                      onClick={(e) => {
                        e.preventDefault();
                        //   generateReport();
                        setGererateMonth(true);
                      }}
                    >
                      Generate Certificate
                    </a>
                  </div>
                </div>
              </Card.Header>
              <Card.Body>
                <table className="table table-bordered mb-0">
                  <thead>
                    <tr>
                      <th scope="col">Month</th>
                      <th scope="col">Salary Status</th>
                      <th scope="col">Report</th>
                    </tr>
                  </thead>
                  <tbody>
                    {salaryReportData && salaryReportData.length > 0
                      ? salaryReportData &&
                      salaryReportData.map((item: any) => (
                        <tr>
                          <td>{item.date}</td>
                          <td>
                            <span
                              // className={
                              //   item.salaryStatus == "paid"
                              //     ? "green"
                              //     : item.salaryStatus == "unpaid"
                              //     ? "red"
                              //     : "orange"
                              // }
                              style={{
                                color:
                                  item.salaryStatus == "paid"
                                    ? "green"
                                    : item.salaryStatus == "unpaid"
                                      ? "red"
                                      : "orange",
                              }}
                            >
                              {item.salaryStatus
                                ? customFunctions.capitalizeFirstLetter(
                                  item.salaryStatus
                                )
                                : "-"}
                            </span>
                          </td>
                          <td>
                            {item.salaryStatus === "paid" ? (
                              <a
                                href="javascript:void(0)"
                                onClick={(e) => {
                                  e.preventDefault();
                                  downloadReport(item.date);
                                }}
                              >
                                Download Report
                              </a>
                            ) : (
                              <a
                                href="javascript:void(0)"
                                onClick={(e) => {
                                  e.preventDefault();
                                  if (!item.type) return;
                                  navigate("/report", {
                                    state: {
                                      formComponent: "vendor",
                                      tenderData: {
                                        label: selectedTender.label,
                                        value: selectedTender.value,
                                      },
                                      venderData: {
                                        label:
                                          currentUser && currentUser == 2
                                            ? loginVendorName
                                            : selectedVendor.label
                                              ? selectedVendor.label
                                              : "",
                                        value:
                                          currentUser && currentUser == 2
                                            ? loginVendor
                                            : selectedVendor.value
                                              ? selectedVendor.value
                                              : "",
                                        start_date: selectedVendor.start_date
                                          ? selectedVendor.start_date
                                          : "",
                                        end_date:
                                          selectedVendor.end_date
                                            ? selectedVendor.end_date
                                            : "",
                                      },
                                      departmentData: {
                                        name:
                                          currentUser &&
                                          Number(currentUser) !== 3 &&
                                          selectedDepartment.name,
                                        id:
                                          currentUser &&
                                          Number(currentUser) !== 3 &&
                                          selectedDepartment.id,
                                      },
                                      selectedMonth: new Date(
                                        `01/${item.date}/${new Date(
                                          selectedmonthYear
                                        ).getFullYear()}`
                                      ),
                                    },
                                  });
                                }}
                              >
                                {item.type ? "Generate" : <td title="Vendor is not exists for this duration">-</td>}
                              </a>
                            )}
                          </td>
                        </tr>
                      ))
                      : "No record found"}
                  </tbody>
                </table>
              </Card.Body>
              <Card.Footer>
                <div className="d-flex justify-content-between">
                  <div>
                    <p className="mb-0" style={{ color: "green" }}>
                      Paid (100% paid)
                    </p>
                  </div>
                  <div>
                    <p className="mb-0" style={{ color: "orange" }}>
                      Pending(Partially paid)
                    </p>
                  </div>
                  <div>
                    <p className="mb-0" style={{ color: "Red" }}>
                      Unpaid(100 % unpaid)
                    </p>
                  </div>
                </div>
              </Card.Footer>
            </Card>
          </Col>
        </Row>
      )}
      {attendencePopUP &&(
        <AttendencePopUp 
        employeeData={employeeData}
        popupClose={() => setAttendencePopUP(!attendencePopUP)}
        callback={()=>{}}
        gererateMonth={attendencePopUP}
        />
      )}
      {gererateMonth && (
        <VendorDashboardPopUp
          gererateMonth={gererateMonth}
          //  callback={(firstMonth, secondMonth) => {
          //  setLineCompareToggle('month');
          //  getBookingCompareData(firstMonth, secondMonth);
          //  }}
          start_date={selectedVendor?.start_date}
          end_date={selectedVendor?.end_date}
          popupClose={() => setGererateMonth(!gererateMonth)}
          tenderData={
            currentUser && [1, 3, 2].includes(Number(currentUser))
              ? {
                label: selectedTender?.label,
                value: selectedTender?.value,
              }
              : ""
          }
          vendorData={
            currentUser && [1, 3].includes(Number(currentUser))
              ? {
                label: selectedVendor?.label,
                value: selectedVendor?.value,
              }
              : {
                label: loginVendorName,
                value: loginVendor,
              }
          }
          loginVendor={loginVendor}
          selectedTenderDetails={selectedTenderDetails}
        />
      )}
    </>
  );
};

export default VendorDashboard;
