import React, { useEffect, useRef, useState } from "react";
import swal from "sweetalert";
import SimpleReactValidator from "simple-react-validator";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Card, Col, Row } from "react-bootstrap";
import {
  TextInput,
  Helmet,
  PasswordInput,
  Datepicker,
  DropdownInput,
} from "../../components/FormInputs";
import Breadcrumb from "../../components/Breadcrumb/breadcrumb.component";
import Api from "../../helper/api";
import AuthApi from "../../helper/authApi";
import customFunctions from "../../helper/customFunctions";
import Loader from "../../components/Loader";
import { vendorData } from "../../helper/vendorCompanyData";
import SelectAsyncPaginate from "../../components/FormInputs/SelectAsyncPaginate";
import { useDispatch } from "react-redux";
import { filter, filterValue } from "../../store/redux_slice";

const AddTender = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  
  const { state } = useLocation();
  const validator = useRef(new SimpleReactValidator());

  const [tenderId, setTenderId] = useState<any>(null);
  const [pageType, setPageType] = useState<string>("add");
  const [title, setTitle] = useState<string>("Add Tender");
  const [count, forceUpdate] = useState<number>(0);
  const [disableButton, setDisableButton] = useState<boolean>(false);
  const [isShowLoader, setShowLoader] = useState<boolean>(false);
  const [currentUser, updateCurrentUser] = useState<any>(1);
  const [loginVendor, updateLoginVendor] = useState<any>("");
  const [loginDepartment, updateLoginDepartment] = useState<any>("");
  const [selectedDepartment, setSelectedDepartment] = useState<any>(null);
  const [departmentValues, setdepartmentValues] = useState<any>([]);
  const [responseVendorArray, setResponseVendorArray] = useState<any>([]);
  const [vendorValues, setVendorValues] = useState<any>([]);
  const [vendorErr, setVendorErr] = useState<any>('');
  const [tenderDetails, setTenderDetails] = useState<any>({
    department: "",
    tenderName: "",
    tenderNumber: "",
    labourCount: "",
    workOrderStartDate: "",
    workOrderEndDate: "",
    tenderAmount: "",
    labourRate: "",
  });

  const [vendorAssign, setVendorAssign] = useState<any>([
    {
      vendorArr: vendorValues,
      vendor: "",
      labourAllowed: "",
      workOrderNumber: "",
      workOrderStartDate: "",
      workOrderEndDate: "",
    },
  ]);

  const items: any = [
    { to: "/", label: "Dashboard" },
    { to: "/tender", label: "Tenders" },
    { label: title },
  ];

  useEffect(() => {
    getUserRole();
    // getDepartment();
    // getVendor();
    if (state && state?.pageType === "add") return;
    if (state) {
      if (state?.pageType === "edit") {
        setTenderId(state?.id);
        setPageType("edit");
        setTitle("Edit Tender");
      }
    }
  }, [state]);

  const getUserRole = async () => {
    // const roleId = await localStorage.getItem("role_id");
    // const loginVendor = await localStorage.getItem("login_vendor");
    // const deptId = await localStorage.getItem("dept_id");
    const roleId = await customFunctions.getLocalStorage("role_id");
    const loginVendor = await customFunctions.getLocalStorage("login_vendor");
    const deptId = await customFunctions.getLocalStorage("dept_id");
    updateCurrentUser(Number(roleId));
    updateLoginVendor(Number(loginVendor));
    updateLoginDepartment(Number(deptId));
  };

  useEffect(() => {
    getUserRole();
  }, []);

  useEffect(() => {
    if (!tenderId) return;
    getTenderList();
  }, [tenderId]);

  useEffect(() => {
    getVendor();
    if (currentUser && Number(currentUser) !== 3) {
      // getDepartment();
    }
  }, [currentUser]);

  const getDepartment = async () => {
    setShowLoader(true);
    const { data, message } = await AuthApi.getDataFromServer(
      `${Api.departmentUrl}`
    );
    if (data && data.success === true) {
      setShowLoader(false);
      let Arr: any = [];
      if (data && data.data) {
        let Data: any = data.data.response;
        Data &&
          Data.length > 0 &&
          Data.map((i: any) => {
            Arr.push({ label: i.name, value: i.id });
          });
      }
      setdepartmentValues(Arr);
    } else {
      setShowLoader(false);
      swal(message, "", "error");
    }
  };

  const getVendor = async () => {
    setShowLoader(true);

    let params: any = {
      limit: 50
    }

    let endPoint = Api.vendorUrl;
    const url = customFunctions.generateUrl(endPoint, params);
    const { data, message } = await AuthApi.getDataFromServer(url);

    if (data && data.success === true) {
      setShowLoader(false);
      let Arr: any = [];
      if (data && data.data) {
        let Data: any = data.data.response;
        Data &&
          Data.length > 0 &&
          Data.map((i: any) => {
            Arr.push({ label: i.comp_name, value: i.id });
          });
      }
      setVendorValues(Arr);
    } else {
      setShowLoader(false);
      swal(message, "", "error");
    }
  };

  const getTenderList = async () => {
    setShowLoader(true);
    const { data, message } = await AuthApi.getDataFromServer(
      `${Api.tenderUrl}/id?id=${tenderId}`
    );
    if (data && data.success === true) {
      setShowLoader(false);
      if (data && data.data) {
        let Arr: any = [];
        let tenderData: any = data?.data?.response[0];
        setTenderDetails({
          department: {
            name: tenderData?.department?.name,
            id: tenderData?.department?.id,
          },
          tenderName: tenderData?.name,
          tenderNumber: tenderData?.tender_no,
          labourCount: tenderData?.labour_count,
          workOrderStartDate: new Date(tenderData?.start_date),
          workOrderEndDate: new Date(tenderData?.end_date),
          tenderAmount: tenderData?.tender_amount,
          labourRate: tenderData?.Min_wages,
        });

        tenderData &&
          tenderData?.tender_vendors.length > 0 &&
          tenderData?.tender_vendors.map((i: any) => {
            Arr.push({
              id: i.id,
              vendor: { label: i?.vendor?.comp_name, value: i?.vendor?.id },
              labourAllowed: i?.labour_allocated,
              workOrderNumber: i?.work_order_no,
              workOrderStartDate: new Date(i?.start_date),
              workOrderEndDate: new Date(i?.end_date),
            });
          });
        setVendorAssign(Arr);
      }
    } else {
      setShowLoader(false);
      swal(message, "", "error");
    }
  };

  const getVendorData = (pIndex: any) => {

    const serviceDetailsArr = [...vendorAssign];
    if (serviceDetailsArr && serviceDetailsArr.length) {
      let filterArr = vendorValues.filter((sr: any) => {
        return serviceDetailsArr.findIndex(
          (serv: any, i: any) =>
            sr?.value === serv?.vendor?.value && i !== pIndex
        ) === -1
          ? true
          : false;
      });
      serviceDetailsArr[pIndex].vendorArr = filterArr?.length ? filterArr : [];
    }
    setVendorAssign(serviceDetailsArr);
    forceUpdate(count + 1);
  };

  const onSubmit = (e: any) => {
    e.preventDefault();
    let isValid = true;
    if (loginDepartment) {
      validator.current.errorMessages.select_department = null;
      validator.current.fields.select_department = true;
    }

    vendorAssign && vendorAssign.map((item: any) => {
      for (let key in item) {
        if (['', null, {}, undefined].includes(item[key])) {
          isValid = false
        }
      }
    })
    // if (disableButton) return;
    // setDisableButton(true);
    if (validator.current.allValid() && isValid) {
      AddTender();
    } else {
      setDisableButton(false);
      validator.current.showMessages();
      forceUpdate(count + 1);
    }
  };

  const AddTender = async () => {
    setShowLoader(true);
    let isValid = true;
    const Arr: any = [];
    vendorAssign.length > 0 &&
      vendorAssign.map((itm: any) => {
        Arr.push({
          vendorId: itm?.vendor?.value,
          work_order_no: itm?.workOrderNumber,
          labour_allocated: itm?.labourAllowed,
          start_date: itm?.workOrderStartDate,
          end_date: itm?.workOrderEndDate,
        });

        const tenderEndDate = new Date(tenderDetails.workOrderEndDate).getTime();
        const tenderStartDate = new Date(tenderDetails.workOrderStartDate).getTime();
        const vendorEndDate = new Date(itm?.workOrderEndDate).getTime();
        const vendorStartDate = new Date(itm?.workOrderStartDate).getTime();


        if (vendorStartDate > vendorEndDate || vendorStartDate < tenderStartDate) {
          isValid = false;
          setShowLoader(false);
          swal('Vendor start date should be in between tender start and end date', '', 'error');
        }

        if (vendorEndDate > tenderEndDate) {
          isValid = false;
          setShowLoader(false);
          swal('Vendor end date should be in between tender start and end date', '', 'error');
        }
        // if (vendorStartDate < tenderStartDate) {
        //   isValid = false;
        //   setShowLoader(false);
        //   swal('Vendor start and end date should be in between tender start and end date', '', 'error');
        // }

      });

    if (!isValid) return;

    let sum = Arr.reduce((a: Number, b: any): Number => {
      return Number(a) + Number(b.labour_allocated);
    }, 0);

    if (Number(tenderDetails.labourCount) < sum) {
      swal(
        `Tender labour count is maximum ${tenderDetails.labourCount}`,
        "",
        "error"
      );
      setShowLoader(false);
      setDisableButton(false);
      return;
    }

    const postData: any = {
      name: tenderDetails.tenderName,
      description: '',
      tender_amount: tenderDetails.tenderAmount,
      start_date: tenderDetails.workOrderStartDate,
      end_date: tenderDetails.workOrderEndDate,
      Min_wages: tenderDetails.labourRate,
      departmentId: loginDepartment ? loginDepartment : tenderDetails.department.id,
      labour_count: tenderDetails.labourCount
    }

    let newPayload: any = {}

    if (tenderId) {
      newPayload.vendor_arr = Arr;
      newPayload.tender_id = tenderId
      newPayload.updateTender = {
        id: tenderId,
        ...postData
      };
      // delete newPayload.tender_no;
      newPayload.delete_vendor_arr = responseVendorArray;
    } else {
      newPayload = postData;
      newPayload.tender_no = tenderDetails.tenderNumber;
      newPayload.status = "1";
      newPayload.vendor_arr = Arr;
    }

    const payload: any = customFunctions.cleanObject(newPayload);
    setShowLoader(false);
    const endPoint: any = tenderId
      ? `${Api.tenderUrl}/add-delete-update-tender`
      : `${Api.tenderUrl}`;
    const callback: any = tenderId
      ? AuthApi.putDataToServer
      : AuthApi.postDataToServer;
    const { data, message } = await callback(endPoint, payload);
    if (data && data.success === true) {
      setDisableButton(false);
      swal(
        tenderId
          ? "Tender updated successfully!"
          : "Tender created successfully!",
        "",
        "success"
      ).then(() => {
        dispatch(filter([]));
        dispatch(filterValue({}));
        navigate('/tender');
      });
    } else {
      setDisableButton(false);
      setShowLoader(false);
      swal(message, "", "error");
    }
  };

  return (
    <>
      <Helmet title={title} />
      <div className="page-header">
        <div className="title-breadcrumb-section">
          <h2 className="main-content-title tx-24 mg-b-5">{title}</h2>
          <Breadcrumb items={items} />
        </div>
      </div>
      <Loader showLoader={isShowLoader} needFullPage={false} />
      <section className="content-area"></section>
      <Card>
        <Card.Body>
          <h6 className="section-title">Tender Details</h6>
          <hr />

          <Row>
            {currentUser == 1 && (
              <Col sm={6} md={6} lg={6}>

                <SelectAsyncPaginate
                  label="Select Department Name"
                  asterisk="*"
                  // isMulti
                  value={tenderDetails?.department}
                  disabled={pageType == "edit"}
                  clearSelected
                  onSelectChange={(select: any) => {
                    setTenderDetails({ ...tenderDetails, department: select });
                  }}
                  selectPlaceholder="Select department"
                  endpoint={`${Api.departmentUrl}`}
                  apiname={"departmentUrl"}
                  isClearable={true}
                  vendorId={undefined}
                  departmentId={undefined}
                  key={undefined}
                  className={undefined}
                  isSearch={undefined}
                  isMulti={undefined}
                  clearCacheOnID={undefined}
                  clearSelectedByParent={undefined}
                  adminType={undefined}
                  roleId={undefined}
                  currentUserId={undefined}
                  fromComponent={undefined}
                  errorMessage={validator.current.message(
                    "select_department",
                    tenderDetails.department,
                    "required"
                  )}
                  id="department"
                  selectedTenderId={undefined} />

                {/* <DropdownInput
                  id="department"
                  label="Select Department"
                  asterisk="*"
                  placeholder="Select department"
                  options={departmentValues}
                  defaultValue={tenderDetails?.department}
                  disabled={pageType == "edit"}
                  onSelectHandler={(select: any) => {
                    setTenderDetails({ ...tenderDetails, department: select });
                  }}
                  isClearable={true}
                  errorMessage={validator.current.message(
                    "select_department",
                    tenderDetails.department,
                    "required"
                  )}
                /> */}
              </Col>
            )}
            <Col sm={6} md={6} lg={6}>
              <TextInput
                onInputChange={(
                  e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                ) => {
                  const re = /[a-z A-Z 0-9 अ-अः क-ज्ञ – ा ि 	ी ु	ू	े	ै	ो	ौ	ं	ः ]/;
                  // const re = /^[^ !"`'#%&,:;<>=@{}~\$\(\)\*\+\/\\\?\[\]\^\|]+$ /
                  if (!e.target.value || re.test(e.target.value)) {
                    setTenderDetails({
                      ...tenderDetails,
                      tenderName: e.target.value,
                    });
                  }
                }}
                id="tenderName"
                label="Tender Name"
                asterisk="*"
                containerClass="form-group"
                type="text"
                name="tenderName"
                value={tenderDetails.tenderName}
                placeholder="Enter tender name"
                errorMessage={validator.current.message(
                  "tender name",
                  tenderDetails.tenderName,
                  "required"
                )}
                autoComplete="new-tenderName"
              />
            </Col>
            <Col sm={6} md={6} lg={6}>
              <TextInput
                onInputChange={(
                  e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                ) => {
                  setTenderDetails({
                    ...tenderDetails,
                    tenderNumber: e.target.value,
                  });
                }}
                id="tenderNumber"
                asterisk="*"
                label="Tender Number"
                containerClass="form-group"
                type="text"
                name="tenderNumber"
                disabled={pageType == 'edit'}
                value={tenderDetails.tenderNumber}
                placeholder="Enter tender number"
                autoComplete="new-tenderNumber"
                errorMessage={validator.current.message(
                  "tender number",
                  tenderDetails.tenderNumber,
                  "required"
                )}
              />
            </Col>
            <Col sm={6} md={6} lg={6}>
              <TextInput
                onInputChange={(
                  e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                ) => {
                  const re = /^[0-9]*$/;
                  if (!e.target.value || re.test(e.target.value)) {
                    setTenderDetails({
                      ...tenderDetails,
                      labourCount: e.target.value,
                    });
                  }
                }}
                id="labourCount"
                asterisk="*"
                label="Labour Count"
                containerClass="form-group"
                type="text"
                name="labourCount"
                value={tenderDetails.labourCount}
                placeholder="Enter labour count"
                autoComplete="new-labourCount"
                errorMessage={validator.current.message(
                  "labour count",
                  tenderDetails.labourCount,
                  "required|integer|min:1,num"
                )}
              />
            </Col>
            <Col sm={6} md={6} lg={6}>
              <Datepicker
                id="startDate"
                peekNextMonth={true}
                showMonthDropdown={true}
                showYearDropdown={true}
                dropdownMode="select"
                label="Work Order Start Date"
                className="form-control"
                selected={tenderDetails.workOrderStartDate}
                asterisk="*"
                // isClearable={tenderDetails.workOrderStartDate}
                placeholder="yyyy-mm-dd"
                dateFormat="yyyy-MM-dd"
                // maxDate={new Date()}
                onChange={(e: any) => {
                  vendorAssign && vendorAssign.map((item: any) => {
                    item.workOrderStartDate = '';
                    item.workOrderEndDate = '';
                  })
                  setVendorAssign([...vendorAssign]);
                  setTenderDetails({
                    ...tenderDetails,
                    workOrderStartDate: e,
                    workOrderEndDate: null,
                  });
                }}
                errorMessage={validator.current.message(
                  "Work Order Start Date",
                  tenderDetails.workOrderStartDate,
                  "required"
                )}
              />
            </Col>
            <Col sm={6} md={6} lg={6}>
              <Datepicker
                id="endDate"
                peekNextMonth={true}
                showMonthDropdown={true}
                showYearDropdown={true}
                dropdownMode="select"
                label="Work Order End Date"
                className="form-control"
                selected={tenderDetails.workOrderEndDate}
                asterisk="*"
                placeholder="yyyy-mm-dd"
                dateFormat="yyyy-MM-dd"
                // isClearable={tenderDetails.workOrderEndDate}
                disabled={!tenderDetails.workOrderStartDate}
                minDate={new Date(tenderDetails.workOrderStartDate)}
                onChange={(e: any) => {
                  vendorAssign && vendorAssign.map((item: any) => {
                    item.workOrderStartDate = '';
                    item.workOrderEndDate = '';
                  })
                  setVendorAssign([...vendorAssign]);
                  setTenderDetails({ ...tenderDetails, workOrderEndDate: e });
                }}
                errorMessage={validator.current.message(
                  "Work Order End Date",
                  tenderDetails.workOrderEndDate,
                  "required"
                )}
              />
            </Col>
            <Col sm={6} md={6} lg={6}>
              <TextInput
                onInputChange={(
                  e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                ) => {
                  const re = /^[0-9]*$/;
                  if (!e.target.value || re.test(e.target.value)) {
                    setTenderDetails({
                      ...tenderDetails,
                      tenderAmount: e.target.value,
                    });
                  }
                }}
                id="tenderAmount"
                asterisk="*"
                label="Tender Amount"
                containerClass="form-group"
                type="text"
                name="tenderAmount"
                value={tenderDetails.tenderAmount}
                placeholder="Enter tender amount"
                autoComplete="new-tenderAmount"
                errorMessage={validator.current.message(
                  "tender amount",
                  tenderDetails.tenderAmount,
                  "required|integer|min:1,num"
                )}
              />
            </Col>
            <Col sm={6} md={6} lg={6}>
              <TextInput
                onInputChange={(
                  e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                ) => {
                  const re = /^[0-9]*$/;
                  if (!e.target.value || re.test(e.target.value)) {
                    setTenderDetails({
                      ...tenderDetails,
                      labourRate: e.target.value,
                    });
                  }
                }}
                id="labourRate"
                asterisk="*"
                label="Aproved Labour Rate Through Tender Recieved"
                containerClass="form-group"
                type="text"
                name="labourRate"
                value={tenderDetails.labourRate}
                placeholder="Enter aproved labour rate through tender recieved"
                autoComplete="new-labourRate"
                errorMessage={validator.current.message(
                  "Aproved Labour Rate Through Tender Recieved",
                  tenderDetails.labourRate,
                  "required|integer|min:1,num"
                )}
              />
            </Col>
          </Row>
          <h6 className="section-title">Assign Vendor/Company</h6>
          <hr />
          {vendorAssign &&
            vendorAssign.map((item: any, index: any) => (
              <>
                <Row key={`rowid:${index}`}>
                  <Col sm={6} md={6} lg={6}>
                    <DropdownInput
                      id="Vendor"
                      label="Select Vendor/Company"
                      asterisk="*"
                      placeholder="Select vendor"
                      options={item.vendorArr}
                      // options={vendorValues}
                      disabled={pageType == "edit" && item.id}
                      defaultValue={item?.vendor}
                      onSelectHandler={(select: any) => {
                        vendorAssign[index].vendor = select;
                        setVendorAssign([...vendorAssign]);
                      }}
                      onMenuOpen={() => getVendorData(index)}
                      isClearable={true}
                      errorMessage={
                        validator.current.message(
                          "select vendor",
                          item.vendor,
                          "required"
                        )
                      }
                    />

                    {/* <SelectAsyncPaginate
                      label="Select Vendor/Company"
                      asterisk="*"
                      value={item?.vendor}
                      disabled={pageType == "edit" && item.id}
                      clearSelected
                      onSelectChange={(select: any) => {
                        vendorAssign[index].vendor = select;
                        setVendorAssign([...vendorAssign]);
                        getVendorData(index)
                      }}
                      selectPlaceholder="Select vendor"
                      endpoint={`${Api.vendorUrl}`}
                      apiname={"vendorUrl"}
                      isClearable={true}
                      // onMenuOpen={() => getVendorData(index)}
                      onMenuOpen={undefined}
                      key={undefined}
                      className={undefined}
                      isSearch={undefined}
                      isMulti={undefined}
                      clearCacheOnID={undefined}
                      clearSelectedByParent={undefined}
                      adminType={undefined}
                      roleId={undefined}
                      currentUserId={undefined}
                      vendorId={undefined}
                      fromComponent={undefined}
                      errorMessage={validator.current.message(
                        "select vendor",
                        item.vendor,
                        "required"
                      )}
                      id="Vendor"
                    /> */}


                  </Col>
                  <Col sm={6} md={6} lg={6}>
                    <TextInput
                      onInputChange={(
                        e: React.ChangeEvent<
                          HTMLTextAreaElement | HTMLInputElement
                        >
                      ) => {
                        const re = /^[0-9]*$/;
                        if (!e.target.value || re.test(e.target.value)) {
                          vendorAssign[index].labourAllowed = e.target.value;
                          setVendorAssign([...vendorAssign]);
                        }
                      }}
                      id="labourAllowed"
                      label="No of Labour Allowed"
                      asterisk="*"
                      containerClass="form-group"
                      name="labourAllowed"
                      value={item.labourAllowed}
                      placeholder="Enter labour count"
                      errorMessage={validator.current.message(
                        "No of Labour Allowed",
                        item.labourAllowed,
                        "required|integer|min:1,num"
                      )}
                      autoComplete="new-tenderName"
                    />
                  </Col>
                  <Col sm={6} md={6} lg={6}>
                    <TextInput
                      onInputChange={(
                        e: React.ChangeEvent<
                          HTMLTextAreaElement | HTMLInputElement
                        >
                      ) => {
                        vendorAssign[index].workOrderNumber = e.target.value;
                        setVendorAssign([...vendorAssign]);
                      }}
                      id="workOrder"
                      asterisk="*"
                      label="Work Order Number"
                      containerClass="form-group"
                      type="text"
                      name="workOrder"
                      value={item.workOrderNumber}
                      placeholder="Enter work order number"
                      autoComplete="new-tenderNumber"
                      errorMessage={validator.current.message(
                        "work order number",
                        item.workOrderNumber,
                        "required"
                      )}
                    />
                  </Col>

                  <Col sm={6} md={6} lg={6}>
                    <Datepicker
                      id="startDate"
                      peekNextMonth={true}
                      showMonthDropdown={true}
                      showYearDropdown={true}
                      dropdownMode="select"
                      label="Vendor Work Order Start Date"
                      className="form-control"
                      selected={item.workOrderStartDate}
                      asterisk="*"
                      placeholder="yyyy-mm-dd"
                      dateFormat="yyyy-MM-dd"
                      disabled={!tenderDetails.workOrderEndDate}
                      minDate={new Date(tenderDetails.workOrderStartDate)}
                      maxDate={new Date(tenderDetails.workOrderEndDate)}
                      onChange={(e: any) => {
                        vendorAssign[index].workOrderStartDate = e;
                        vendorAssign[index].workOrderEndDate = null;
                        setVendorAssign([...vendorAssign]);
                      }}
                      errorMessage={validator.current.message(
                        "Vendor Work Order Start Date",
                        item.workOrderStartDate,
                        "required"
                      )}
                    />
                  </Col>
                  <Col sm={6} md={6} lg={6}>
                    <Datepicker
                      id="endDate"
                      peekNextMonth={true}
                      showMonthDropdown={true}
                      showYearDropdown={true}
                      dropdownMode="select"
                      label="Vendor Work Order End Date"
                      className="form-control"
                      selected={item.workOrderEndDate}
                      asterisk="*"
                      placeholder="yyyy-mm-dd"
                      dateFormat="yyyy-MM-dd"
                      disabled={!item.workOrderStartDate}
                      minDate={new Date(item.workOrderStartDate)}
                      maxDate={new Date(tenderDetails.workOrderEndDate)}
                      onChange={(e: any) => {
                        vendorAssign[index].workOrderEndDate = e;
                        setVendorAssign([...vendorAssign]);
                      }}
                      errorMessage={validator.current.message(
                        "Vendor Work Order End Date",
                        item.workOrderEndDate,
                        "required"
                      )}
                    />
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col sm={6} md={6} lg={6}>
                    {vendorAssign.length > 1 && (
                      <Button
                        variant="btn btn-primary"
                        type="button"
                        className="mr-3"
                        onClick={(e) => {

                          e.preventDefault();
                          if (vendorAssign.length == 1) {
                            return;
                          }
                          swal({
                            title: "Are you sure?",
                            text: "you want to remove this field ",
                            icon: "warning",
                            buttons: ["cancel", true],
                            dangerMode: true,
                          }).then((ok) => {
                            if (ok) {
                              if (item?.vendor?.value) {
                                let arr = [...responseVendorArray]
                                arr.push(item?.vendor?.value)
                                setResponseVendorArray(arr)
                              }
                              let newVal = vendorAssign.filter(
                                (item: any, ind: any) => {
                                  if (index !== ind) return item;
                                }
                              );
                              setVendorAssign([...newVal]);
                            }
                          });
                        }}
                      >
                        remove
                      </Button>
                    )}

                    {
                      vendorAssign.length - 1 == index &&
                      vendorValues.length !== vendorAssign.length &&
                      (
                        <Button
                          variant="btn btn-primary"
                          type="button"
                          className="workhrs-btn mr-3"
                          onClick={(e) => {
                            e.preventDefault();
                            let newAssign = vendorAssign;
                            newAssign.push({
                              vendorArr: vendorValues,
                              vendor: "",
                              labourAllowed: "",
                              workOrderNumber: "",
                              workOrderStartDate: "",
                              workOrderEndDate: "",
                            });
                            setVendorAssign([...newAssign]);
                          }}
                        >
                          Add More
                        </Button>
                      )}
                  </Col>
                </Row>
              </>
            ))}

          {pageType !== "view" && (
            <Col>
              <div style={{ float: "left" }} className="form-group mt-3">
                <Button
                  type="submit"
                  variant="primary"
                  onClick={(e) => {
                    e.preventDefault();
                    onSubmit(e);
                  }}
                  disabled={disableButton}
                >
                  Submit
                </Button>
                <Button
                  onClick={(e) => {
                    e.preventDefault();
                    navigate('/tender');
                  }}
                  variant="secondary"
                  className="ms-2"
                >
                  Cancel
                </Button>
              </div>
              <br />
            </Col>
          )}
        </Card.Body>
      </Card>
    </>
  );
};

export default AddTender;
