import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";

const HelmetComponent = (props:any) => {
  const { title } = props;
  return (
    <div>
      <HelmetProvider>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{`${title} | Contract Employee Portal`}</title>
          <meta name="description" content="" />
          <link rel="canonical" href="" />
        </Helmet>
      </HelmetProvider>
    </div>
  );
};

export default HelmetComponent;
