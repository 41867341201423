import React, { useEffect, useState } from "react";
import { Accordion, Row, Col, Button, Card, Image } from "react-bootstrap";
import Loader from "../../components/Loader";
import customFunctions from "../../helper/customFunctions";
import Table from "../../components/Table/simpletable.component";
import Breadcrumb from "../../components/Breadcrumb/breadcrumb.component";
import { Helmet, TextInput } from "../../components/FormInputs";
import AddDepartment from "./addDepartmentComponent";
import swal from "sweetalert";
import Api from "../../helper/api";
import AuthApi from "../../helper/authApi";
import SweetAlert from "react-bootstrap-sweetalert";

const DepartmentListing = () => {

  const title = "Departments";
  const [isShowLoader, setShowLoader] = useState<boolean>(false);
  const [rows, setRows] = useState<any>([]);
  const [filterErr, setFilterErr] = useState<any>("");
  const [searchKeyword, setSearchKeyword] = useState<any>("");
  const [departmentData, setDepartmentData] = useState<any>([]);
  const [toggle, setToggle] = useState<boolean>(true);
  const [statusToggle, setStatusToggle] = useState<boolean>(false);
  const [applyToggle, setApplyToggle] = useState<any>(false);
  const [count, forceUpdate] = useState<number>(0);
  const [statusId, setStatusId] = useState<any>("");
  const [departmentId, setDepartmentId] = useState<any>(null);
  const [userRole, setUserRole] = useState<any>(0);

  const [propsData, setPropsData] = useState<any>({
    data: "",
    pageType: "add",
  });

  const initialPagination: any = {
    currentPage: 1,
    itemsPerPage: 5,
    totalItems: 1,
  };

  useEffect(() => {
    const uRole = customFunctions.getLocalStorage('role_id');
    setUserRole(Number(uRole));
  }, []);

  const [pagination, setPagination] = useState<any>(initialPagination);

  const items: any = [{ to: "/", label: "Dashboard" }, { label: title }];

  const columns = [
    {
      label: "Name",
      id: "name",
    },
    // {
    //   label: "Status",
    //   id: "status",
    // },
    {
      label: "Action",
      id: "action",
    },
  ];

  useEffect(() => {
    getDepartmentList();
  }, []);

  useEffect(() => {
    setTableData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [departmentData]);

  const getDepartmentList = async (pageNo?: any, clearFilter?: boolean) => {
    if (!applyToggle && clearFilter && !searchKeyword) return;
    const page: any = pageNo || 1;
    setShowLoader(true);
    setApplyToggle(false);
    const endPoint: any = Api.departmentUrl;

    const params: any = {
      page: page,
      limit: pagination.itemsPerPage,
      search: !clearFilter && searchKeyword ? searchKeyword.trim() : "",
      sort_direction: "descending",
    };

    const url: any = customFunctions.generateUrl(endPoint, params);

    const { data, message } = await AuthApi.getDataFromServer(url);

    if (data && data.success === true) {

      setShowLoader(false);
      if (data && data.data) {
        setPagination({
          itemsPerPage: Number(5),
          currentPage: data.data.page,
          totalItems: data.data.count,
        });
        setDepartmentData(data.data.response);
      } else {
        setDepartmentData([]);
      }
    } else {
      setShowLoader(false);
      setDepartmentData([]);
      swal(message, "", "error");
    }
  };

  const setTableData = () => {
    const rowsData: any = [];
    departmentData.map((dt: any, index: any) => {
      rowsData.push({
        id: dt?.id,
        name: dt?.name,
        // status: (
        //   <>
        //     <div className="switch-check">
        //       <div
        //         className="form-check form-switch"
        //         style={{ marginBottom: "0" }}
        //       >
        //         <input
        //           className="form-check-input"
        //           type="checkbox"
        //           role="switch"
        //           id="flexSwitchCheckDefault"
        //           checked={dt?.status == "1" ? true : false}
        //           onClick={() => {
        //             setStatusToggle(true);
        //             setStatusId(dt?.status == "2" ? "1" : "2");
        //             setDepartmentId(dt?.id);
        //           }}
        //           title={dt?.status == "1" ? "Active" : "Inactive"}
        //         />
        //       </div>
        //     </div>
        //   </>
        // ),
        action: (
          <>
            <a
              href="/"
              className="pl-2 ms-1"
              title={"Edit"}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setPropsData({ data: dt, pageType: "edit" });
              }}
            >
              <Image src="images/edit-button.svg" alt="edit" />
            </a>
            {
              userRole && Number(userRole) == 1 &&
              <Image
                src="images/delete.png"
                alt="delete"
                title="Delete"
                className="pl-2 ms-1"
                onClick={(e: any) => {
                  e.preventDefault();
                  e.stopPropagation();
                  swal({
                    title: "Are you sure?",
                    text: "you want to delete this department record.",
                    icon: "warning",
                    buttons: ["Cancel", true],
                    dangerMode: true,
                  }).then((willDelete) => {
                    if (willDelete) {
                      deleteDepartment(dt?.id);
                    }
                  });
                }}
              />
            }
          </>
        ),
      });
      return dt;
    });
    setRows([...rowsData]);
  };

  const handleBulkDelete = async (selectedIds: any) => {

    if (!customFunctions.checkIfEmpty(selectedIds, 'A')) {
      const postData = {
        id: selectedIds
      }
      setShowLoader(true);
      const { data, message } = await AuthApi.deleteDataFromServer(
        `${Api.departmentUrl}/change-department-status`, postData);
      if (data) {
        setShowLoader(false);
        swal('Department deleted sucessfully', '', 'success', {
          closeOnClickOutside: false,
        }).then(() => {
          // swal.close();
          if (Number(rows.length) === 1) {
            getDepartmentList(pagination.currentPage - 1);
          } else {
            getDepartmentList(pagination.currentPage);
          }
        });
      } else {
        setShowLoader(false);
        swal(message, '', 'error', {
          closeOnClickOutside: false,
        });
      }
    };
  };

  const deleteDepartment = async (id: any) => {
    const postData: any = {
      id: [id]
    }
    setShowLoader(true);
    const endpoint: any = `${Api.departmentUrl}/change-department-status`;
    const { data, message } = await AuthApi.deleteDataFromServer(endpoint, postData);
    if (data && data.success === true) {
      let msg:any=data.data;
      setStatusToggle(false);
      swal(data.data && typeof data.data == 'string'? data.data :  "Department deleted successfully!", "",
       data.data && typeof data.data == 'string'? "error" : "success", {
        closeOnClickOutside: false,
      }).then(() => {
        setDepartmentId(null);
        setStatusId("");
        getDepartmentList(1);
      });
    } else {
      setShowLoader(false);
      swal(message, "", "error").then(() => {
        setDepartmentId(null);
        setStatusId("");
        getDepartmentList(1);
      });
    }
  };

  const filterSearch = (e: any) => {
    e.preventDefault();
    let filterSearchErr: string = "";
    let isValid: boolean = true;
    if (customFunctions.checkIfEmpty(searchKeyword)) {
      filterSearchErr = "Please enter name";
      setFilterErr(filterSearchErr);
      isValid = false;
    }
    if (isValid) {
      setFilterErr("");
      getDepartmentList(1);
    }
  };

  const changeStatus = async () => {
    // const postData: any = {
    //   id: Number(departmentId),
    //   status: statusId,
    // };
    // if (!departmentId) return;
    // setShowLoader(true);
    // const endpoint: any = `${Api.departmentUrl}/change-department-status?id=${departmentId}`;
    // const { data, message } = await AuthApi.deleteDataFromServer(endpoint, postData);
    // if (data && data.success === true) {
    //   setStatusToggle(false);
    //   swal("Department status changed successfully!", "", "success", {
    //     closeOnClickOutside: false,
    //   }).then(() => {
    //     setDepartmentId(null)
    //     setStatusId("");
    //     getDepartmentList(1);
    //   });
    // } else {
    //   setShowLoader(false);
    //   swal( message, "", "error").then(() => {
    //     setDepartmentId(null)
    //     setStatusId("");
    //     getDepartmentList(1);
    //   });
    // }
  };

  const onClear = (e: any) => {
    e.preventDefault();
    setFilterErr("");
    setSearchKeyword("");
    getDepartmentList(1, true);
  };

  return (
    <>
      <Helmet title={title} />
      <div className="page-header">
        <div className="title-breadcrumb-section">
          <h2 className="main-content-title tx-24 mg-b-5">{title}</h2>
          <Breadcrumb items={items} />
        </div>
      </div>
      {/* {statusToggle && (
        <div className="sweetalert">
          <SweetAlert
            title={"Are you sure, do you want to change the status ?"}
            warning
            showCancel
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() => {
              setStatusToggle(false);
              changeStatus();
            }}
            onCancel={() => {
              setStatusToggle(false);
            }}
          />
        </div>
      )} */}
      <Loader showLoader={isShowLoader} needFullPage={false} />
      <section className="content-area">
        <Accordion defaultActiveKey="1">
          <Accordion.Item eventKey="1">
            <Accordion.Header>
              <h5 className="card-title mb-0">Filters</h5>
            </Accordion.Header>
            <Accordion.Body>
              {filterErr && (
                <div className="p-3  bg-danger text-white bg-danger-main table-red-bg mb-3">
                  <div className="img-text">
                    <img
                      style={{ cursor: "pointer" }}
                      onClick={(e) => onClear(e)}
                      src="/images/fail.svg"
                      alt="Success"
                    />{" "}
                    {filterErr}
                  </div>
                </div>
              )}
              <Row className="mt-3">
                <Col sm={3} md={3} lg={3}>
                  <TextInput
                    value={searchKeyword}
                    placeholder="Search by name"
                    onInputChange={(e: any) => {
                      setSearchKeyword(e.target.value);
                    }}
                  />
                </Col>

                <Col sm={3} md={3} lg={3}>
                  <div className="filter-btn pull-right filter-btn-report">
                    <Button
                      variant="primary"
                      type="submit"
                      className="ms-4"
                      onClick={(e) => {
                        e.preventDefault();
                        filterSearch(e);
                      }}
                    >
                      Submit
                    </Button>
                    <Button
                      variant="secondary"
                      type="button"
                      className="ms-4"
                      onClick={(e) => onClear(e)}
                    >
                      Clear
                    </Button>
                  </div>
                </Col>
              </Row>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <Row>
          <Col sm={6} md={6} lg={6}>
            <AddDepartment
              data={propsData}
              callbackProps={() => getDepartmentList()}
            />
          </Col>
          <Col sm={6} md={6} lg={6}>
            <Card className="mt-3">
              <Card.Body>
                <Table
                  columns={columns}
                  // showCheckbox={userRole && Number(userRole) == 1 ? true : false}
                  // bulkActionsLabel="Apply"
                  // bulkActions={[
                  //   { actionTitle: "Delete", actionCallback: handleBulkDelete },
                  // ]}
                  rows={rows}
                  showPagination
                  paginationProps={{
                    ...pagination,
                    currentPage: pagination.currentPage,
                    onPageChange: (pageNo: any) => {
                      getDepartmentList(pageNo);
                    },
                  }}
                  dropdownContainerClass="mb-3"
                  emptyRender={() => (
                    <p className="text-center mb-0">No record found</p>
                  )}
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </section>
    </>
  );
};

export default DepartmentListing;
