import axios from 'axios';

export default {
  setupInterceptors: async () => {
    axios.interceptors.request.use(
      async (config: any) => {
        // const requestConfig = config;
        // requestConfig.headers['Content-Type'] = 'application/json';
        const configuration = config;
        if (configuration.url.indexOf('upload') > -1) {
          configuration.headers['Content-Type'] = 'multipart/form-data'; // eslint-disable-line
        } else {
          configuration.headers['Content-Type'] = 'application/json'; // eslint-disable-line
        }
        const token = localStorage.getItem('access_token');
        
        // if (token) configuration.headers['access-control-allow-origin'] = '*'; // eslint-disable-line
        // if (token) configuration.headers['access-control-allow-headers'] = '*'; // eslint-disable-line
        if (token) configuration.headers['x-access-token'] = `${token}`; // eslint-disable-line
        configuration.headers['timezone'] = Intl.DateTimeFormat().resolvedOptions().timeZone; // eslint-disable-line
        configuration.headers['version'] = '1.0'; // eslint-disable-line
        configuration.headers['platform'] = 'web'; // eslint-disable-line
        return configuration;
      },
      (error: any) => {
        Promise.reject(error);
      }
    );

    axios.interceptors.response.use(
      (response: any) => response,
      async (error: any) => {
        const data = await error.response;
        if (data && (data.status === 401 || data.status === 403)) {
          if (data && data.data && data.data.message === 'Invalid Token') {
            // alert('User does not exist');
            // customFucntions.localStorageClear();
            localStorage.clear()
            if (window.location.pathname !== '/login') {
              localStorage.clear();
              // window.location.replace = '/login';
              window.location.reload();
            }
            return Promise.reject(error.response.data);
          } else {
            // alert("User does not exist");
            // customFucntions.localStorageClear();
            localStorage.clear()
            if (window.location.pathname !== '/login') {
              localStorage.clear();
              // window.location.replace = '/login';
              window.location.reload();
            }
            return Promise.reject(error.response.data);
          }
        }
        return Promise.reject(error.response);
      }
    );
  },
};