import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import Interceptor from "./services/Interceptor";
import {store} from './store/index'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement );

Interceptor.setupInterceptors();
root.render(
  <>
    {/* <React.StrictMode> */}
      <Provider store={store}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Provider>
    {/* </React.StrictMode> */}
  </>
);

reportWebVitals();
