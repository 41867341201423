import { createSlice } from "@reduxjs/toolkit";

const initialState: any = {
  isLoggedIn: true,
  filter: [],
  filterValue: {},
  // employeeList:{},
  // salaryList:{}
};

export const dataSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    changeLogin: (state, action) => {
      state.isLoggedIn = action.payload;
    },
    filter: (state, action) => {
      state.filter = action.payload;
    },
    employeeList: (state, action) => {
      state.employeeList = action.payload;
    },
    salaryList: (state, action) => {
      state.salaryList = action.payload;
    },
    filterValue: (state, action) => {
      state.filterValue = action.payload;
    },
  },
});

export const { changeLogin, filter, employeeList, salaryList, filterValue } = dataSlice.actions;

export default dataSlice.reducer;
