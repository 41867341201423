import React, { useEffect, useState } from "react";
import { AsyncPaginate } from "react-select-async-paginate";
import { Form } from "react-bootstrap";
import AuthApi from "../../helper/authApi";
import customFunctions from "../../helper/customFunctions";

const SelectAsyncPaginate = ({
  isClearable,
  value,
  clearSelected,
  onSelectChange,
  selectPlaceholder,
  key,
  className,
  disabled,
  isSearch,
  endpoint,
  apiname,
  isMulti,
  clearCacheOnID,
  clearSelectedByParent,
  label,
  asterisk,
  errorMessage,
  id,
  adminType,
  roleId,
  currentUserId,
  fromComponent,
  selectedTenderId,
  vendorId,
  departmentId,
}) => {
  const [selectedValue, setSelectedValue] = useState(isMulti ? [] : null);
  const [totalLoadLength, setTotalLoadLength] = useState(0);
  const [apiTotalCount, setApiTotalCount] = useState(0);
  useEffect(() => {
    setSelectedValue(value);
  }, [value]);

  useEffect(() => {
    if (clearSelectedByParent) setSelectedValue({});
  }, [clearSelectedByParent]);

  const loadOptions = async (searchQuery, loadedOptions, { page }) => {
    const params = {
      page: page,
      limit: 10,
      search: `${ searchQuery ?searchQuery.toLowerCase(): ""}`,
    id: apiname == "vendorByTenderUrl" ? selectedTenderId : "",
      vendorId:
  ["tenderUrl", "getTenderUrl"].includes(apiname) && vendorId
    ? vendorId
    : "",
    departmentId: apiname == "tenderUrl" && departmentId ? departmentId : "",
};

const url = customFunctions.generateUrl(endpoint, params);

if (!searchQuery && apiTotalCount !== 0 && apiTotalCount == totalLoadLength)
  return {
    options: [],
    hasMore: false,
  };

const { data } = await AuthApi.getDataFromServer(url);
let newdata = [];
if (apiname === "tenderUrl") {
  if (
    data &&
    data.data &&
    data.data.response &&
    data.data.response.length > 0
  ) {
    setApiTotalCount(data.data.count);
    setTotalLoadLength(
      Number(totalLoadLength) + Number(data.data.response.length)
    );
    let tenderData = [];
    data.data.response.map((dt) => {
      vendorId
        ? tenderData.push({
          name: `${dt.name}`,
          id: dt.id,
          tender_vendor_id: dt?.tender_vendors[0]?.id,
        })
        : tenderData.push({
          name: `${dt.name}`,
          id: dt.id,
        });
    });
    return {
      options: tenderData,
      hasMore:
        Number(totalLoadLength) === Number(data.data.count) ? false : true,
      additional: {
        page: page + 1,
      },
    };
  } else {
    return {
      options: [],
      hasMore: false,
    };
  }
}

if (apiname === "getTenderUrl") {
  if (
    data &&
    data.data &&
    data.data.response &&
    data.data.response.length > 0
  ) {
    setApiTotalCount(data.data.count);
    setTotalLoadLength(
      Number(totalLoadLength) + Number(data.data.response.length)
    );
    let dArray = [];
    data.data.response.map((dt) => {
      dArray.push({
        name: dt?.department.name,
        id: dt.department.id,
      });
    });
    if (vendorId) {
      const ids = dArray.map((o) => o.id);
      const filtered = dArray.filter(
        ({ id }, index) => !ids.includes(id, index + 1)
      );
      newdata = filtered;
    } else {
      newdata = data.data.response;
    }
    return {
      options: newdata,
      hasMore:
        Number(totalLoadLength) === Number(data.data.count) ? false : true,
      additional: {
        page: page + 1,
      },
    };
  } else {
    return {
      options: [],
      hasMore: false,
    };
  }
}

if (apiname === "departmentUrl") {
  if (
    data &&
    data.data &&
    data.data.response &&
    data.data.response.length > 0
  ) {
    setApiTotalCount(data.data.count);
    setTotalLoadLength(
      Number(totalLoadLength) + Number(data.data.response.length)
    );
    let Arr=[];
    data.data.response.map((dt) => {
      Arr.push({
        name : dt.name,
        id : dt.id
      })
    });
    // newdata = Arr;
    return {
      options: Arr,
      hasMore:
        Number(totalLoadLength) === Number(data.data.count) ? false : true,
      additional: {
        page: page + 1,
      },
    };
  } else {
    return {
      options: [],
      hasMore: false,
    };
  }
}

if (apiname === "vendorUrl") {
  if (
    data &&
    data.data &&
    data.data.response &&
    data.data.response.length > 0
  ) {
    setApiTotalCount(data.data.count);
    setTotalLoadLength(
      Number(totalLoadLength) + Number(data.data.response.length)
    );
    let Arr=[];
    data.data.response.map((dt) => {
      Arr.push({
        name : dt.comp_name,
        id : dt.id,
        tender_vendors:dt?.tender_vendors
      })
    });
    // newdata = Arr;
    return {
      options: Arr,
      hasMore:
        Number(totalLoadLength) === Number(data.data.count) ? false : true,
      additional: {
        page: page + 1,
      },
    };
  } else {
    return {
      options: [],
      hasMore: false,
    };
  }
}

if (apiname === "vendorByTenderUrl") {
  if (
    data &&
    data.data &&
    data.data.response &&
    data.data.response.length > 0
  ) {
    setApiTotalCount(data.data.count);
    setTotalLoadLength(
      Number(totalLoadLength) + Number(data.data.response.length)
    );
    let tenderData = [];
    data.data.response[0]?.tender_vendors.map((dt) => {
      tenderData.push({
        name: `${dt.vendor?.comp_name}`,
        id: dt.vendor?.id,
      });
    });
    return {
      options: tenderData,
      hasMore:
        Number(totalLoadLength) === Number(data.data.count) ? false : true,
      additional: {
        page: page + 1,
      },
    };
  } else {
    return {
      options: [],
      hasMore: false,
    };
  }
}

if (apiname === "departmentReportsUrl") {
  if (
    data &&
    data.data &&
    data.data.response &&
    data.data.response.length > 0
  ) {
    setApiTotalCount(data.data.count);
    setTotalLoadLength(
      Number(totalLoadLength) + Number(data.data.response.length)
    );
    let depData = [];
    data.data.response.map((dt) => {
      depData.push({
        name: vendorId ? `${dt.department.name}` : `${dt.name}`,
        id: vendorId ? dt.department.id : dt.id,
      });

      if (vendorId) {
        const ids = depData.map((o) => o.id);
        const filtered = depData.filter(
          ({ id }, index) => !ids.includes(id, index + 1)
        );
        depData = filtered;
      }
    });
    return {
      options: depData,
      hasMore:
        Number(totalLoadLength) === Number(data.data.count) ? false : true,
      additional: {
        page: page + 1,
      },
    };
  } else {
    return {
      options: [],
      hasMore: false,
    };
  }
}
};

const onSelectChangeData = (option) => {
  if (clearSelectedByParent) setSelectedValue(option);
  if (clearSelectedByParent) {
    setSelectedValue({});
  } else {
    setSelectedValue(option);
  }
  if (onSelectChange) {
    onSelectChange(option);
  }
};

return (
  <>
    {label && (
      <Form.Label>
        {" "}
        {label} <span style={{ color: "red" }}>{asterisk}</span>
      </Form.Label>
    )}
    <AsyncPaginate
      key={key}
      isMulti={isMulti}
      value={selectedValue}
      className={className}
      loadOptions={loadOptions}
      getOptionValue={(option) => option.name}
      getOptionLabel={(option) => option.name}
      onChange={onSelectChangeData}
      isDisabled={disabled}
      isSearchable={isSearch}
      placeholder={selectPlaceholder}
      isClearable={isClearable ? isClearable : false}
      cacheUniqs={[clearCacheOnID]}
      additional={{
        page: 1,
      }}
      inputId={id}
    />
    {errorMessage && <p style={{ color: "red" }}> {errorMessage} </p>}
  </>
);
};

export default SelectAsyncPaginate;