import React, { useEffect, useState } from "react";
import { Accordion, Row, Col, Button, Card, Image } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/Loader";
import customFunctions from "../../helper/customFunctions";
import Table from "../../components/Table/simpletable.component";
import Breadcrumb from "../../components/Breadcrumb/breadcrumb.component";
import { Helmet, TextInput } from "../../components/FormInputs";
import swal from "sweetalert";
import Api from "../../helper/api";
import AuthApi from "../../helper/authApi";
import SweetAlert from "react-bootstrap-sweetalert";
import UserRestrictions from "../../components/UserRestrictions/userrestrictions.component";
import { useDispatch, useSelector } from "react-redux";
import { filter, filterValue } from "../../store/redux_slice";

const VendorCompanyList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const selector = useSelector<any, any>((state) => state)

  const title = "Vendor Company";
  const [isShowLoader, setShowLoader] = useState<boolean>(false);
  const [rows, setRows] = useState<any>([]);
  const [filterErr, setFilterErr] = useState<any>("");
  const [searchKeyword, setSearchKeyword] = useState<any>("");
  const [vendorCompanyData, setVendorCompanyData] = useState<any>([]);
  const [statusToggle, setStatusToggle] = useState<boolean>(false);
  // const [currentUserId, setCurrentUserId] = useState<number>(0);
  const [applyToggle, setApplyToggle] = useState<any>(false);
  const [roleId, setRoleId] = useState<number>(0);
  const [statusId, setStatusId] = useState<any>("");
  const [vendorId, setVendorId] = useState<any>(null);
  const [currentUser, updateCurrentUser] = useState<any>(0);
  const [loginDepartment, updateLoginDepartment] = useState<any>(null);

  const initialPagination: any = {
    currentPage: 1,
    itemsPerPage: 10,
    totalItems: 1,
  };

  const [pagination, setPagination] = useState<any>(initialPagination);

  const items: any = [{ to: "/", label: "Dashboard" }, { label: title }];

  const columns = [
    {
      label: "Company Name",
      id: "company_name",
    },
    {
      label: "Contact Person Name",
      id: "name",
    },
    {
      label: "Office Mobile",
      id: "company_mobile",
    },
    {
      label: "Office Email",
      id: "company_email",
    },
    {
      label: "User Email",
      id: "user_email",
    },
    // {
    //   label: "Status",
    //   id: "status",
    //   isDisplay: false,
    // },
    {
      label: "Action",
      id: "action",
    },
  ];

  useEffect(() => {
    getUserRole();
  }, []);

  const getUserRole = async () => {
    const roleId = await customFunctions.getLocalStorage("role_id");
    const detpId = await customFunctions.getLocalStorage("dept_id");
    updateCurrentUser(Number(roleId));
    updateLoginDepartment(detpId);
  };

  useEffect(() => {
    // if (selector?.data_reducer?.filter?.length > 0) {
    //   setVendorCompanyData(selector?.data_reducer?.filter)
    //   setSearchKeyword(selector?.data_reducer?.filterValue?.searchKeyword)
    //   return
    // }
    if (currentUser && [1, 3].includes(Number(currentUser))) {
      getVendorCompanyList(1);
    }
    // if (currentUser && [1].includes(Number(currentUser))) {
    //   getVendorCompanyList(1);
    // }
    // if (currentUser && Number(currentUser) && currentUser == 3) {
    //   getVendorByDepartment(1);
    // }
  }, [currentUser]);

  useEffect(() => {
    setTableData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vendorCompanyData]);

  const getVendorCompanyList = async (pageNo?: any, clearFilter?: boolean) => {
    // if (!currentUserId || (!applyToggle && clearFilter && !searchKeyword))
    //   return;
    const page: any = pageNo || 1;
    setShowLoader(true);
    setApplyToggle(false);
    const endPoint: any = Api.vendorUrl;

    const params: any = {
      page: page,
      limit: pagination.itemsPerPage,
      search: !clearFilter && searchKeyword ? searchKeyword.trim() : "",
      sort_direction: "descending",
    };
    const url: any = customFunctions.generateUrl(endPoint, params);
    const { data, message } = await AuthApi.getDataFromServer(url);
    if (data && data.success === true) {
      setShowLoader(false);
      if (data && data.data) {
        setPagination({
          itemsPerPage: Number(10),
          currentPage: data.data.page,
          totalItems: data.data.count,
        });
        setVendorCompanyData(data.data.response);
        // dispatch(filter(data.data.response))
        // dispatch(filterValue({ searchKeyword: searchKeyword }))
      } else {
        setVendorCompanyData([]);
      }
    } else {
      setShowLoader(false);
      setVendorCompanyData([]);
      swal(message, "", "error");
    }
  };

  const getVendorByDepartment = async (pageNo = 1, isSearchfilter = false) => {
    setShowLoader(true);
    const page = pageNo || pagination?.currentPage;

    const params = {
      // dept_id: loginDepartment,
      search: !isSearchfilter && searchKeyword ? searchKeyword.trim() : "",
      page: page,
      limit: Number(10),
    };
    const endPoint = `${Api.vendorUrl}`;
    const url = customFunctions.generateUrl(endPoint, params);
    const { data, message } = await AuthApi.getDataFromServer(url);
    if (data && data.success == true) {
      setShowLoader(false);
      if (
        data &&
        data.data &&
        data.data.response &&
        data.data.response.length > 0
      ) {
        setVendorCompanyData(data.data.response);
        // dispatch(filter(data.data.response))
        // dispatch(filterValue({ searchKeyword: searchKeyword }))
      } else {
        setShowLoader(false);
        setVendorCompanyData([]);
      }
    } else {
      setShowLoader(false);
      setVendorCompanyData([]);
      swal(message || "Something went wrong", "", "error");
    }
  };

  const setTableData = () => {
    const rowsData: any = [];

    vendorCompanyData.map((dt: any, index: any) => {
      let tNameTitle = dt?.comp_name.length > 20
        ? dt?.comp_name.slice(0, 20) + '...'
        : dt?.comp_name;
      rowsData.push({
        id: dt?.id,
        company_name: <td title={dt?.comp_name}>{tNameTitle}</td>,
        // company_name: dt?.comp_name,
        company_address: dt?.local_address ? dt?.local_address : '-',
        company_mobile: dt?.office_phoneNo,
        user_email: dt?.user?.email,
        company_email: dt?.office_email ? dt?.office_email : '-',
        name: customFunctions.capitalizeFirstLetter(`${dt?.user?.fname}`) + ' ' + customFunctions.capitalizeFirstLetter(`${dt?.user?.lname}`),
        // middle_name: dt?.middle_name,
        // last_name: dt?.last_name,
        designation: dt?.designation,
        mobile_number: dt?.mobile_number,
        pan_card: dt?.pan_no,
        // gst_number: dt?.vendor_tax_values[0]?.GST_No,
        sap_number: dt,
        // pf_number: dt?.vendor_tax_values[0]?.PF_No,
        // esic_number: dt?.vendor_tax_values[0]?.ESI_No,
        email: dt?.email,
        password: dt?.password,
        // status: (
        //   <>
        //     <div className="switch-check">
        //       <div
        //         className="form-check form-switch"
        //         style={{ marginBottom: "0" }}
        //       >
        //         <input
        //           className="form-check-input"
        //           type="checkbox"
        //           role="switch"
        //           id="flexSwitchCheckDefault"
        //           checked={dt?.status == "1" ? true : false}
        //           onClick={() => {
        //             setStatusToggle(true);
        //             setStatusId(dt?.status == "2" ? "1" : "2");
        //             setVendorId(dt?.id);
        //           }}
        //           title={dt?.status == "1" ? "Active" : "Inactive"}
        //         />
        //       </div>
        //     </div>
        //   </>
        // ),
        action: (
          <>
            {[1, 3].includes(Number(currentUser)) && (
              <a
                href="/"
                className="pl-2 ms-1"
                title={"Edit"}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  navigate("/edit-vendor", {
                    state: { data: dt, id: dt.id, pageType: "edit" },
                  });
                }}
              >
                <Image src="images/edit-button.svg" alt="edit" />
              </a>
            )}
            {currentUser && Number(currentUser) == 1 && (
              <Image
                title={"Delete"}
                src="images/delete.png"
                alt="delete"
                className="pl-2 ms-1"
                onClick={(e: any) => {
                  e.preventDefault();
                  e.stopPropagation();
                  swal({
                    title: "Are you sure?",
                    text: "you want to delete this vendor record.",
                    icon: "warning",
                    buttons: ["Cancel", true],
                    dangerMode: true,
                  }).then((willDelete) => {
                    if (willDelete) {
                      deleteDepartment(dt?.id);
                    }
                  });
                }}
              />
            )}
          </>
        ),
      });
      return dt;
    });
    setRows([...rowsData]);
  };

  const handleBulkDelete = async (selectedIds: any) => {
    if (!customFunctions.checkIfEmpty(selectedIds, "A")) {
      const postData = {
        id: selectedIds,
      };
      setShowLoader(true);
      const { data, message } = await AuthApi.deleteDataFromServer(
        `${Api.vendorUrl}/change-vendor-status`,
        postData
      );

      if (data) {
        setShowLoader(false);
        swal("Vendor company deleted successfully!", "", "success", {
          closeOnClickOutside: false,
        }).then(() => {
          // swal.close();
          if (Number(rows.length) === 1) {
            getVendorCompanyList(pagination.currentPage - 1);
          } else {
            getVendorCompanyList(pagination.currentPage);
          }
        });
      } else {
        setShowLoader(false);
        swal(message, "", "error", {
          closeOnClickOutside: false,
        });
      }
    }
  };

  const deleteDepartment = async (id: any) => {
    // if (!vendorId) return;
    const postData = {
      id: [id],
    };
    setShowLoader(true);
    const endpoint: any = `${Api.vendorUrl}/change-vendor-status`;
    const { data, message } = await AuthApi.deleteDataFromServer(
      endpoint,
      postData
    );

    if (data && data.success === true) {
      setStatusToggle(false);
      swal(data.data && typeof data.data == 'string' ? data.data : "Vendor company deleted successfully!", "",
        data.data && typeof data.data == 'string' ? "error" : "success", {
        closeOnClickOutside: false,
      }).then(() => {
        setVendorId(null);
        setStatusId("");
        getVendorCompanyList(1);
      });
    } else {
      setShowLoader(false);
      swal(message, "", "error").then(() => {
        setVendorId(null);
        setStatusId("");
        getVendorCompanyList(1);
      });
    }
  };

  const changeStatus = async () => {
    // const postData: any = {
    //   id: Number(vendorId),
    //   status: statusId,
    // };
    // if (!vendorId) return;
    // setShowLoader(true);
    // const endpoint: any = `${Api.vendorUrl}/change-vendor-status?id=${vendorId}`;
    // const { data, message } = await AuthApi.deleteDataFromServer(endpoint, postData);
    // if (data && data.success === true) {
    //   setStatusToggle(false);
    //   swal("Vendor company status changed successfully!", "", "success", {
    //     closeOnClickOutside: false,
    //   }).then(() => {
    //     setVendorId(null)
    //     setStatusId("");
    //     getVendorCompanyList(1);
    //   });
    // } else {
    //   setShowLoader(false);
    //   swal( message, "", "error").then(() => {
    //     setVendorId(null)
    //     setStatusId("");
    //     getVendorCompanyList(1);
    //   });
    // }
  };

  const filterSearch = (e: any) => {
    e.preventDefault();
    let filterSearchErr: string = "";
    let isValid: boolean = true;
    if (customFunctions.checkIfEmpty(searchKeyword)) {
      filterSearchErr = "Please enter company name";
      setFilterErr(filterSearchErr);
      isValid = false;
    }
    if (isValid) {
      setFilterErr("");
      if (currentUser && [1, 3].includes(Number(currentUser))) {
        getVendorCompanyList(1);
      }
      // if (currentUser && Number(currentUser) && currentUser == 3) {
      //   getVendorByDepartment(1);
      // } else if (currentUser && [1].includes(Number(currentUser))) {
      //   getVendorCompanyList(1);
      // }
    }
  };

  const onClear = (e: any) => {
    e.preventDefault();
    setFilterErr("");
    setSearchKeyword("");
    if (currentUser && [1, 3].includes(Number(currentUser))) {
      getVendorCompanyList(1, true);
    }
    // if (currentUser && [1].includes(Number(currentUser))) {
    //   getVendorCompanyList(1, true);
    // } else if (currentUser && Number(currentUser) == 3) {
    //   getVendorByDepartment(1, true);
    // }
  };

  return (
    <>
      <Helmet title={title} />
      <div className="page-header">
        <div className="title-breadcrumb-section">
          <h2 className="main-content-title tx-24 mg-b-5">{title}</h2>
          <Breadcrumb items={items} />
        </div>
      </div>
      {/* {statusToggle && (
          <div className="sweetalert">
            <SweetAlert
              title={"Are you sure, do you want to change the status ?"}
              warning
              showCancel
              confirmBtnBsStyle="success"
              cancelBtnBsStyle="danger"
              onConfirm={() => {
                setStatusToggle(false);
                changeStatus();
              }}
              onCancel={() => {
                setStatusToggle(false);
              }}
            />
          </div>
        )} */}
      <Loader showLoader={isShowLoader} needFullPage={false} />
      <section className="content-area">
        <Accordion defaultActiveKey="1">
          <Accordion.Item eventKey="1">
            <Accordion.Header>
              <h5 className="card-title mb-0">Filters</h5>
            </Accordion.Header>
            <Accordion.Body>
              {filterErr && (
                <div className="p-3  bg-danger text-white bg-danger-main table-red-bg mb-3">
                  <div className="img-text">
                    <img
                      style={{ cursor: "pointer" }}
                      onClick={(e) => onClear(e)}
                      src="/images/fail.svg"
                      alt="Success"
                    />{" "}
                    {filterErr}
                  </div>
                </div>
              )}
              <Row className="mt-3">
                <Col sm={3} md={3} lg={3}>
                  <TextInput
                    value={searchKeyword}
                    placeholder="Search by company name"
                    onInputChange={(e: any) => {
                      setSearchKeyword(e.target.value);
                    }}
                  />
                </Col>

                <Col sm={3} md={3} lg={3}>
                  <div className="filter-btn pull-right filter-btn-report">
                    <Button
                      id="submit-btn"
                      variant="primary"
                      type="submit"
                      className="ms-4"
                      onClick={(e) => {
                        e.preventDefault();
                        filterSearch(e);
                      }}
                    >
                      Submit
                    </Button>
                    <Button
                      id="clear-btn"
                      variant="secondary"
                      type="button"
                      className="ms-4"
                      onClick={(e) => onClear(e)}
                    >
                      Clear
                    </Button>
                  </div>
                </Col>
              </Row>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <Card className="mt-3">
          <Card.Header>
            <div className="d-flex align-items-center justify-content-end">
              <a
                href="/vendor-dashboard"
                id="view-dashboard-btn-link"
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/vendor-dashboard");
                }}
                className="btn-link"
              >
                View Dashboard
              </a>
              <UserRestrictions permittedUsers={[1, 3]} roleId={currentUser}>
                {/* {currentUser && [1, 3].includes(Number(currentUser)) && */}
                <a
                id="add-vendor-btn-link"
                  href="/add-vendor"
                  className="ms-3 btn-link"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate("/add-vendor", {
                      state: { pageType: "add" },
                    });
                  }}
                >
                  Add Vendor Company
                </a>
                {/* } */}
              </UserRestrictions>
            </div>
          </Card.Header>
          <Card.Body>
            <Table
              columns={columns}
              // showCheckbox={
              //   currentUser && Number(currentUser) == 1 ? true : false
              // }
              // bulkActionsLabel="Apply"
              // bulkActions={
              //   currentUser &&
              //   Number(currentUser) == 1 && [
              //     {
              //       actionTitle: "Delete",
              //       actionCallback: handleBulkDelete,
              //     },
              //   ]
              // }
              rows={rows}
              role={currentUser}
              showPagination
              paginationProps={{
                ...pagination,
                activePage: pagination.currentPage,
                onPageChange: (pageNo: any) => {
                  getVendorCompanyList(pageNo);
                },
              }}
              dropdownContainerClass="mb-3"
              emptyRender={() => (
                <p className="text-center mb-0">No record found</p>
              )}
            />
          </Card.Body>
        </Card>
      </section>
    </>
  );
};

export default VendorCompanyList;
