import React, { useEffect, useRef, useState } from "react";
import swal from "sweetalert";
import SimpleReactValidator from "simple-react-validator";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Card, Col, Row } from "react-bootstrap";
import { TextInput } from "../../components/FormInputs";
import Loader from "../../components/Loader";
import Api from "../../helper/api";
import AuthApi from "../../helper/authApi";
import customFunctions from "../../helper/customFunctions";

const AddDepartment = (props: any) => {
  const validator = useRef(new SimpleReactValidator());
  const navigate = useNavigate();
  const { state } = useLocation();
  const { data, callbackProps } = props;
  const [departmentDetails, setDepartmentDetails] = useState<any>({
    departmentId: null,
    departmentName: "",
    status: "",
  });
  const [title, setTitle] = useState<string>("Add Department");
  const [isShowLoader, setShowLoader] = useState<boolean>(false);
  const [disableButton, setDisableButton] = useState<boolean>(false);
  const [count, forceUpdate] = useState<number>(0);

  useEffect(() => {
    if (data && data?.pageType === "add") return;
    if (data && data?.pageType === "edit") {
      setDepartmentDetails({
        departmentId: data?.data?.id,
        departmentName: data?.data?.name,
        status: data?.data?.status,
      });
      // setDepartmentId(data?.data?.id);
      // setDepartmentName(data?.data?.name);
      setTitle("Edit Department");
    }
  }, [data]);

  // useEffect(() => {
  //   if (!departmentId) return;
  //   getDepartmentList();
  // }, [departmentId]);

  const getDepartmentList = async () => {
    // setShowLoader(true);
    // const { data, message } = await AuthApi.getDataFromServer(
    //   `${Api.departmentUrl}${departmentId}/`
    // );
    // if (data && data.success === true) {
    //   setShowLoader(false);
    //   if (data && data.data) {
    //     let departmentData: any = data.data;
    //     setDepartmentId(departmentData.id)
    //     setDepartmentName(departmentData.name)
    //   }
    // } else {
    //   setShowLoader(false);
    //   swal(message, "", "error");
    // }
  };

  const onSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (disableButton) return;
    // setShowLoader(true);
    if (validator.current.allValid()) {
      AddDepartment();
      validator.current.hideMessages();
    } else {
      setDisableButton(false);
      validator.current.showMessages();
      forceUpdate(count + 1);
    }
  };

  const AddDepartment = async () => {
    setShowLoader(true);
    let id = departmentDetails.departmentId;
    const postData: any = {
      name: departmentDetails.departmentName.trim(),
    };
    if (id) {
      postData.id = id;
      postData.status = departmentDetails.status;
    }
    const payload: any = customFunctions.cleanObject(postData);
    const endPoint: any = id ? `${Api.departmentUrl}` : `${Api.departmentUrl}`;
    const callback: any = id
      ? AuthApi.putDataToServer
      : AuthApi.postDataToServer;
    const { data, message } = await callback(endPoint, payload);
    if (data && data.success === true) {
      setShowLoader(false);
      setDisableButton(false);
      swal(
        id
          ? "Department name updated successfully!"
          : "Department name created successfully!",
        "",
        "success"
      ).then(() => {
        setDepartmentDetails({
          departmentId: null,
          departmentName: "",
          status: "",
        });
        setTitle("Add Department");
        setShowLoader(false);
        callbackProps();
        navigate("/department");
        forceUpdate(count + 1);
      });
    } else {
      setDisableButton(false);
      setShowLoader(false);
      swal(message, "", "error");
    }
  };

  return (
    <>
      <Loader showLoader={isShowLoader} needFullPage={false} />

      <Card className="mt-3">
        <Card.Header>{title}</Card.Header>
        <Card.Body>
          <Row>
            <Col sm={8}>
              <TextInput
                onInputChange={(
                  e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                ) => {
                  const re = /^[a-z A-Z ]*$/;
                  if (!e.target.value || re.test(e.target.value)) {
                    setDepartmentDetails({
                      ...departmentDetails,
                      departmentName: e.target.value,
                    });
                  }
                }}
                label="Department Name"
                asterisk="*"
                id="department_name"
                containerClass="form-group"
                type="text"
                name="departmentName"
                value={departmentDetails.departmentName}
                placeholder="Enter department name"
                errorMessage={validator.current.message(
                  "department name",
                  departmentDetails.departmentName,
                  "required"
                )}
              />
            </Col>
          </Row>
          <Col>
            <Button
              type="submit"
              variant="primary"
              id="submit_btn"
              onClick={(e) => {
                e.preventDefault();
                onSubmit(e);
              }}
              disabled={disableButton}
            >
              Submit
            </Button>
            <Button
              onClick={(e) => {
                e.preventDefault();
                setDepartmentDetails({
                  departmentId: null,
                  departmentName: "",
                  status: "",
                });
                setTitle("Add Department");
                validator.current.hideMessages();
              }}
              id="cancel_btn"
              variant="secondary"
              className="ms-2"
              disabled={disableButton}
            >
              Cancel
            </Button>
          </Col>
        </Card.Body>
      </Card>
    </>
  );
};

export default AddDepartment;
