import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Card, Row, Col, Form, Button } from "react-bootstrap";
import swal from "sweetalert";
import ReactDatePicker from "react-datepicker";
import { Helmet } from "../../components/FormInputs";
import customFunctions from "../../helper/customFunctions";
import { DropdownInput } from "../../components/FormInputs";
import UserRestrictions from "../../components/UserRestrictions/userrestrictions.component";
import AuthApi from "../../helper/authApi";
import Api from "../../helper/api";
import Loader from "../../components/Loader/index";
import Table from "../../components/Table/simpletable.component";
import SelectAsyncPaginate from "../../components/FormInputs/SelectAsyncPaginate";

function AdminDashboardComponent() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [currentUser, updateCurrentUser] = useState<any>(null);

  const initialPagination = {
    currentPage: 1,
    itemsPerPage: 10,
    totalItems: 1,
  };
  const [pagination, setPagination] = useState<any>(initialPagination);

  const [selectedTender, setSelectedTender] = useState<any>(null);
  const [selectedVendor, setSelectedVendor] = useState<any>(null);
  const [selectedDepartment, setSelectedDepartment] = useState<any>("");
  const [monthYear, setMonthYear] = useState<any>(new Date());
  const [rowData, setRowData] = useState<any>([]);
  const [isShowLoader, setShowLoader] = useState<any>(false);
  const [tenderList, setTenderList] = useState<any>([]);
  const [departmentList, setDepartmentList] = useState<any>([]);
  const [loginVendor, setLoginVendor] = useState<any>("");
  const [loginDepartment, setLoginDepartment] = useState<any>("");
  const [totalTenders, setTotalTenders] = useState<any>(0);
  const [totalVendors, setTotalVendors] = useState<any>(0);
  const [totalEmployees, setTotalEmployees] = useState<any>(0);
  const [errMsg, setErrMsg] = useState<any>({
    departmentErr: "",
    tenderErr: "",
    dateErr: "",
  });
  const [tenderDetails, setTenderDetails] = useState<any>([]);
  const userId = customFunctions.getLocalStorage("id");

  const columns = [
    {
      id: "department_name",
      label: "Department Name",
      render: (row: any) => {
        const rowData = row;
        return <span>{rowData.department_name || "-"}</span>;
      },
    },
    {
      id: "tendor_name",
      label: "Tender Name",
      render: (row: any) => {
        const rowData = row;
        return <span>{rowData.tendor_name || "-"}</span>;
      },
    },
    {
      id: "company_name",
      label: "Vendor Name",

      render: (row: any) => {
        const rowData = row;
        const tname = rowData.tendor_name.split("-");
        return (
          <span>
            <a
              href="javascript:void(0)"
              title={rowData?.company_name}
              className=""
              onClick={() => {
                if (!rowData.dept_id) return;
                navigate("/vendor-dashboard", {
                  state: {
                    departmentData: {
                      label: rowData.department_name,
                      value: rowData.dept_id,
                    },
                    tenderData: {
                      label: tname[0],
                      value: rowData.tendor_id,
                    },
                    vendorData: {
                      label: rowData.company_name,
                      value: rowData.vendor,
                      vendor_tender_id: rowData.Tv,
                    },
                    selected_month: rowData.salaryMonth,
                    fromComponent: "dashboard",
                    tenderVendorId: rowData.Tv,
                    start_date: rowData.start_date,
                    end_date: rowData.end_date,
                  },
                });
              }}
            >
              {rowData.company_name || "-"}
            </a>
          </span>
        );
      },
    },
    {
      id: "salaryMonth",
      label: "Month",
    },
    {
      id: "status",
      label: "Salary Status",
      render: (row: any) => {
        const rowData = row;
        return (
          <span
            style={{
              color:
                rowData.status === "paid"
                  ? "green"
                  : rowData.status === "unpaid"
                  ? "red"
                  : "blue",
            }}
          >
            {customFunctions.capitalizeFirstLetter(rowData.status)}
          </span>
        );
      },
    },
  ];

  useEffect(() => {
    const token = customFunctions.getLocalStorage("access_token");
    if (!token) {
      dispatch({ type: "LOGIN", value: true });
      navigate("/login");
    }
  }, []);

  const title = "Dashboard";

  const getUserRole = async () => {
    const roleId = await customFunctions.getLocalStorage("role_id");
    const vendorId = await customFunctions.getLocalStorage("login_vendor");
    const deptId = await customFunctions.getLocalStorage("dept_id");
    updateCurrentUser(Number(roleId));
    setLoginVendor(Number(vendorId));
    setLoginDepartment(Number(deptId));
  };

  useEffect(() => {
    getUserRole();
    getTotalCount();
    // getVenderTotalCount();
    // getEmployeeTotalCount();
    // getDepartment();
  }, []);

  useEffect(() => {
    if (currentUser) {
      if ([1].includes(currentUser)) {
        // getDepartment();
        // getTenderByDepartment();
      } else if ([2, 3].includes(currentUser)) {
        getTenderByDepartment();
      }
      getRowData();
    }
  }, [currentUser]);

  const getTenderByDepartment = async () => {
    setShowLoader(true);
    let params: any = {
      limit: 50,
    };

    let endPoint = `${Api.tenderUrl}/getTenderList`;
    const url = customFunctions.generateUrl(endPoint, params);
    const { data, message } = await AuthApi.getDataFromServer(url);
    if (data && data.success) {
      setShowLoader(false);
      if (data.data && data.data.response && data.data.response.length > 0) {
        const dpOptions: any = [];
        data &&
          data.data.response.map((op: any) => {
            dpOptions.push({ label: op.name, value: op.id });
            return op;
          });
        setTenderList(dpOptions);
      }
    } else {
      setShowLoader(false);
      swal(message || "Something went wrong", "", "error");
    }
  };

  const getDepartment = async () => {
    setShowLoader(true);
    let endPoint = `${Api.departmentUrl}`;
    const { data, message } = await AuthApi.getDataFromServer(endPoint);
    if (data && data.success === true) {
      setShowLoader(false);
      const dpOptions: any = [];
      if (data.data && data.data.response && data.data.response.length > 0) {
        data.data.response.map((op: any) => {
          dpOptions.push({ label: op.name, value: op.id });
          return op;
        });
        setDepartmentList(dpOptions);
      }
    } else {
      setShowLoader(false);
      swal(message || "Something went wrong", "", "error");
    }
  };

  const getTotalCount = async () => {
    setShowLoader(true);
    let endPoint = `${Api.tenderUrl}/dashboard/dashboard-tender-vendor-employee-count`;
    // let endPoint = `${Api.tenderUrl}/getTenderList`;
    const { data, message } = await AuthApi.getDataFromServer(endPoint);
    if (data && data.success === true) {
      setShowLoader(false);
      if (data.data && data.data) {
        setTotalEmployees(data?.data?.employee_count);
        setTotalTenders(data?.data?.tender_count);
        setTotalVendors(data?.data?.vendor_count);
      }
    } else {
      setShowLoader(false);
      swal(message || "Something went wrong", "", "error");
    }
  };

  const getVenderTotalCount = async () => {
    setShowLoader(true);
    // let endPoint = `${Api.tenderUrl}/dashboard/dashboard-tender-vendor-employee-count`;
    let endPoint = `${Api.vendorUrl}`;
    const { data, message } = await AuthApi.getDataFromServer(endPoint);
    if (data && data.success === true) {
      setShowLoader(false);
      if (data.data && data.data) {
        setTotalVendors(data?.data?.count);
      }
    } else {
      setShowLoader(false);
      swal(message || "Something went wrong", "", "error");
    }
  };

  const getEmployeeTotalCount = async () => {
    setShowLoader(true);
    // let endPoint = `${Api.tenderUrl}/dashboard/dashboard-tender-vendor-employee-count`;
    let endPoint = `${Api.employeeUrl}/getAllEmployee`;
    const { data, message } = await AuthApi.getDataFromServer(endPoint);
    if (data && data.success === true) {
      setShowLoader(false);
      if (data.data && data.data) {
        setTotalEmployees(data?.data?.count);
      }
    } else {
      setShowLoader(false);
      swal(message || "Something went wrong", "", "error");
    }
  };

  useEffect(() => {
    if (!selectedDepartment) return;
    getTendersList();
  }, [selectedDepartment]);

  const getTendersList = async () => {
    const { data, message } = await AuthApi.getDataFromServer(
      // `${Api.departmentUrl}?departmentId=${selectedDepartment.value}`
      `${Api.tenderUrl}/getTenderList?departmentId=${selectedDepartment.id}&limit=50`
    );

    if (data && data.success === true) {
      if (data.data && data.data.response && data.data.response.length > 0) {
        const dt: any = data?.data?.response;
        const dpOptions: any = [];
        dt &&
          dt.length > 0 &&
          dt.map((op: any) => {
            dpOptions.push({ label: op.name, value: op.id });
            return op;
          });
        setTenderList(dpOptions);
      } else {
        setTenderList([]);
        return;
      }
    } else {
      swal(message || "Something went wrong", "", "error");
      return;
    }
  };

  useEffect(() => {
    if (!selectedTender) return;
    getTenderDetails();
  }, [selectedTender]);

  const getTenderDetails = async () => {
    const endPoint = `${Api.tenderUrl}/id?id=${selectedTender.value}`;
    const { data, message } = await AuthApi.getDataFromServer(endPoint);
    if (!data) {
      swal(message || "Something went wrong", "", "error");
      return;
    }

    if (data && data?.data) {
      if (data?.data?.response?.length > 0 && data.data.response[0]) {
        setTenderDetails(data.data.response[0]);
      }
    }
  };

  const filterSearch = () => {
    let isValid = true;
    if (!monthYear) {
      setErrMsg({ ...errMsg, dateErr: "Please select month and year" });
      isValid = false;
    }

    if (selectedDepartment && !selectedTender) {
      setErrMsg({ ...errMsg, tenderErr: "Please select tender name" });
      isValid = false;
    }

    // if (!selectedDepartment) {
    //   setErrMsg({ ...errMsg, departmentErr: "Please select department name" });
    //   isValid = false;
    // }

    if (isValid) {
      setErrMsg({});
      getRowData();
    }
  };

  const getRowData = async (pageNo: any = 1, resetFilter: any = "") => {
    setShowLoader(true);
    let currnetSalaryMonth: any = "",
      currnetSalaryYear: any = "",
      month: any = "",
      year: any = "";
    if (monthYear) {
      month = monthYear && new Date(monthYear).getMonth() + Number(1);
      if (month) {
        if (month.toString().length == 1) {
          month = `0${month}`;
        }
      }
      year = monthYear && new Date(monthYear).getFullYear();
    }
    if (resetFilter) {
      currnetSalaryMonth = currnetSalaryMonth = new Date().getMonth() + 1;
      if (currnetSalaryMonth) {
        if (currnetSalaryMonth.toString().length == 1) {
          currnetSalaryMonth = `0${currnetSalaryMonth}`;
        }
      }
      currnetSalaryYear = new Date().getFullYear();
    }
    const endPoint = `${Api.tenderUrl}/dashboard/dashboard-paid-unpaid`;
    let params = {
      page: Number(pageNo),
      limit: Number(10),
      tenderId:
        !resetFilter && selectedTender && selectedTender.value
          ? selectedTender.value
          : "",
      departmentId:
        !resetFilter && selectedDepartment && selectedDepartment.id
          ? selectedDepartment.id
          : loginDepartment
          ? loginDepartment
          : "",
      salary_date:
        !resetFilter && month && year
          ? `${year}-${month}-01`
          : `${currnetSalaryYear}-${currnetSalaryMonth}-01`,
      // roleId: currentUser,
      // user_id: Number(userId),
    };
    const url = customFunctions.generateUrl(endPoint, params);
    const { data, message } = await AuthApi.getDataFromServer(url);

    if (!customFunctions.checkIfEmpty(data, "O") && data && data.success) {
      setShowLoader(false);
      setPagination({
        itemsPerPage: Number(10),
        currentPage: Number(data?.data && data?.data?.page),
        totalItems: Number(data.data && data.data.count),
      });

      if (data && data.data && data.data.docs && data.data.docs.length > 0) {
        let Arr: any = [];
        if (currentUser && [1].includes(Number(currentUser))) {
          // setTotalTenders(data.data.tendersCount || 0);
          // setTotalVendors(data.data.vendorsCount || 0);
        } else if (currentUser && Number(currentUser) == 3) {
          // setTotalTenders(data.data.departmentTenderCount || 0);
          // setTotalVendors(data.data.departmentVendorCount || 0);
        }
        data.data.docs &&
          data.data.docs.length > 0 &&
          data.data.docs.map((item: any) => {
            // item?.tender_vendors && item?.tender_vendors.map((it:any)=>{
            // Arr.push({
            //   dept_id: item?.department?.id,
            //   department_name: item?.department?.name,
            //   tendor_id: item?.id,
            //   tendor_name: item?.name,
            //   vendor: it?.vendor?.id,
            //   company_name: it?.vendor?.comp_name,
            //   start_date: it?.start_date,
            //   end_date: it?.end_date,
            //   Tv: it?.id,
            //   salaryMonth: item?.salaryMonth,
            //   status: it?.salaryStatus,
            // });
            const startYear = customFunctions.getYear(item?.start_date);
            const startMonth = new Date(item?.start_date).getMonth() + Number(1);

            const endYear = customFunctions.getYear(item?.end_date);
            const endMonth = new Date(item?.end_date).getMonth() + Number(1);

            const currentYear = customFunctions.getYear(monthYear);
            const currentMonth = new Date(monthYear).getMonth() + Number(1);
            
            Arr.push({
              dept_id: item?.tender?.department?.id,
              department_name: item?.tender?.department?.name,
              tendor_id: item?.tender?.id,
              tendor_name: item?.tender?.name,
              vendor: item?.vendor?.id,
              company_name: item?.vendor?.comp_name,
              start_date: item?.start_date,
              end_date: item?.end_date,
              Tv: item?.id,
              salaryMonth: item?.salaryMonth,
              // status:item?.salaryStatus,
              status:
                new Date(`${startYear}/${startMonth}`).getTime() <=
                  new Date(`${currentYear}/${currentMonth}`).getTime() &&
                new Date(`${endYear}/${endMonth}`).getTime() >=
                  new Date(`${currentYear}/${currentMonth}`).getTime() ? (
                  item?.salaryStatus
                ) : (
                  <td title="Vendor is not exists for this duration">-</td>
                ),
            });

            // })
            // Arr.push({
            //   dept_id: item?.department?.id,
            //   department_name: item?.department?.name,
            //   tendor_id: item?.id,
            //   tendor_name: item?.name,
            //   vendor: item?.tender_vendors[0]?.vendor?.id,
            //   company_name: item?.tender_vendors[0]?.vendor?.comp_name,
            //   start_date: item?.tender_vendors[0]?.start_date,
            //   end_date: item?.tender_vendors[0]?.end_date,
            //   Tv: item?.tender_vendors[0]?.id,
            //   salaryMonth: item?.salaryMonth,
            //   status: item?.tender_vendors[0]?.salaryStatus,
            // });
          });
        setRowData(Arr);
        // const sum = data.data.response.reduce((accumulator, object) => {
        //   return accumulator + object.Total_employee;
        // }, 0);
        // setTotalEmployees(sum);
      } else {
        // setTotalTenders(0);
        // setTotalVendors(0);
        setRowData([]);
      }
    } else {
      setShowLoader(false);
      setRowData([]);
      // setTotalTenders(0);
      // setTotalVendors(0);
      swal(message, "", "error", {
        closeOnClickOutside: false,
        // allowOutsideClick: false,
      });
    }
  };

  const resetData = () => {
    setErrMsg({});
    setSelectedDepartment("");
    setSelectedTender(null);
    setSelectedVendor(null);
    // setTotalEmployees(0);
    // setTotalTenders(0);
    // setTotalVendors(0);
    setTenderDetails("");
    setMonthYear(new Date());
    getRowData(1, true);
  };

  return (
    <>
      <Helmet title={title} />
      <Loader showLoader={isShowLoader} needFullPage={false} />
      <div className="page-header">
        <div className="title-breadcrumb-section">
          <h2 className="main-content-title tx-24 mg-b-5">
            Welcome To Dashboard
          </h2>
        </div>
      </div>
      <section className="content-area">
        <div className="row">
          <div className="col-sm-4">
            <div className="card dashboard-block1 mb-0">
              <div className="card-body">
                <Row>
                  <Col sm={9}>
                    <div className="d-flex">
                      <div className="flex-grow-1 ms-3">
                        <h4>Total Tenders</h4>
                        <h2>{totalTenders}</h2>
                      </div>
                    </div>
                  </Col>
                  <Col className="right underline">
                    <a
                      href="/tender"
                      onClick={() => {
                        navigate("/tender");
                      }}
                    >
                      View
                    </a>
                  </Col>
                </Row>
              </div>
            </div>
          </div>

          <div className="col-sm-4">
            <div className="card dashboard-block1 mb-0">
              <div className="card-body">
                <Row>
                  <Col sm={9}>
                    <div className="d-flex">
                      <div className="flex-shrink-0"></div>
                      <div className="flex-grow-1 ms-3">
                        <h4>Total Vendors</h4>
                        <h2>{totalVendors}</h2>
                      </div>
                    </div>
                  </Col>
                  <Col className="right underline">
                    <a
                      href="/vendor"
                      onClick={() => {
                        navigate("/vendor");
                      }}
                    >
                      View
                    </a>
                  </Col>
                </Row>
              </div>
            </div>
          </div>

          <div className="col-sm-4">
            <div className="card dashboard-block1 mb-0">
              <div className="card-body">
                <Row>
                  <Col sm={9}>
                    <div className="d-flex">
                      <div className="flex-grow-1 ms-3">
                        <h4>Total Employees</h4>
                        <h2>{totalEmployees}</h2>
                      </div>
                    </div>
                  </Col>
                  <Col className="right underline">
                    <a
                      href="/employee"
                      onClick={() => {
                        navigate("/employee");
                      }}
                    >
                      View
                    </a>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>

        <Card className="mt-3">
          <Card.Body>
            <div className="d-flex align-items-center justify-content-between">
              <h5 className="card-title mb-0 mt-2 ">Filters</h5>
            </div>
            <hr />
            <Row>
              {/* {filterErr && (
              <div className="p-3  bg-danger text-white bg-danger-main table-red-bg mb-3">
                <div className="img-text">
                  <img style={{ cursor: 'pointer' }} onClick={(e) => { e.preventDefault(); resetData() }} src="/images/fail.svg" alt="Success" /> {filterErr}
                </div>
              </div>
            )} */}
              <UserRestrictions permittedUsers={[1]} roleId={currentUser}>
                <Col sm={4}>
                  <SelectAsyncPaginate
                    label="Select Department Name"
                    // asterisk="*"
                    // isMulti
                    value={selectedDepartment}
                    // disabled={[5, 6].includes(roleId) || referralStatus == "1"}
                    clearSelected
                    onSelectChange={(select: any) => {
                      setSelectedTender(null);
                      setSelectedVendor(null);
                      setMonthYear("");
                      setSelectedDepartment(select);
                      if (select === null) {
                        setErrMsg({});
                      }
                    }}
                    selectPlaceholder="Select department"
                    endpoint={`${Api.departmentUrl}`}
                    apiname={"departmentUrl"}
                    isClearable={true}
                    key={undefined}
                    className={undefined}
                    disabled={undefined}
                    isSearch={undefined}
                    isMulti={undefined}
                    clearCacheOnID={undefined}
                    clearSelectedByParent={undefined}
                    asterisk={undefined}
                    errorMessage={undefined}
                    adminType={undefined}
                    roleId={undefined}
                    currentUserId={undefined}
                    vendorId={undefined}
                    departmentId={undefined}
                    fromComponent={undefined}
                    selectedTenderId={undefined}
                    //   errorMessage={validator.current.message(
                    //   "clinical service",
                    //   patientDetails?.injurySection?.selectedClinicalService,
                    //   "required"
                    // )}
                    id="departmentName"
                  />

                  {/* <DropdownInput
                    id="departmentName"
                    label="Select Department Name"
                    placeholder="Select department name"
                    options={departmentList}
                    defaultValue={selectedDepartment}
                    onSelectHandler={(select: any) => {
                      setSelectedTender(null);
                      setSelectedVendor(null);
                      setMonthYear("");
                      setSelectedDepartment(select);
                      if (select === null) {
                        setErrMsg({});
                      }
                    }}
                    isClearable={true}
                    // asterisk="*"
                  // errorMessage={!selectedDepartment && errMsg.departmentErr}
                  /> */}
                </Col>
              </UserRestrictions>
              <UserRestrictions permittedUsers={[1, 2, 3]} roleId={currentUser}>
                <Col sm={4}>
                  {/* <SelectAsyncPaginate
                    label="Select Tender Name"
                    // asterisk="*"
                    // isMulti
                    value={selectedTender}
                    // disabled={[5, 6].includes(roleId) || referralStatus == "1"}
                    clearSelected
                    onSelectChange={(select: any) => {
                      setSelectedVendor(null);
                      setSelectedTender(select);
                      setMonthYear("");
                      if (select === null) {
                        setErrMsg({});
                      }
                    }}
                    selectPlaceholder="Select tender"
                    endpoint={`${Api.tenderUrl}/getTenderList?departmentId=${5}`}
                    // endpoint={`${Api.tenderUrl}/getTenderList?departmentId=${selectedDepartment.id}`}
                    apiname={"tenderByDepartmentUrl"} key={undefined} isClearable={undefined} className={undefined} isSearch={undefined} isMulti={undefined} clearCacheOnID={undefined} clearSelectedByParent={undefined} asterisk={undefined} errorMessage={undefined} adminType={undefined} roleId={undefined} currentUserId={undefined}
                    disabled={
                      !selectedDepartment &&
                      currentUser &&
                      Number(currentUser) !== 3
                    }
                    //   errorMessage={validator.current.message(
                    //   "clinical service",
                    //   patientDetails?.injurySection?.selectedClinicalService,
                    //   "required"  
                    // )}
                    id="tenderName"
                  /> */}

                  <DropdownInput
                    id="tenderName"
                    // asterisk="*"
                    label="Select Tender Name"
                    placeholder="Select tender name"
                    options={tenderList}
                    defaultValue={selectedTender}
                    onSelectHandler={(select: any) => {
                      setSelectedVendor(null);
                      setSelectedTender(select);
                      setMonthYear("");
                      if (select === null) {
                        setErrMsg({});
                      }
                    }}
                    isClearable={true}
                    disabled={
                      !selectedDepartment &&
                      currentUser &&
                      Number(currentUser) !== 3
                    }
                    errorMessage={
                      selectedDepartment && !selectedTender && errMsg.tenderErr
                    }
                  />
                </Col>
              </UserRestrictions>

              <Col sm={3}>
                <Form.Label>
                  Select month and year <span className="text-danger">*</span>
                </Form.Label>
                <ReactDatePicker
                  id="date"
                  className="form-control"
                  placeholderText="Select month and year"
                  selected={monthYear}
                  onChange={(date) => {
                    setMonthYear(date);
                  }}
                  dateFormat="yyyy/MM"
                  showMonthYearPicker
                  // disabled={!selectedTender}
                  minDate={customFunctions.getDatefromMonth(
                    tenderDetails.start_date
                  )}
                  maxDate={
                    tenderDetails.end_date
                      ? customFunctions.getMaxDatefromMonth(
                          tenderDetails.end_date
                        )
                      : null
                  }
                />
                <span className="text-danger">{errMsg.dateErr}</span>
              </Col>
              <Row>
                <Col className="report-btn">
                  <Button
                    style={{ marginTop: "10px" }}
                    onClick={(e) => {
                      e.preventDefault();
                      filterSearch();
                    }}
                  >
                    Submit
                  </Button>
                  <Button
                    variant="secondary"
                    style={{ marginTop: "10px", marginLeft: "10px" }}
                    onClick={(e) => {
                      e.preventDefault();
                      resetData();
                    }}
                  >
                    Clear
                  </Button>
                </Col>
              </Row>
            </Row>
          </Card.Body>
        </Card>
        <Card>
          <Card.Body>
            <>
              <Table
                columns={columns}
                rows={rowData}
                // bulkActions={{}}
                showPagination={true}
                tableClass="table-striped"
                paginationProps={{
                  ...pagination,
                  activePage: pagination.currentPage,
                  onPageChange: (pageNo: any) => {
                    getRowData(Number(pageNo));
                  },
                }}
                emptyRender={() => {
                  return "No record found.";
                }}
              />
            </>
          </Card.Body>
        </Card>
      </section>
    </>
  );
}

export default AdminDashboardComponent;
