import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  Col,
  Row,
  Image,
  Accordion,
  Form,
} from "react-bootstrap";
import { TextInput, Helmet, DropdownInput } from "../../components/FormInputs";
import Breadcrumb from "../../components/Breadcrumb/breadcrumb.component";
import swal from "sweetalert";
import Api from "../../helper/api";
import AuthApi from "../../helper/authApi";
import customFunctions from "../../helper/customFunctions";
import Loader from "../../components/Loader";
import Table from "../../components/Table/simpletable.component";
import SweetAlert from "react-bootstrap-sweetalert";
import UserRestrictions from "../../components/UserRestrictions/userrestrictions.component";
import SelectAsyncPaginate from "../../components/FormInputs/SelectAsyncPaginate";
import { useSelector, useDispatch } from "react-redux";
import { filter, filterValue } from "../../store/redux_slice";
import AttendenceListPopUp from "./attendenceModal";

const EmployeeListing = () => {
  const navigate = useNavigate();
  const selector = useSelector<any, any>((state) => state?.data_reducer);
  const dispatch = useDispatch();
  const title = "Employees";
  const [isShowLoader, setShowLoader] = useState<any>(false);
  const [rows, setRows] = useState<any>([]);
  const [filterErr, setFilterErr] = useState<any>("");
  const [vendorErr, setVendorErr] = useState("");
  const [tenderErr, setTenderErr] = useState("");
  const [searchKeyword, setSearchKeyword] = useState<any>("");
  const [selectedTender, setSelectedTender] = useState<any>("");
  const [selectedVendor, setSelectedVendor] = useState<any>("");
  const [applyToggle, setApplyToggle] = useState<any>(false);
  const [roleId, setRoleId] = useState<number>(0);
  const [employeeData, setEmployeeData] = useState<any>([]);
  const [statusToggle, setStatusToggle] = useState<boolean>(false);
  const [statusId, setStatusId] = useState<any>("");
  const [employeeId, setEmployeeId] = useState<any>(null);
  const [currentUser, updateCurrentUser] = useState<any>(0);
  const [loginVendor, updateLoginVendor] = useState<any>("");
  const [loginVendorName, updateLoginVendorName] = useState<any>("");
  const [loginDepartment, updateLoginDepartment] = useState<any>("");

  const initialPagination = {
    currentPage: 1,
    itemsPerPage: 10,
    totalItems: 1,
  };
  const [pagination, setPagination] = useState<any>(initialPagination);
  const [tenderValues, setTenderValues] = useState<any>([]);
  const [vendorValues, setVendorValues] = useState<any>([]);
  const [employeeAttendence, setEmployeeAttendence] = useState<any>(false);
  const [employeeAttendenceData, setEmployeeAttendenceData] = useState<any>("");

  const columns = [
    {
      label: "Employee ID",
      id: "employee_id",
    },
    {
      label: "Employee Name",
      id: "first_name",
    },
    {
      label: "Mobile No",
      id: "mobile_number",
    },
    {
      label: "Tender Name",
      id: "tender_name",
    },
    {
      label: "Vendor Company Name",
      id: "vendor_company",
      isDisplay: currentUser == 2 ? false : true,
    },
    {
      label: "Created Date",
      id: "date",
    },
    {
      label: "Action",
      id: "action",
    },
  ];

  const getUserRole = async () => {
    const roleId = await customFunctions.getLocalStorage("role_id");
    const loginVendor = await customFunctions.getLocalStorage("login_vendor");
    const vname = await customFunctions.getLocalStorage("login_vendorName");
    const deptId = await customFunctions.getLocalStorage("dept_id");
    updateCurrentUser(Number(roleId));
    updateLoginVendor(Number(loginVendor));
    updateLoginDepartment(Number(deptId));
    updateLoginVendorName(vname);
  };

  useEffect(() => {
    getUserRole();
  }, []);

  const items: any = [
    {
      to: [1, 3].includes(Number(currentUser)) ? "/" : "/vendor-dashboard",
      label: "Dashboard",
    },
    { label: title },
  ];

  useEffect(() => {
    if (!currentUser) return;
    if (currentUser) {
      // getTenderData();
      if ([1].includes(Number(currentUser))) {
        // if (selector?.filterValue?.searchKeyword != '') {
        // setEmployeeData(selector?.data_reducer?.filter)
        // setSearchKeyword(selector?.filterValue?.searchKeyword)
        // setSelectedTender(selector?.filterValue?.selectedTender)
        // setSelectedVendor(selector?.filterValue?.selectedVendor)
        // }
        getEmployeeList(1);
      }
    }
  }, [currentUser]);

  useEffect(() => {
    if (!selectedTender) return;
    if (Number(currentUser) === 1) {
      getVenderData();
    }
    if (Number(currentUser) === 3) {
      getVenderByTenderId();
    }
  }, [selectedTender]);

  useEffect(() => {
    setTableData();
  }, [employeeData]);

  const getTenderData = async () => {
    setShowLoader(true);
    setApplyToggle(false);
    const endPoint: any = `${Api.tenderUrl}/getTenderList`;

    const url: any = customFunctions.generateUrl(endPoint);

    const { data, message } = await AuthApi.getDataFromServer(url);
    if (data && data.success === true) {
      let tender = data.data.response;
      setShowLoader(false);
      if (data && data.data) {
        let Arr: any = [];
        tender &&
          tender.length > 0 &&
          tender.map((item: any) => {
            Arr.push({ label: item?.name, value: item?.id });
          });
        setTenderValues(Arr);
      } else {
        setTenderValues([]);
      }
    } else {
      setShowLoader(false);
      setTenderValues([]);
      swal(message, "", "error");
    }
  };

  const getVenderByTenderId = async () => {
    setShowLoader(true);
    setApplyToggle(false);
    const endPoint: any = `${Api.tenderUrl}/id?id=${selectedTender?.id}&limit=50`;

    const url: any = customFunctions.generateUrl(endPoint);

    const { data, message } = await AuthApi.getDataFromServer(url);
    if (data && data.success === true) {
      let vender = data.data.response[0]?.tender_vendors;
      setShowLoader(false);
      if (data && data.data) {
        let Arr: any = [];
        vender &&
          vender.length > 0 &&
          vender.map((item: any) => {
            Arr.push({
              label: item?.vendor?.comp_name,
              value: item?.vendor?.id,
            });
          });
        setVendorValues(Arr);
      } else {
        setVendorValues([]);
      }
    } else {
      setShowLoader(false);
      setVendorValues([]);
      swal(message, "", "error");
    }
  };

  const getVenderData = async () => {
    // setShowLoader(true);
    setApplyToggle(false);
    const endPoint: any = `${Api.tenderUrl}/id?id=${selectedTender?.id}&limit=50`;

    const url: any = customFunctions.generateUrl(endPoint);

    const { data, message } = await AuthApi.getDataFromServer(url);
    if (data && data.success === true) {
      let vender = data.data.response[0]?.tender_vendors;
      setShowLoader(false);
      if (data && data.data) {
        let Arr: any = [];
        vender &&
          vender.length > 0 &&
          vender.map((item: any) => {
            Arr.push({
              label: item?.vendor?.comp_name,
              value: item?.vendor?.id,
            });
          });
        setVendorValues(Arr);
      } else {
        setVendorValues([]);
      }
    } else {
      setShowLoader(false);
      setVendorValues([]);
      swal(message, "", "error");
    }
  };

  const getEmployeeList = async (pageNo?: any, clearFilter?: boolean) => {
    // if (!applyToggle && clearFilter && !searchKeyword) return;
    const page: any = pageNo || 1;
    setShowLoader(true);
    setApplyToggle(false);
    const endPoint: any = `${Api.employeeUrl}/getAllEmployee`;
    const params: any = {
      page: page,
      limit: pagination.itemsPerPage,
      search: !clearFilter && searchKeyword ? searchKeyword.trim() : "",
      vendor_id:
        !clearFilter && currentUser == 2
          ? loginVendor
          : !clearFilter && selectedVendor
            ? selectedVendor?.value
            : "",
      tender_id: !clearFilter && selectedTender ? selectedTender?.id : "",
    };

    const url: any = customFunctions.generateUrl(endPoint, params);

    const { data, message } = await AuthApi.getDataFromServer(url);
    if (data && data.success === true) {
      setShowLoader(false);
      if (data && data.data) {
        setPagination({
          itemsPerPage: Number(10),
          currentPage: data.data.page,
          totalItems: data.data.count,
        });

        setEmployeeData(data.data.response);
        // dispatch(filter(data.data.response));
        // dispatch(filterValue({
        //   searchKeyword: searchKeyword,
        //   selectedTender: selectedTender,
        //   selectedVendor: selectedVendor,
        //   page: data.data.page,
        //   limit: 10
        // }))
      } else {
        setEmployeeData([]);
      }
    } else {
      setShowLoader(false);
      setEmployeeData([]);
      swal(message, "", "error");
    }
  };

  const handleBulkDelete = async (selectedIds: any) => {
    if (!customFunctions.checkIfEmpty(selectedIds, "A")) {
      const postData = {
        id: selectedIds,
      };
      setShowLoader(true);
      const { data, message } = await AuthApi.deleteDataFromServer(
        `${Api.employeeUrl}/change-employee-status`,
        postData
      );
      if (data) {
        setShowLoader(false);
        swal("Employee deleted sucessfully", "", "success", {
          closeOnClickOutside: false,
        }).then(() => {
          // swal.close();
          if (Number(rows.length) === 1) {
            getEmployeeList(pagination.currentPage - 1);
          } else {
            getEmployeeList(pagination.currentPage);
          }
        });
      } else {
        setShowLoader(false);
        swal(message, "", "error", {
          closeOnClickOutside: false,
        });
      }
    }
  };

  const deleteEmployee = async (id: any) => {
    setShowLoader(true);
    const postData = {
      id: [id],
    };
    const endpoint: any = `${Api.employeeUrl}/change-employee-status`;
    const { data, message } = await AuthApi.deleteDataFromServer(
      endpoint,
      postData
    );
    if (data && data.success === true) {
      setStatusToggle(false);
      swal("Employee deleted successfully!", "", "success", {
        closeOnClickOutside: false,
      }).then(() => {
        setEmployeeId(null);
        setStatusId("");
        getEmployeeList(1);
      });
    } else {
      setShowLoader(false);
      swal(message, "", "error").then(() => {
        setEmployeeId(null);
        setStatusId("");
        getEmployeeList(1);
      });
    }
  };

  const setTableData = () => {
    const rowsData: any = [];
    employeeData.map((dt: any) => {
      let tNameTitle =
        dt?.tv_employees[0]?.tender_vendor?.tender?.name?.length > 30
          ? dt?.tv_employees[0]?.tender_vendor?.tender?.name.slice(0, 30) +
          "..."
          : dt?.tv_employees[0]?.tender_vendor?.tender?.name;

      let vNameTitle =
        dt?.vendor?.comp_name.length > 30
          ? dt?.vendor?.comp_name.slice(0, 30) + "..."
          : dt?.vendor?.comp_name;

      rowsData.push({
        id: dt?.id,
        tender_name: (
          <td title={dt?.tv_employees[0]?.tender_vendor?.tender?.name}>
            {tNameTitle}
          </td>
        ),
        mobile_number: dt?.phone,
        first_name:
          dt && dt?.fname
            ? customFunctions.capitalizeFirstLetter(dt?.fname) +
            " " +
            customFunctions.capitalizeFirstLetter(dt?.lname)
            : null,
        middle_name: dt?.middle_name,
        last_name: dt?.lname,
        vendor_company: (
          <td title={dt?.vendor?.comp_name}>
            {vNameTitle}
          </td>
        ),
        adhar_card: dt?.adhar_card,
        employee_id: dt?.epm_id,
        pf_number: dt?.pf_number,
        esic_number: dt?.esic_number,
        email: dt?.email,
        date: dt?.createdAt ? customFunctions.getDMYDate(dt?.createdAt, "dmy") : "-",
        action: (
          <>
          {/* <a
              href="/"
              className="pl-2 ms-1"
              title={"Edit"}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setEmployeeAttendence(true);
                setEmployeeAttendenceData(dt);
              }}
            >
              <Image src="images/empty-task.svg" alt="edit" />
            </a> */}
            <a
              href="/"
              className="pl-2 ms-1"
              title={"Edit"}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                navigate("/edit-employee", {
                  state: {
                    id: dt.id,
                    tender: tenderValues,
                    vender: vendorValues,
                    pageType: "edit",
                  },
                });
              }}
            >
              <Image src="images/edit-button.svg" alt="edit" />
            </a>
            <Image
              src="images/delete.png"
              alt="delete"
              title="Delete"
              className="pl-2 ms-1"
              onClick={(e: any) => {
                e.preventDefault();
                e.stopPropagation();
                setEmployeeId(dt?.id);
                swal({
                  title: "Are you sure?",
                  text: "you want to delete this employee record.",
                  icon: "warning",
                  buttons: ["Cancel", true],
                  dangerMode: true,
                }).then((willDelete) => {
                  if (willDelete) {
                    deleteEmployee(dt?.id);
                  }
                });
              }}
            />
          </>
        ),
      });
      return dt;
    });
    setRows([...rowsData]);
  };

  // const changeStatus = async () => {
  //   const postData: any = {
  //     id: Number(employeeId),
  //     status: statusId,
  //   };
  //   if (!employeeId) return;
  //   setShowLoader(true);
  //   const endpoint: any = `${Api.employeeUrl}/change-employee-status?id=${employeeId}`;
  //   const { data, message } = await AuthApi.deleteDataFromServer(
  //     endpoint,
  //     postData
  //   );
  //   if (data && data.success === true) {
  //     setStatusToggle(false);
  //     swal("Employee status changed successfully!", "", "success", {
  //       closeOnClickOutside: false,
  //     }).then(() => {
  //       setEmployeeId(null);
  //       setStatusId("");
  //       getEmployeeList(1);
  //     });
  //   } else {
  //     setShowLoader(false);
  //     swal(message, "", "error").then(() => {
  //       setEmployeeId(null);
  //       setStatusId("");
  //       getEmployeeList(1);
  //     });
  //   }
  // };

  // const filterSearch = (e: any) => {
  //   e.preventDefault();
  //   let filterSearchErr: string = "";
  //   let isValid: boolean = true;
  //   if (customFunctions.checkIfEmpty(searchKeyword)) {
  //     filterSearchErr =
  //       "Please select tender or vendor name or search employee";
  //     setFilterErr(filterSearchErr);
  //     isValid = false;
  //   }
  //   if (isValid) {
  //     setFilterErr("");
  //     getEmployeeList(1);
  //   }
  // };

  const filterSearch = (e: any) => {
    e.preventDefault();
    let isValid = true,
      vendorErr = "",
      tenderErr = "";
    if (
      searchKeyword &&
      selectedTender &&
      !selectedVendor &&
      currentUser &&
      [1, 3].includes(Number(currentUser))
    ) {
      tenderErr = "Please select vendor";
      setFilterErr("Please select vendor");
      isValid = false;
    }

    if (
      !searchKeyword &&
      !selectedVendor &&
      currentUser &&
      [1, 3].includes(Number(currentUser))
    ) {
      tenderErr = "Please select vendor";
      setFilterErr("Please select vendor");
      isValid = false;
    }

    if (
      !searchKeyword &&
      !selectedVendor &&
      !selectedTender &&
      currentUser &&
      [1, 2, 3].includes(Number(currentUser))
    ) {
      vendorErr = "Please select tender";
      setFilterErr("Please select tender");
      isValid = false;
    }

    if (isValid) {
      setVendorErr("");
      setTenderErr("");
      setFilterErr("");
      getEmployeeList(1);
    }
  };

  const onClear = (e: any) => {
    e.preventDefault();
    setSearchKeyword("");
    setSelectedTender("");
    setSelectedVendor("");
    setFilterErr("");
    setVendorErr("");
    setTenderErr("");
    // dispatch(filterValue({}))
    if (Number(currentUser) == 1) {
      getEmployeeList(1, true);
      // if (currentUser && [1].includes(Number(currentUser))) {
    } else {
      setEmployeeData([]);
    }
  };

  return (
    <>
      <Helmet title={title} />
      <div className="page-header">
        <div className="title-breadcrumb-section">
          <h2 className="main-content-title tx-24 mg-b-5">{title}</h2>
          <Breadcrumb items={items} />
        </div>
      </div>
      {/* {statusToggle && (
          <div className="sweetalert">
            <SweetAlert
              title={"Are you sure, do you want to change the status ?"}
              warning
              showCancel
              confirmBtnBsStyle="success"
              cancelBtnBsStyle="danger"
              // reverseButtons={true}
              onConfirm={() => {
                setStatusToggle(false);
                changeStatus();
              }}
              onCancel={() => {
                setStatusToggle(false);
              }}
            />
          </div>
        )} */}
      <Loader showLoader={isShowLoader} needFullPage={false} />
      <section className="content-area">
        <Accordion defaultActiveKey="1">
          <Accordion.Item eventKey="1">
            <Accordion.Header>
              <h5 className="card-title mb-0">Filters</h5>
            </Accordion.Header>
            <Accordion.Body>
              {/* <Form
                  onSubmit={(e) => {
                    filterSearch(e);
                  }}
                > */}

              {filterErr && (
                <div className="p-3  bg-danger text-white bg-danger-main table-red-bg mb-3">
                  <div className="img-text">
                    <img
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        onClear(e);
                      }}
                      src="/images/fail.svg"
                      alt="Success"
                    />{" "}
                    {filterErr}
                  </div>
                </div>
              )}
              <Row className="mt-3">
                <UserRestrictions permittedUsers={[1]} roleId={currentUser}>
                  <Col sm={3}>
                    <TextInput
                      onInputChange={(e: any) => {
                        setSearchKeyword(e.target.value);
                      }}
                      type="text"
                      value={searchKeyword}
                      placeholder="Search by employee"
                    />
                  </Col>
                </UserRestrictions>
                <Col sm={3}>
                  {/* <DropdownInput
                      // asterisk={[2, 3].includes(currentUser) ? "*" : ""}
                      placeholder="Select tender"
                      options={tenderValues}
                      isClearable={true}
                      defaultValue={selectedTender}
                      onSelectHandler={(e: any) => {
                        if (e == null) {
                          setSelectedVendor('');
                          setVendorValues([]);
                        }
                        setSelectedTender(e);
                        setSelectedVendor('');
                        setSearchKeyword('');
                      }}
                    // errorMessage={tenderErr}
                    /> */}

                  <SelectAsyncPaginate
                    // label="Select tender"
                    // asterisk="*"
                    // isMulti
                    value={selectedTender}
                    // disabled={[5, 6].includes(roleId) || referralStatus == "1"}
                    clearSelected
                    selectedTenderId={""}
                    onSelectChange={(select: any) => {
                      setSelectedVendor(null);
                      if (select == null) {
                        setVendorValues([]);
                      }
                      setSelectedTender(select);
                    }}
                    selectPlaceholder="Select tender"
                    endpoint={`${Api.tenderUrl}/getTenderList`}
                    apiname={"tenderUrl"}
                    key={undefined}
                    isClearable={undefined}
                    className={undefined}
                    disabled={undefined}
                    isSearch={undefined}
                    clearCacheOnID={undefined}
                    clearSelectedByParent={undefined}
                    asterisk={undefined}
                    errorMessage={undefined}
                    id={undefined}
                    adminType={undefined}
                    roleId={undefined}
                    currentUserId={undefined} // errorMessage={validator.current.message(
                    label={undefined} // "clinical service",
                    isMulti={undefined} // patientDetails?.injurySection?.selectedClinicalService,
                    vendorId={undefined}
                    departmentId={undefined} // "required"
                    fromComponent={undefined} // )}
                  // id="clinicalservice"
                  />
                </Col>
                <UserRestrictions permittedUsers={[1, 3]} roleId={currentUser}>
                  <Col sm={3}>
                    <DropdownInput
                      placeholder="Select vendor/company"
                      options={vendorValues}
                      defaultValue={selectedVendor}
                      onSelectHandler={(e: any) => {
                        setSelectedVendor(e);
                      }}
                      disabled={!selectedTender}
                    // asterisk={[2, 3].includes(currentUser) ? "*" : ""}
                    // errorMessage={vendorErr}
                    />

                    {/* <SelectAsyncPaginate
                        // label="Select tender"
                        // asterisk="*"
                        // isMulti
                        value={selectedVendor}
                        // disabled={[5, 6].includes(roleId) || referralStatus == "1"}
                        clearSelected
                        onSelectChange={(select: any) => {
                          setSelectedVendor(select);
                          setSearchKeyword("");
                        }}
                        selectedTenderId = {selectedTender.id}
                        selectPlaceholder="Select vendor/company"
                        endpoint={`${Api.tenderUrl}/id`}
                        disabled={!selectedTender}
                        apiname={"vendorByTenderUrl"}
                        key={undefined}
                        isClearable={undefined}
                        className={undefined}
                        isSearch={undefined}
                        clearCacheOnID={undefined}
                        clearSelectedByParent={undefined}
                        asterisk={undefined}
                        errorMessage={undefined}
                        id={undefined}
                        adminType={undefined}
                        roleId={undefined}
                        currentUserId={undefined} // errorMessage={validator.current.message(
                        label={undefined} // "clinical service",
                        isMulti={undefined} // patientDetails?.injurySection?.selectedClinicalService,
                        // "required"
                        // )}
                        // id="clinicalservice"
                      /> */}
                  </Col>
                </UserRestrictions>
                <UserRestrictions permittedUsers={[2, 3]} roleId={currentUser}>
                  <Col sm={3}>
                    <TextInput
                      value={searchKeyword}
                      placeholder="Search by employee name"
                      disabled={
                        currentUser && Number(currentUser) == 2
                          ? !selectedTender
                          : !selectedVendor
                      }
                      onInputChange={(e: any) => {
                        setSearchKeyword(e.target.value);
                      }}
                    />
                  </Col>
                </UserRestrictions>

                <Col sm={3}>
                  <div className="filter-btn pull-right filter-btn-report">
                    <Button
                      id="submit-btn"
                      variant="primary"
                      type="submit"
                      className="ms-4"
                      onClick={(e: any) => {
                        setApplyToggle(true);
                        filterSearch(e);
                      }}
                    >
                      Submit
                    </Button>
                    <Button
                      id="clear-btn"
                      variant="secondary"
                      type="button"
                      className="ms-4"
                      onClick={(e) => {
                        e.preventDefault();
                        onClear(e);
                      }}
                    >
                      Clear
                    </Button>
                  </div>
                </Col>
              </Row>
              {/* </Form> */}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <Card className="mt-3">
          <Card.Header>
            <div className="d-flex align-items-center justify-content-end">
              <a
                id="add-btn-link"
                href="/add-employee"
                className="underline"
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/add-employee", {
                    state: {
                      tender: tenderValues,
                      vender: vendorValues,
                      pageType: "add",
                    },
                  });
                }}
              >
                Add Employee
              </a>
            </div>
          </Card.Header>
          <Card.Body>
            <Table
              columns={columns}
              rows={rows}
              showCheckbox={true}
              bulkActionsLabel="Apply"
              bulkActions={[
                { actionTitle: "Delete", actionCallback: handleBulkDelete },
              ]}
              showPagination
              role={roleId}
              paginationProps={{
                ...pagination,
                activePage: pagination.currentPage,
                onPageChange: (pageNo: any) => {
                  getEmployeeList(pageNo);
                },
              }}
              dropdownContainerClass="mb-3"
              emptyRender={() => (
                <p className="text-center mb-0">No record found</p>
              )}
            />
          </Card.Body>
        </Card>
      </section>
      {/* {employeeAttendence && (
        <AttendenceListPopUp
          toggle={employeeAttendence}
          data={employeeAttendenceData}
          popupClose={() => setEmployeeAttendence(false)}
        />
      )} */}
    </>
  );
};

export default EmployeeListing;
