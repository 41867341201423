import React, { useEffect, useState } from 'react';
import { Modal, Row, Col, Button, Form } from 'react-bootstrap';
import { DropdownInput } from '../../components/FormInputs';
import customFunctions from '../../helper/customFunctions';
import ReactDatePicker from 'react-datepicker';
import AuthApi from '../../helper/authApi';
import Api from '../../helper/api';
import Loader from '../../components/Loader';
import swal from "sweetalert";

const VendorDashboardPopUp = (props:any) => {

  useEffect(() => {
    const month = new Date(props.start_date).getMonth();
  }, [props]);

  const [firstMonth, setFirstMonth] = useState<any>(null);
  const [secondMonth, setSecondMonth] = useState<any>(null);

  const [firstMonthErr, setFirstMonthErr] = useState('');
  const [secondMonthErr, setSecondMonthErr] = useState('');
  const [isShowLoader, setShowLoader] = useState<any>(false);

  const validate = (e:any) => {
    e.preventDefault();
    let firstMonthErr = '', secondMonthErr = '', isValid = true;

    if (!firstMonth && !secondMonth) {
      firstMonthErr = 'Please select first month';
      setFirstMonthErr(firstMonthErr);
      isValid = false
    }

    if (firstMonth &&
      customFunctions.checkIfEmpty(secondMonth)) {
      secondMonthErr = 'Please select second month';
      setSecondMonthErr(secondMonthErr);
      isValid = false
    }

    setFirstMonthErr(firstMonthErr);
    setSecondMonthErr(secondMonthErr);

    if (isValid) {
      setFirstMonthErr('');
      setSecondMonthErr('');
      generateReport();
    }
  }


  const generateReport = async () => {
    setShowLoader(true);
    const params = {
      tender_id: props.tenderData.value,
      vendor_id: props && props.loginVendor ? props.loginVendor : props.vendorData.value,
      start_date:customFunctions.getDate(firstMonth),
      end_date: customFunctions.getDate(secondMonth),
    }
    const endPint = Api.generateCertificateUrl;
    const url = customFunctions.generateUrl(endPint, params);
    const { data, message } = await AuthApi.getDataFromServer(url);
    if (data && data.success == true) {
      setShowLoader(false);
      window.open(`${data.data}`, '_blank');
      props.popupClose();
    } else {
      setShowLoader(false);
      swal(message || 'Something went wrong', '', 'error');
    }
  }

  return (
    <>
      <div>
        <Loader showLoader={isShowLoader} needFullPage={false} />
        <Modal
          className={'filter-popup'}
          size="lg"
          centered
          onHide={props.popupClose}
          show={props.gererateMonth}
          keyboard={false}
          backdrop="static"
        >
          <div className="add-task-popup schedule-filter-popup">
            <Modal.Header className="col-12">
              <span style={{ fontWeight: 'bold' }}>
                Select the months you want to generate!
              </span>
              <button onClick={() => props.popupClose()} type="button" className="btn-close"></button>
            </Modal.Header>
            <Modal.Body>
              <>
                <Row>
                  <Col sm={6}>
                    <DropdownInput
                      label="Select Tender Name"
                      placeholder="Select tender name"
                      // options={tenderListArr}
                      defaultValue={props.tenderData}
                      // onSelectHandler={(select) => {
                      //   setSelectedVendor(null);
                      //   setSelectedTender(select);
                      // }}
                      disabled={true}
                    // errorMessage={tenderErr}
                    />
                  </Col>
                  {/* {
                    props && !props.loginVendor && ( */}
                      <Col sm={6}>
                        <DropdownInput
                          label="Select Vendor Name"
                          placeholder="Select vendor name"
                          // options={tenderListArr}
                          defaultValue={props.vendorData}
                          // onSelectHandler={(select) => {
                          //   setSelectedVendor(null);
                          //   setSelectedTender(select);
                          // }}
                          disabled={true}
                        // errorMessage={tenderErr}
                        />
                      </Col>
                    {/* )
                  } */}
                </Row>
                <Row>
                  <Col sm={6}>
                    <Form.Label>
                      Select month and year<span className='text-danger'>*</span>
                    </Form.Label>
                    <ReactDatePicker
                      className='form-control'
                      placeholderText="Select month and year"
                      selected={firstMonth}
                      onChange={(date:any) => { setSecondMonth(null); setFirstMonth(date) }}
                      dateFormat="yyyy-MM"
                      showMonthYearPicker
                      // minDate={customFunctions.getDatefromMonth(
                      //   props?.selectedTenderDetails?.start_date
                      // )}
                      // maxDate={customFunctions.getMaxDatefromMonth(props?.selectedTenderDetails?.end_date)}
                      minDate={customFunctions.getDatefromMonth(
                        props?.start_date
                      )}
                      maxDate={customFunctions.getMaxDatefromMonth(props?.end_date)}
                      // maxDate={props?.selectedTenderDetails?.end_date ? customFunctions.getMaxDatefromMonth(props?.selectedTenderDetails?.end_date) : null}
                    // minDate={new Date(props.start_date).getMonth()}
                    />
                    <span style={{ color: 'red' }}> {firstMonthErr} </span>

                  </Col>
                  <Col sm={6}>
                    <Form.Label>
                      Select month and year<span className='text-danger'>*</span>
                    </Form.Label>
                    <ReactDatePicker
                      className='form-control'
                      placeholderText="Select month and year"
                      selected={secondMonth}
                      onChange={(date:any) => { setSecondMonth(date) }}
                      dateFormat="yyyy-MM"
                      showMonthYearPicker
                      disabled={!firstMonth}
                      minDate={customFunctions.getDatefromMonth(firstMonth)}
                      // maxDate={customFunctions.getMaxDatefromMonth(props?.selectedTenderDetails?.end_date)}
                      maxDate={customFunctions.getMaxDatefromMonth(props?.end_date)}

                    />
                    <span style={{ color: 'red' }}> {secondMonthErr} </span>

                  </Col>
                </Row>
                <Row>
                  <Col style={{ textAlign: 'center', marginTop: '20px' }}>
                    <Button
                      onClick={(e) => {
                        validate(e);
                      }}
                    >
                      Submit
                    </Button>
                  </Col>
                </Row>
              </>
            </Modal.Body>
          </div>
        </Modal>
      </div >
    </>
  )
}

export default VendorDashboardPopUp;