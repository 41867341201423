import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import SimpleReactValidator from "simple-react-validator";
import swal from "sweetalert";
import { Button } from "react-bootstrap";
import CustomFunctions from "../../../helper/customFunctions";
import AuthApi from "../../../helper/authApi";
import Api from "../../../helper/api";
import { Helmet } from "../../../components/FormInputs";
import { changeLogin } from "../../../store/redux_slice";

function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const validator = useRef(new SimpleReactValidator());
  const [count, forceUpdate] = useState<number>(0);
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [disableButton, setDisableButton] = useState<boolean>(false);
  const [switchItem, setSwitchItem] = useState<boolean>(false);

  // While use in project comment this code and use above Useeffect
  useEffect(() => {
    const token = CustomFunctions.getLocalStorage("access_token");
    if (token) {
      // dispatch({ type: "LOGIN", value: true });
      dispatch(changeLogin(true));
      navigate("/");
    } else {
      // dispatch({ type: "LOGIN", value: false });
      dispatch(changeLogin(false));
      navigate("/login");
    }
  }, [navigate, dispatch]);

  const loginUser = async () => {
    const postData: any = {
      email: username.trim(),
      password,
    };
    const { data, message } = await AuthApi.postDataToServer(
      Api.loginUrl,
      postData
    );
    if (data && data.data && data.data.hardUser) {
      const dataObj = data.data.hardUser;
      CustomFunctions.setLocalStorage("loginData", JSON.stringify(data.data));
      CustomFunctions.setLocalStorage("role_id", dataObj.roleId);
      CustomFunctions.setLocalStorage("access_token", dataObj.token);
      CustomFunctions.setLocalStorage("email", dataObj.email);
      CustomFunctions.setLocalStorage("id", dataObj.id);
      CustomFunctions.setLocalStorage(
        "userName",
        `${
          dataObj.fname
            ? CustomFunctions.capitalizeFirstLetter(dataObj.fname)
            : ""
        } ${
          dataObj.lname
            ? CustomFunctions.capitalizeFirstLetter(dataObj.lname)
            : ""
        }`
      );
      if (dataObj.vendorId)
        CustomFunctions.setLocalStorage('login_vendor', dataObj.vendorId);
      CustomFunctions.setLocalStorage(
        'login_vendorName',
        dataObj.comp_name
      );
      CustomFunctions.setLocalStorage(
        'name',
        `${CustomFunctions.capitalizeFirstLetter(
          dataObj.fname
        )} ${CustomFunctions.capitalizeFirstLetter(dataObj.lname)}`
      );

      if (dataObj.departmentId)
        CustomFunctions.setLocalStorage('dept_id', dataObj.departmentId);
        CustomFunctions.setLocalStorage('dep_name', dataObj.dep_name);

      setDisableButton(false);
      const timer = setInterval(async () => {
        const token = CustomFunctions.getLocalStorage("access_token");
        if (token) {
          clearInterval(timer);
          if (Number(dataObj.roleId) == 2) {
            window.location.href = '/vendor-dashboard';
          } else {
            window.location.href = '/';
          }
        }
      }, 100);
    } else {
      setDisableButton(false);
      swal(
        !CustomFunctions.checkIfEmpty(message, "O")
          ? typeof message === "string"
            ? message
            : message[0]
          : "Something went wrong",
        "",
        "error"
      );
    }
  };

  const validateForm = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (disableButton) return;

    setDisableButton(true);
    if (validator.current.allValid()) {
      loginUser();
    } else {
      validator.current.showMessages();
      forceUpdate(count + 1);
      setDisableButton(false);
    }
  };

  const handlePasswordView = (event: React.MouseEvent<HTMLImageElement>) => {
    event.preventDefault();
    setSwitchItem(!switchItem);
  };

  return (
    <>
      <Helmet title={"Login"} />
      <div className="login-page d-flex align-items-center justify-content-center flex-column">
        <div className="logo mb-0 text-center">
          <img
            // src="/images/pmclogo.png"
            src="/images/pmclogo.png"
            className="header-brand-img desktop-logo svg"
            alt="img"
          />
          <div className="title mt-3">
            <h5>कंत्राटी निविदा माहिती प्रणाली</h5>
          </div>
        </div>
        <form className="login-form" autoComplete="off">
          <div className="title text-center">
            <h1>Welcome!</h1>
          </div>
          <div className="form-group">
            <img src="/images/user.svg" alt="Username" />
            <input
              className="form-control form-control-lg"
              type="email"
              name="email"
              id="email_address"
              value={username || ""}
              onChange={(
                e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
              ) => setUsername(e.target.value)}
              placeholder="Enter email address"
              maxLength={150}
            />
            {validator.current.message(
              "Email address",
              username,
              "required|email",
              {
                className: "text-danger",
              }
            )}
          </div>

          <div className="form-group m-0">
            <img src="/images/password.svg" alt="Username" />
            <input
              className="form-control form-control-lg"
              type={switchItem ? "text" : "password"}
              name="password"
              id="password"
              value={password || ""}
              onChange={(
                e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
              ) => setPassword(e.target.value)}
              placeholder="Password"
              maxLength={12}
              minLength={1}
            />
            {password && (
              <img
                style={{ cursor: "pointer" }}
                src={
                  !switchItem ? "/images/visibility.svg" : "/images/eyePass.svg"
                }
                alt="Username"
                className="showpass"
                onClick={handlePasswordView}
              />
            )}{" "}
            {validator.current.message("Password", password, "required", {
              className: "text-danger",
            })}
          </div>

          {/* <div className="form-row d-flex align-items-center justify-content-between rememberme-password">
            <label className="login__rememberme">
              <input
                onChange={(e) => setRemember(e.target.checked)}
                name="remember"
                type="checkbox"
                checked={remember}
                id="remember"
                value={remember}
              />
              <span className="ms-2">Remember me</span>
            </label>
            <p className="lost_password underline">
              <a
                href="/forget-password"
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/forget-password");
                }}
              >
                Forgot password ?
              </a>
            </p>
          </div> */}
          <div className="text-center mt-3">
            <>
              <Button
                variant="btn btn-lg btn-primary"
                type="submit"
                className="ms-4"
                disabled={disableButton}
                onClick={validateForm}
                id={"submitBtn"}
              >
                Login
              </Button>
              {/* <Button
                variant="btn btn-lg btn-secondary"
                type="button"
                className="ms-4"
                // disabled={disableButton}
                onClick={() => {
                  navigate("/company-registartion");
                }}
              >
                New Firm
              </Button> */}
            </>
          </div>
        </form>
      </div>
    </>
  );
}

export default Login;
