import React, { useEffect, useRef, useState } from "react";
import swal from "sweetalert";
import SimpleReactValidator from "simple-react-validator";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Card, Col, Row } from "react-bootstrap";
import { TextInput, Helmet, PasswordInput } from "../../components/FormInputs";
import Breadcrumb from "../../components/Breadcrumb/breadcrumb.component";
import Api from "../../helper/api";
import AuthApi from "../../helper/authApi";
import customFunctions from "../../helper/customFunctions";
import Loader from "../../components/Loader";
import { useDispatch } from "react-redux";
import { filter, filterValue } from "../../store/redux_slice";

const AddVendorCompany = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const validator = useRef(new SimpleReactValidator());
  const dispatch = useDispatch();
  const [vendorId, setVendorId] = useState<any>(null);
  const [pageType, setPageType] = useState<string>("add");
  const [title, setTitle] = useState<string>("Add Vendor Company");
  const [count, forceUpdate] = useState<number>(0);
  const [disableButton, setDisableButton] = useState<boolean>(false);
  const [isShowLoader, setShowLoader] = useState<boolean>(false);
  const [currentUserId, setCurrentUserId] = useState<number>(0);
  const [roleId, setRoleId] = useState<any>(null);

  const [vendorDetails, setVendorDetails] = useState<any>({
    firstName: "",
    middleName: "",
    lastName: "",
    // designation: "",
    aadharNumber: "",
    mobileNumber: "",
    companyName: "",
    companyAddress: "",
    companyEmail: "",
    companyMobile: "",
    vendorNumber: "",
    panCard: "",
    gstNumber: "",
    sapNumber: "",
    pfNumber: "",
    esicNumber: "",
  });

  const [loginDetails, setLoginDetails] = useState<any>({
    email: "",
    password: "",
  });

  const items: any = [
    { to: "/", label: "Dashboard" },
    { to: "/vendor", label: "Vendor Company" },
    { label: title },
  ];

  useEffect(() => {
    getUserRole();
    if (state && state?.pageType === "add") return;
    if (state) {
      if (state?.pageType === "edit") {
        setVendorId(state?.id);
        setPageType("edit");
        setTitle("Edit Vendor Company");
      }
    }
  }, [state]);

  useEffect(() => {
    if (!vendorId) return;
    getVendorCompanyList();
  }, [vendorId]);

  const getUserRole = async () => {
    const roleId: any = await localStorage.getItem("role_id");
    setRoleId(JSON.parse(roleId));
    const currentUserId: any = customFunctions.getLocalStorage("id");
    setCurrentUserId(Number(currentUserId));
  };

  const getVendorCompanyList = async () => {
    setShowLoader(true);
    const { data, message } = await AuthApi.getDataFromServer(
      `${Api.vendorUrl}?id=${vendorId}`
    );
    if (data && data.success === true) {
      setShowLoader(false);
      if (data && data.data) {
        let vendorData: any = data.data.response[0];
        setVendorDetails({
          firstName: vendorData?.user?.fname,
          middleName: vendorData?.user?.mname,
          lastName: vendorData?.user?.lname,
          // designation: vendorData,
          vendorNumber: vendorData?.vendor_no,
          aadharNumber: vendorData?.user?.adhar_card,
          mobileNumber: vendorData?.user?.phone_no,
          companyName: vendorData?.comp_name,
          companyAddress: vendorData?.local_address,
          companyEmail: vendorData?.office_email,
          companyMobile: vendorData?.office_phoneNo,
          panCard: vendorData?.pan_no,
          gstNumber: vendorData?.vendor_tax_values[0]?.GST_No,
          sapNumber: vendorData?.sap_no,
          pfNumber: vendorData?.vendor_tax_values[0]?.PF_No,
          esicNumber: vendorData?.vendor_tax_values[0]?.ESI_No,
        });
        setLoginDetails({
          email: vendorData?.user?.email,
          password: vendorData?.user?.password,
        });
      }
    } else {
      setShowLoader(false);
      swal(message, "", "error");
    }
  };

  const onSubmit = (e: any) => {
    if (loginDetails.password != '') {
      validator.current.errorMessages.password = null;
      validator.current.fields.password = true;
    }
    e.preventDefault();
    if (disableButton) return;
    setDisableButton(true);

    if (validator.current.allValid()) {
      AddVendor();
    } else {
      setDisableButton(false);
      validator.current.showMessages();
      forceUpdate(count + 1);
    }
  };

  const AddVendor = async () => {
    setShowLoader(true);
    const postData: any = {
      vendor_no: vendorDetails?.vendorNumber,
      comp_name: vendorDetails?.companyName,
      local_address: vendorDetails?.companyAddress ? vendorDetails?.companyAddress : null,
      office_email: vendorDetails?.companyEmail ? vendorDetails?.companyEmail : null,
      office_phoneNo: vendorDetails?.companyMobile,
      fname: vendorDetails?.firstName,
      mname: vendorDetails?.middleName,
      lname: vendorDetails?.lastName,
      adhar_card: vendorDetails?.aadharNumber,
      phone_no: vendorDetails?.mobileNumber,
      email: loginDetails?.email,
      password: loginDetails?.password,
      roleId: 2,
      pan_no: vendorDetails.panCard,
      sap_no: vendorDetails.sapNumber,
      GST_No: vendorDetails.gstNumber,
      PF_No: vendorDetails.pfNumber,
      ESI_No: vendorDetails.esicNumber ? vendorDetails.esicNumber : null
    };
    if (vendorId) {
      postData.id = vendorId;
      postData.status = 1;
    }

    setShowLoader(false);
    const endPoint: any = vendorId
      ? `${Api.vendorUrl}`
      : `${Api.vendorUrl}`;
    const callback: any = vendorId
      ? AuthApi.putDataToServer
      : AuthApi.postDataToServer;
    const { data, message } = await callback(endPoint, postData);
    if (data && data.success === true) {
      setDisableButton(false);
      swal(
        vendorId
          ? "Vendor company updated successfully!"
          : "Vendor company created successfully!",
        "",
        "success"
      ).then(() => {
        dispatch(filter([]))
        dispatch(filterValue({}))
        navigate("/vendor");
      });
    } else {
      setDisableButton(false);
      setShowLoader(false);
      swal(message, "", "error");
    }
  };

  return (
    <>
      <Helmet title={title} />
      <div className="page-header">
        <div className="title-breadcrumb-section">
          <h2 className="main-content-title tx-24 mg-b-5">{title}</h2>
          <Breadcrumb items={items} />
        </div>
      </div>
      <Loader showLoader={isShowLoader} needFullPage={false} />
      <section className="content-area"></section>
      <Card>
        <Card.Body>
          <h6 className="section-title">Company Details</h6>
          <hr />

          <Row>
            <Col sm={6} md={6} lg={6}>
              <TextInput
                onInputChange={(
                  e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                ) => {
                  const re = /[a-z A-Z 0-9 अ-अः क-ज्ञ – ा ि 	ी ु	ू	े	ै	ो	ौ	ं	ः ]/;
                  if (!e.target.value || re.test(e.target.value)) {
                    setVendorDetails({
                      ...vendorDetails,
                      companyName: e.target.value,
                    });
                  }
                }}
                id="companyName"
                label="Company Name"
                asterisk="*"
                containerClass="form-group"
                type="text"
                name="companyName"
                value={vendorDetails.companyName}
                placeholder="Enter company name"
                errorMessage={validator.current.message(
                  "company name",
                  vendorDetails.companyName,
                  "required"
                )}
                autoComplete="new-companyName"
              />
            </Col>
            <Col sm={6} md={6} lg={6}>
              <TextInput
                onInputChange={(
                  e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                ) => {
                  setVendorDetails({
                    ...vendorDetails,
                    companyAddress: e.target.value,
                  });
                }}
                id="localOfficeAddress"
                label="Local Office Address"
                containerClass="form-group"
                type="text"
                as="textarea"
                name="localOfficeAddress"
                value={vendorDetails.companyAddress}
                placeholder="Enter local office address"
                autoComplete="new-localOfficeAddress"
              />
            </Col>
            <Col sm={6} md={6} lg={6}>
              <TextInput
                onInputChange={(
                  e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                ) => {
                  setVendorDetails({
                    ...vendorDetails,
                    companyEmail: e.target.value,
                  });
                }}
                id="officeEmail"
                label="Office Email"
                containerClass="form-group"
                type="text"
                name="officeEmail"
                value={vendorDetails.companyEmail}
                placeholder="Enter office email"
                autoComplete="new-officeEmail"
              />
            </Col>
            <Col sm={6} md={6} lg={6}>
              <TextInput
                onInputChange={(e: any) => {
                  const re = /^[0-9]*$/;
                  if (!e.target.value || re.test(e.target.value)) {
                    setVendorDetails({
                      ...vendorDetails,
                      companyMobile: e.target.value,
                    });
                  }
                }}
                id="officeMobile"
                label="Office Mobile"
                asterisk="*"
                inputVarible={"+91"}
                containerClass="form-group"
                type="tel"
                name="officeMobile"
                value={vendorDetails.companyMobile}
                placeholder="Enter office mobile"
                errorMessage={validator.current.message(
                  "office mobile",
                  vendorDetails.companyMobile,
                  "required|regex:^([6-9]){1}([0-9]){9}$",
                  {
                    messages: {
                      required: "The office mobile field is required.",
                      regex: "Invalid office mobile number",
                    },
                  }
                )}
                autoComplete="new-officeMobile"
                maxlength={10}
                minlength={10}
              />
            </Col>
            <Col sm={6} md={6} lg={6}>
              <TextInput
                onInputChange={(
                  e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                ) => {
                  setVendorDetails({
                    ...vendorDetails,
                    vendorNumber: e.target.value,
                  });
                }}
                id="vendorDetails"
                asterisk="*"
                label="Vendor Number"
                containerClass="form-group"
                type="text"
                name="vendorNumber"
                value={vendorDetails.vendorNumber}
                placeholder="Enter vendor number"
                autoComplete="new-vendorNumber"
                errorMessage={validator.current.message(
                  "vendor number",
                  vendorDetails.vendorNumber,
                  "required"
                )}
              />
            </Col>
          </Row>
          {[1, 3].includes(Number(roleId)) ? (
            <>
              <h6 className="section-title">Contact Person</h6>
              <hr />
              <Row>
                <Col sm={4} md={4} lg={4}>
                  <TextInput
                    onInputChange={(
                      e: React.ChangeEvent<
                        HTMLTextAreaElement | HTMLInputElement
                      >
                    ) => {
                      const re = /^[a-zA-Z ]*$/;
                      if (!e.target.value || re.test(e.target.value)) {
                        setVendorDetails({
                          ...vendorDetails,
                          firstName: e.target.value,
                        });
                      }
                    }}
                    id="firstName"
                    label="First Name"
                    asterisk="*"
                    containerClass="form-group"
                    type="text"
                    name="firstName"
                    value={vendorDetails.firstName}
                    placeholder="Enter first name"
                    errorMessage={validator.current.message(
                      "firstName",
                      vendorDetails.firstName,
                      "required|alpha_space"
                    )}
                  />
                </Col>
                <Col sm={4} md={4} lg={4}>
                  <TextInput
                    onInputChange={(
                      e: React.ChangeEvent<
                        HTMLTextAreaElement | HTMLInputElement
                      >
                    ) => {
                      const re = /^[a-zA-Z ]*$/;
                      if (!e.target.value || re.test(e.target.value)) {
                        setVendorDetails({
                          ...vendorDetails,
                          middleName: e.target.value,
                        });
                      }
                    }}
                    id="middleName"
                    label="Middle Name"
                    containerClass="form-group"
                    type="text"
                    name="middleName"
                    value={vendorDetails.middleName}
                    placeholder="Enter middle name"
                  />
                </Col>
                <Col sm={4} md={4} lg={4}>
                  <TextInput
                    onInputChange={(
                      e: React.ChangeEvent<
                        HTMLTextAreaElement | HTMLInputElement
                      >
                    ) => {
                      const re = /^[a-zA-Z ]*$/;
                      if (!e.target.value || re.test(e.target.value)) {
                        setVendorDetails({
                          ...vendorDetails,
                          lastName: e.target.value,
                        });
                      }
                    }}
                    id="lastName"
                    label="Last Name"
                    asterisk="*"
                    containerClass="form-group"
                    type="text"
                    name="lastName"
                    value={vendorDetails.lastName}
                    placeholder="Enter last name"
                    errorMessage={validator.current.message(
                      "lastName",
                      vendorDetails.lastName,
                      "required|alpha_space"
                    )}
                  />
                </Col>
                {/* <Col sm={6} md={6} lg={6}>
                  <TextInput
                    onInputChange={(
                      e: React.ChangeEvent<
                        HTMLTextAreaElement | HTMLInputElement
                      >
                    ) => {
                      const re = /^[a-zA-Z ]*$/;
                      if (!e.target.value || re.test(e.target.value)) {
                        setVendorDetails({
                          ...vendorDetails,
                          designation: e.target.value,
                        });
                      }
                    }}
                    id="designation"
                    label="Designation"
                    containerClass="form-group"
                    type="text"
                    name="designation"
                    value={vendorDetails.designation}
                    placeholder="Enter designation"
                  />
                </Col> */}
                <Col sm={4} md={4} lg={4}>
                  <TextInput
                    onInputChange={(e: any) => {
                      const re = /^[0-9]*$/;
                      if (!e.target.value || re.test(e.target.value)) {
                        setVendorDetails({
                          ...vendorDetails,
                          mobileNumber: e.target.value,
                        });
                      }
                    }}
                    id="mobileNumber"
                    label="Mobile Number"
                    asterisk="*"
                    inputVarible={"+91"}
                    containerClass="form-group"
                    type="tel"
                    name="mobileNumber"
                    value={vendorDetails.mobileNumber}
                    placeholder="Enter mobile number"
                    errorMessage={validator.current.message(
                      "mobile number",
                      vendorDetails.mobileNumber,
                      "required|regex:^([6-9]){1}([0-9]){9}$",
                      {
                        messages: {
                          required: "The mobile number field is required.",
                          regex: "Invalid mobile number",
                        },
                      }
                    )}
                    autoComplete="new-mobileNumber"
                    maxlength={10}
                    minlength={10}
                  />
                </Col>
                <Col sm={4} md={4} lg={4}>
                  <TextInput
                    onInputChange={(e: any) => {
                      const re = /^[0-9]*$/;
                      if (!e.target.value || re.test(e.target.value)) {
                        setVendorDetails({
                          ...vendorDetails,
                          aadharNumber: e.target.value,
                        });
                      }
                    }}
                    id="aadharNumber"
                    label="Aadhar Number"
                    asterisk="*"
                    containerClass="form-group"
                    type="text"
                    name="aadharNumber"
                    value={vendorDetails.aadharNumber}
                    // disabled={pageType === "edit"}
                    placeholder="Enter aadhar number"
                    errorMessage={validator.current.message(
                      "aadhar number",
                      vendorDetails.aadharNumber,
                      "required|regex:^([0-9]){12}$",
                      {
                        messages: {
                          required: "The aadhar number field is required.",
                          regex: "Invalid aadhar number",
                        },
                      }
                    )}
                    autoComplete="new-aadharNumber"
                    maxlength={12}
                    minlength={12}
                  />
                </Col>
              </Row>

              <h6 className="section-title">Login Details</h6>
              <hr />
              <Row>
                <Col sm={6} md={6} lg={6}>
                  <TextInput
                    onInputChange={(
                      e: React.ChangeEvent<
                        HTMLTextAreaElement | HTMLInputElement
                      >
                    ) => {
                      setLoginDetails({
                        ...loginDetails,
                        email: e.target.value,
                      });
                    }}
                    id="email"
                    asterisk="*"
                    label="Email"
                    containerClass="form-group"
                    type="text"
                    name="email"
                    value={loginDetails.email}
                    placeholder="Enter email"
                    autoComplete="new-email"
                    errorMessage={validator.current.message(
                      "email",
                      loginDetails.email,
                      "required|email"
                    )}
                  />
                </Col>
                {
                  pageType == 'add' &&

                  <Col sm={6} md={6} lg={6}>
                    <PasswordInput
                      onInputChange={(
                        e: React.ChangeEvent<
                          HTMLTextAreaElement | HTMLInputElement
                        >
                      ) => {
                        setLoginDetails({
                          ...loginDetails,
                          password: e.target.value,
                        });
                      }}
                      id="password"
                      name="password"
                      containerClass="form-group"
                      inputClass="form-control"
                      label="Password"
                      asterisk="*"
                      value={loginDetails.password}
                      type="password"
                      minlength="6"
                      placeholder="Enter password"
                      errorMessage={validator.current.message(
                        "password",
                        loginDetails.password,
                        "required",
                        {
                          className: "text-danger",
                        }
                      )}
                      autoComplete="new-password"
                      switchElement="true"
                    />
                  </Col>
                }
              </Row>
            </>
          ) : null}
          <h6 className="section-title">Vendor Details</h6>
          <hr />
          <Row>
            <Col sm={6} md={6} lg={6}>
              <TextInput
                onInputChange={(
                  e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                ) => {
                  const re = /^[0-9a-zA-Z]*$/;
                  if (!e.target.value || re.test(e.target.value)) {
                    setVendorDetails({
                      ...vendorDetails,
                      panCard: e.target.value.toUpperCase(),
                    });
                  }
                }}
                id="panCard"
                label="PAN Card"
                asterisk="*"
                containerClass="form-group"
                type="text"
                name="panCard"
                value={vendorDetails.panCard}
                placeholder="Enter pan card"
                errorMessage={validator.current.message(
                  "pan card",
                  vendorDetails.panCard,
                  "required|regex:^([A-Z]){5}([0-9]){4}([A-Z]){1}$",
                  {
                    messages: {
                      required: "The pan card number field is required",
                      regex: "Invalid pan card number",
                    },
                  }
                )}
                autoComplete="new-panCard"
                maxlength={10}
                minlength={10}
              />
            </Col>
            <Col sm={6} md={6} lg={6}>
              <TextInput
                onInputChange={(
                  e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                ) => {
                  const re = /^[a-zA-Z0-9]*$/;
                  if (!e.target.value || re.test(e.target.value)) {
                    setVendorDetails({
                      ...vendorDetails,
                      gstNumber: e.target.value.toUpperCase(),
                    });
                  }
                }}
                id="gstNumber"
                asterisk="*"
                label="GST Number"
                containerClass="form-group"
                type="text"
                name="gstNumber"
                value={vendorDetails.gstNumber}
                placeholder="Enter gst number"
                autoComplete="new-gstNumber"
                maxlength={15}
                minlength={15}
                errorMessage={validator.current.message(
                  "gst number",
                  vendorDetails.gstNumber,
                  "required|regex:^([0-9]){2}([A-Z]){5}([0-9]){4}([A-Z]){1}([A-Z0-9]){1}Z([A-Z0-9]){1}$",
                  {
                    messages: {
                      regex: "Invalid gst number.",
                      required: "The gst number field is required.",
                    },
                  }
                )}
              />
            </Col>
            <Col sm={6} md={6} lg={6}>
              <TextInput
                onInputChange={(
                  e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                ) => {
                  const re = /^[a-zA-Z0-9]*$/;
                  if (!e.target.value || re.test(e.target.value)) {
                    setVendorDetails({
                      ...vendorDetails,
                      sapNumber: e.target.value,
                    });
                  }
                }}
                id="sapNumber"
                asterisk="*"
                label="Vendor Number Through SAP"
                containerClass="form-group"
                type="text"
                name="sapNumber"
                disabled={pageType == 'edit'}
                value={vendorDetails.sapNumber}
                placeholder="Enter vendor number through sap"
                autoComplete="new-sapNumber"
                errorMessage={validator.current.message(
                  "vendor number through sap",
                  vendorDetails.sapNumber,
                  "required"
                )}
              />
            </Col>
            <Col sm={6} md={6} lg={6}>
              <TextInput
                onInputChange={(
                  e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                ) => {
                  const re = /^[a-z A-Z 0-9]*$/;
                  if (!e.target.value || re.test(e.target.value)) {
                    setVendorDetails({
                      ...vendorDetails,
                      pfNumber: e.target.value.toUpperCase(),
                    });
                  }
                }}
                id="pfNumber"
                asterisk="*"
                label="PF Number"
                containerClass="form-group"
                type="text"
                name="pfNumber"
                value={vendorDetails.pfNumber}
                placeholder="Enter pf number"
                autoComplete="new-pfNumber"
                errorMessage={validator.current.message(
                  "pf number",
                  vendorDetails.pfNumber,
                  "required|regex:^[A-Z]{2}[\\s\\/]?[A-Z]{3}[\\s\\/]?[0-9]{7}[\\s\\/]?[0-9]{3}[\\s\\/]?[0-9]{7}$",
                  {
                    messages: {
                      regex: "Invalid pf number.",
                      required: "The pf number field is required.",
                    },
                  }
                )}
                minlength={22}
                maxlength={22}
              />
            </Col>
            <Col sm={6} md={6} lg={6}>
              <TextInput
                onInputChange={(
                  e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                ) => {
                  const re = /^[a-zA-Z0-9]*$/;
                  if (!e.target.value || re.test(e.target.value)) {
                    setVendorDetails({
                      ...vendorDetails,
                      esicNumber: e.target.value,
                    });
                  }
                }}
                id="esicNumber"
                label="ESIC Number"
                containerClass="form-group"
                type="text"
                name="esicNumber"
                value={vendorDetails.esicNumber}
                placeholder="Enter esic number"
                autoComplete="new-esicNumber"
              />
            </Col>
          </Row>
          {pageType !== "view" && (
            <Col>
              <div style={{ float: "left" }} className="form-group mt-3">
                <Button
                  type="submit"
                  variant="primary"
                  onClick={(e) => {
                    e.preventDefault();
                    onSubmit(e);
                  }}
                  disabled={disableButton}
                >
                  Submit
                </Button>
                <Button
                  onClick={(e) => {
                    e.preventDefault();
                    navigate("/vendor");
                  }}
                  variant="secondary"
                  className="ms-2"
                >
                  Cancel
                </Button>
              </div>
              <br />
            </Col>
          )}
        </Card.Body>
      </Card>
    </>
  );
};

export default AddVendorCompany;