import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';

interface PaymentPopupProps {
  show: boolean;
  handleClose: () => void;
  amount: number;
  onPaymentSuccess: (response: any) => void;
  onPaymentFailure: (error: any) => void;
}

const PaymentPopup: React.FC<PaymentPopupProps> = ({ show, handleClose, amount, onPaymentSuccess, onPaymentFailure }) => {
  const handlePayment = () => {
    // Replace with your third-party payment integration logic
    const options = {
      key: 'YOUR_KEY_HERE', // Your payment gateway key
      amount: amount * 100, // Converting to paise if amount is in INR
      currency: 'INR',
      name: 'Your Company Name',
      description: 'Payment for services',
      image: '/your-logo.png', // Add your company logo here
      handler: function (response: any) {
        onPaymentSuccess(response);
      },
      prefill: {
        name: 'John Doe',
        email: 'john.doe@example.com',
        contact: '9999999999',
      },
      theme: {
        color: '#F37254',
      },
    };

    // const paymentObject = new window.Razorpay(options);
    // paymentObject.open();
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Confirm Payment</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h5>Amount to be paid: ₹{amount}</h5>
        <p>Proceed with the payment?</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handlePayment}>
          Pay ₹{amount}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PaymentPopup