import React from 'react';

function Breadcrumb(props:any) {
  const { items } = props;
  return (
    <>
      <ol className="breadcrumb">
        {items &&
          items.map(({ to, label }:any) => (
            <li key={`key-${to}`} className="breadcrumb-item active">
              <a href={to} onClick={()=>{if(to === '/') localStorage.setItem('menuIndex','0') }}>{label}</a>
            </li>
          ))}
      </ol>
    </>
  );
}

export default Breadcrumb;
