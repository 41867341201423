import React, { useEffect, useState } from "react";
import { Route, useNavigate, Routes } from "react-router-dom";
import { useDispatch } from "react-redux";
import RouterFile from "./routes";
import Login from "./containers/authentication/Login/loginComponent";
import ForgetPassword from "./containers/authentication/Forget/forgetPasswordComponent";
import ResetPassword from "./containers/authentication/Forget/resetPasswordComponent";
import TokenVerificationComponent from "./containers/authentication/Forget/tokenVerificationComponent";
import { changeLogin } from "./store/redux_slice";

function App() {
  const token = localStorage.getItem("access_token");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const path = window.location.pathname.split("/");
  const extractedPath = path.length > 1 ? path[1].toString() : "";
  const checkLoggedIn = () => {
    if (!token || token === null) {
      if (
        extractedPath !== "login" &&
        extractedPath !== "forget-password" &&
        extractedPath !== "update-password" &&
        extractedPath !== "reset-password"
      ) {
        navigate("/login");
      }
    }
  };

  useEffect(() => {
    if (!token || token === null) {
      setIsLoggedIn(false);
      // navigate("/login");
      // dispatch({ type: "LOGIN", value: false });
      dispatch(changeLogin(false));
      return;
    } else {
      setIsLoggedIn(true);
      // dispatch({ type: "LOGIN", value: true });
      dispatch(changeLogin(true));
      return;
    }
  }, [token,dispatch]);

  useEffect(() => {
    checkLoggedIn();
  });

  return (
    <>
      {isLoggedIn ? (
        <Routes>
          <Route path="*" element={<RouterFile />} />
        </Routes>
      ) : (
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/forget-password" element={<ForgetPassword />} />
          <Route path="/update-password" element={<ResetPassword />} />
          <Route path="/reset-password" element={<TokenVerificationComponent />} />
        </Routes>
      )}
    </>
  );
}

export default App;
