import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import SimpleReactValidator from "simple-react-validator";
import { Form, Button, Image, Row, Col } from "react-bootstrap";
import swal from "sweetalert";
import { TextInput } from "../../../components/FormInputs";
import customFunctions from "../../../helper/customFunctions";
import AuthApi from "../../../helper/authApi";
import Api from "../../../helper/api";
import Loader from "../../../components/Loader";
import { changeLogin } from "../../../store/redux_slice";

const ForgetPassword = () => {
  const validator = useRef(new SimpleReactValidator());
  const navigate = useNavigate();
  const [userData, setUserData] = useState<any>({ email: "" });
  const [count, forceUpdate] = useState<number>(0);
  const [isShowLoader, setShowLoader] = useState<boolean>(false);

  const dispatch = useDispatch();
  useEffect(() => {
    // dispatch({ type: "LOGIN", value: false });
    dispatch(changeLogin(false));
  }, [dispatch]);

  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { name, value } = event.target;
    setUserData((inputValues: any) => {
      return { ...inputValues, [name]: value };
    });
  };

  const getRecoveryPasswordData = async () => {
    setShowLoader(true);
    const payload = {
      email: userData.email,
    };

    const postData = customFunctions.cleanObject(payload);
    const { data, message } = await AuthApi.postDataToServer(
      `${Api.forgotPasswordUrl}`,
      postData
    );

    if (data && data.message) {
      setShowLoader(false);
      swal(data && data.message[0], "", "success", {
        closeOnClickOutside: false,
      }).then(() => {
        navigate("/login");
      });
    } else {
      swal(typeof message === "string" ? message : message[0], "", "error", {
        closeOnClickOutside: false,
      });
    }
  };

  const submitForm = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (validator.current.allValid()) {
      getRecoveryPasswordData();
    } else {
      validator.current.showMessages();
      forceUpdate(count + 1);
    }
  };

  return (
    <div className="login-page recover-password">
      <Loader showLoader={isShowLoader} needFullPage={false} />
      <div className="logo text-center d-flex">
        <Image src="/images/pmclogo.png" />
      </div>
      <Form className="login-form ">
        <div className="title text-center">
          <h1>Recover Password</h1>
        </div>
        <div className="forget-input">
          <Row>
            <Col>
              <TextInput
                inputIcon="/images/user.svg"
                containerClass="form-group"
                inputClass="form-control form-control-lg"
                type="email"
                name="email"
                onInputChange={handleInputChange}
                value={userData.email}
                placeholder="Enter registered email address"
                errorMessage={validator.current.message(
                  "email",
                  userData.email,
                  "required|email"
                )}
              />
            </Col>
          </Row>
        </div>
        <div className="text-center mb-2">
          <Button
            type="submit"
            className="btn btn-lg btn-primary"
            onClick={submitForm}
          >
            Reset
          </Button>
        </div>
        <div className="text-center">
          <a href="/login" className="back-to-login d-inline underline">
            Back to Login
          </a>
        </div>
      </Form>
    </div>
  );
};

export default ForgetPassword;
