import React from "react";
import { Modal, Row, Col } from "react-bootstrap";

const ListPopUp = (props: any) => {
  return (
    <>
      <div>
        <Modal
          show={props.toggle}
          onHide={props.popupClose}
          size={"sm"}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <h5>Vendors</h5>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="">
              <ul>
                {props &&
                  props.data &&
                  props.data.length > 0 &&
                  props.data.map((itm: any) =>
                    itm?.vendor?.comp_name ? (
                      <li>{itm?.vendor?.comp_name}</li>
                    ) : (
                      ""
                    )
                  )}
              </ul>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default ListPopUp;
