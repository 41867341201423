import React, { useEffect, useState } from "react";
import {
  Modal,
  Accordion,
  Row,
  Col,
  Button,
  Card,
  Image,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/Loader";
import customFunctions from "../../helper/customFunctions";
import Table from "../../components/Table/simpletable.component";
import Breadcrumb from "../../components/Breadcrumb/breadcrumb.component";
import { DropdownInput, Helmet, TextInput } from "../../components/FormInputs";
import swal from "sweetalert";
import Api from "../../helper/api";
import AuthApi from "../../helper/authApi";
import SweetAlert from "react-bootstrap-sweetalert";
import UserRestrictions from "../../components/UserRestrictions/userrestrictions.component";
import ListPopUp from "./tenderListModal";
import SelectAsyncPaginate from "../../components/FormInputs/SelectAsyncPaginate";
import { useDispatch } from "react-redux";
import { filter, filterValue } from "../../store/redux_slice";
import { useSelector } from "react-redux";

const TenderListing = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const selector = useSelector<any, any>((state: any) => state);

  const title = "Tenders";
  const [isShowLoader, setShowLoader] = useState<boolean>(false);
  const [rows, setRows] = useState<any>([]);
  const [filterErr, setFilterErr] = useState<any>("");
  const [searchKeyword, setSearchKeyword] = useState<any>("");
  const [tenderData, setTenderData] = useState<any>([]);
  const [statusToggle, setStatusToggle] = useState<boolean>(false);
  const [currentUserId, setCurrentUserId] = useState<number>(0);
  const [applyToggle, setApplyToggle] = useState<any>(false);
  const [statusId, setStatusId] = useState<any>("");
  const [tenderId, setTenderId] = useState<any>(null);
  const [currentUser, updateCurrentUser] = useState<any>(0);
  const [loginVendor, updateLoginVendor] = useState<any>("");
  const [loginDepartment, updateLoginDepartment] = useState<any>("");
  const [selectedDepartment, setSelectedDepartment] = useState<any>(null);
  const [departmentList, setDepartmentList] = useState([]);
  const [toggle, setToggle] = useState<boolean>(false);
  const [venderNames, setVenderNames] = useState<any>([]);

  const initialPagination: any = {
    currentPage: 1,
    itemsPerPage: 10,
    totalItems: 1,
  };

  const [pagination, setPagination] = useState<any>(initialPagination);

  const getUserRole = async () => {
    const roleId = await customFunctions.getLocalStorage("role_id");
    const loginVendor = await customFunctions.getLocalStorage("login_vendor");
    const deptId = await customFunctions.getLocalStorage("dept_id");
    updateCurrentUser(Number(roleId));
    updateLoginVendor(Number(loginVendor));
    updateLoginDepartment(Number(deptId));
  };

  useEffect(() => {
    getUserRole();
  }, [currentUser]);

  const columns = [
    {
      label: "Tender Name",
      id: "tender_name",
    },
    {
      label: "Vendor Name",
      id: "vendor_name",
      isDisplay: currentUser == 2 ? false : true,
    },
    {
      label: "Department Name",
      id: "department_name",
      isDisplay: currentUser == 3 ? false : true,
    },
    {
      label: "Work Order Start Date",
      id: "work_order_start_date",
    },
    {
      label: "Work Order End Date",
      id: "work_order_end_date",
    },
    {
      label: "Labour Count",
      id: "labour_count",
    },
    // {
    //   label: "Status",
    //   id: "status",
    // },
    {
      label: [1, 3].includes(Number(currentUser)) ? "Action" : "",
      id: "action",
      roleAccess: [1, 3],
    },
  ];

  const items: any = [
    {
      to: [1, 3].includes(Number(currentUser)) ? "/" : "/vendor-dashboard",
      label: "Dashboard",
    },
    { label: title },
  ];

  useEffect(() => {
    // if(selector?.data_reducer?.filter.length>0){
    //   setTenderData(selector?.data_reducer?.filter);
    //   setSelectedDepartment(selector?.data_reducer?.filterValue?.selectedDepartment)
    // }
    // else
    if (currentUser && currentUser == 2) {
      getTenderByVendorId();
      // getDepartmentByVendorId();
    } else if (currentUser && currentUser == 1) {
      getTenderList();
      // getDepartment();
    } else if (currentUser && currentUser == 3) {
      getTenderByVendorId();
    }
    // handleActionCallback();
  }, [currentUser]);

  // useEffect(() => {
  //   getTenderList();
  // }, []);

  useEffect(() => {
    setTableData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenderData]);

  const getTenderList = async (pageNo?: any, clearFilter?: boolean) => {
    // if (!currentUserId || (!applyToggle && clearFilter && !searchKeyword))
    //   return;
    const page: any = pageNo || 1;
    setShowLoader(true);
    setApplyToggle(false);
    const endPoint: any = `${Api.tenderUrl}/getTenderList`;

    const params: any = {
      page: page,
      limit: pagination.itemsPerPage,
      departmentId:
        !clearFilter && selectedDepartment ? selectedDepartment?.id : "",
      sort_direction: "descending",
    };

    const url: any = customFunctions.generateUrl(endPoint, params);

    const { data, message } = await AuthApi.getDataFromServer(url);
    if (data && data.success === true) {
      setShowLoader(false);
      if (data && data.data.response) {
        setPagination({
          itemsPerPage: Number(10),
          currentPage: data.data.page,
          totalItems: data.data.count,
        });
        setTenderData(data.data.response);
        // dispatch(filter(data.data.response));
        // dispatch(filterValue({selectedDepartment : selectedDepartment}));
      } else {
        setTenderData([]);
      }
    } else {
      setShowLoader(false);
      setTenderData([]);
      swal(message, "", "error");
    }
  };

  const getTenderByVendorId = async (pageNo = 1, isFilter = false) => {
    setShowLoader(true);
    const page = pageNo || 1;

    const params = {
      page: page,
      limit: Number(10),
      // keyword,
      vendorId: currentUser && Number(currentUser) !== 3 ? loginVendor : "",
      departmentId:
        // !isFilter &&
        currentUser &&
        Number(currentUser) !== 3 &&
        selectedDepartment &&
        selectedDepartment.id
          ? selectedDepartment.id
          : currentUser && Number(currentUser) == 3
          ? loginDepartment
          : "",
    };
    const endPoint = `${Api.tenderUrl}/getTenderList`;
    const url = customFunctions.generateUrl(endPoint, params);
    const { data, message } = await AuthApi.getDataFromServer(url);

    if (!customFunctions.checkIfEmpty(data, "O") && data && data.success) {
      setShowLoader(false);
      setPagination({
        itemsPerPage: Number(10),
        currentPage: data.data.page,
        totalItems: data.data.count,
      });
      const rows: any = [];
      if (
        data &&
        data.data &&
        data.data.response &&
        data.data.response.length > 0
      ) {
        // data.data.response.map((items: any) => {
        //   rows.push({
        //     ...items,
        //     tender_name: items?.name,
        //     work_order_start_date: items?.start_date,
        //     work_order_end_date: items?.end_date,
        //     department_name: items.department?.name,
        //     labour_count: items?.labour_count,
        //     vendor_name: items?.vendor_meta[0]?.company_name,
        //   });
        // });
        setTenderData(data.data.response);
      }
    } else {
      setShowLoader(false);
      setTenderData([]);
      swal(message, "", "error", {
        closeOnClickOutside: false,
      });
    }
  };

  const getDepartmentByVendorId = async () => {
    // setShowLoader(true);
    // const params = {
    //   page: 1,
    //   limit: 10,
    //   // findAll: 'findAll',
    //   vendor_id: customFunctions.getLocalStorage("login_vendor"),
    // };
    // const endPoint = `${Api.tenderUrl}/getTenderList?vendorId=${loginVendor}`;
    // const url = customFunctions.generateUrl(endPoint);
    // const { data, message } = await AuthApi.getDataFromServer(url);
    // if (!customFunctions.checkIfEmpty(data, "O") && data && data.success) {
    //   setShowLoader(false);
    //   if (
    //     data &&
    //     data?.data &&
    //     data?.data?.response &&
    //     data?.data?.response?.length > 0
    //   ) {
    //     let deptArray: any = [];
    //     data.data.response.map((dept: any) => {
    //       deptArray.push({
    //         label: dept?.department?.name,
    //         value: dept?.department?.id,
    //       });
    //     });

    //     // let key = 'value';
    //     // deptArray = [...new Map(deptArray.map(item =>
    //     // [item[key], item])).values()];

    //     setDepartmentList(deptArray);
    //   } else {
    //     setShowLoader(false);
    //     setDepartmentList([]);
    //     setTenderData([]);
    //   }
    // } else {
    //   setShowLoader(false);
    //   swal(message || "Something went wrong", "", "error");
    // }

    setShowLoader(true);
    const params = {
      // page: 1,
      // limit: 50,
      findAll: "findAll",
      vendor_id: customFunctions.getLocalStorage("login_vendor"),
    };
    const endPoint = `${Api.tenderUrl}/getTenderList?vendorId=${loginVendor}`;
    const url = customFunctions.generateUrl(endPoint);
    const { data, message } = await AuthApi.getDataFromServer(url);
    if (!customFunctions.checkIfEmpty(data, "O") && data && data.success) {
      setShowLoader(false);
      if (
        data &&
        data.data &&
        data.data.response &&
        data.data.response.length > 0
      ) {
        const deptArray: any = [];
        data.data.response.map((dept: any) => {
          deptArray.push({
            label: dept?.department?.name,
            value: dept?.department?.id,
          });
        });
        const ids = deptArray.map((o: any) => o.value);
        const filtered = deptArray.filter(
          ({ value }: any, index: any) => !ids.includes(value, index + 1)
        );
        setDepartmentList(filtered);
      } else {
        setShowLoader(false);
        setDepartmentList([]);
      }
    } else {
      setShowLoader(false);
      swal(message || "Something went wrong", "", "error");
    }
  };

  const getDepartment = async () => {
    setShowLoader(true);
    const params = {
      page: 1,
      limit: 50,
    };
    const endPoint = Api.departmentUrl;
    const url = customFunctions.generateUrl(endPoint, params);
    const { data, message } = await AuthApi.getDataFromServer(url);
    if (!customFunctions.checkIfEmpty(data, "O") && data && data.success) {
      setShowLoader(false);
      if (data && data?.data && data?.data?.response) {
        const deptArray: any = [];
        data.data.response?.map((dept: any) => {
          deptArray.push({
            label: dept.name,
            value: dept.id,
          });
        });
        setDepartmentList(deptArray);
      } else {
        setShowLoader(false);
        setTenderData([]);
      }
    } else {
      setShowLoader(false);
      swal(message || "Something went wrong", "", "error");
    }
  };

  const handleBulkDelete = async (selectedIds: any) => {
    if (!customFunctions.checkIfEmpty(selectedIds, "A")) {
      const postData = {
        id: selectedIds,
      };
      setShowLoader(true);
      const { data, message } = await AuthApi.deleteDataFromServer(
        `${Api.tenderUrl}/change-tender-status`,
        postData
      );
      if (data) {
        setShowLoader(false);
        swal("Tender deleted sucessfully", "", "success", {
          closeOnClickOutside: false,
        }).then(() => {
          getTenderList(1);
        });
      } else {
        setShowLoader(false);
        swal(message, "", "error", {
          closeOnClickOutside: false,
        });
      }
    }
  };

  const deleteTender = async (id: any) => {
    // if (!tenderId) return;
    setShowLoader(true);
    const postData = {
      id: [id],
    };
    const endpoint: any = `${Api.tenderUrl}/change-tender-status`;
    const { data, message } = await AuthApi.deleteDataFromServer(
      endpoint,
      postData
    );
    if (data && data.success === true) {
      setStatusToggle(false);
      swal("Tender deleted successfully!", "", "success", {
        closeOnClickOutside: false,
      }).then(() => {
        setTenderId(null);
        setStatusId("");
        getTenderList(1);
      });
    } else {
      setShowLoader(false);
      swal(message, "", "error").then(() => {
        setTenderId(null);
        setStatusId("");
        getTenderList(1);
      });
    }
  };

  const setTableData = () => {
    const rowsData: any = [];
    tenderData.map((dt: any) => {
      let newArray: any = dt?.tender_vendors;
      let newArray2: any = [];
      newArray.map((items: any) => newArray2.push(items?.vendor?.comp_name));
      let splitArray =
        newArray2.join().length > 15
          ? newArray2.join().slice(0, 15) + "..."
          : newArray2.join();

      let tNameTitle =
        dt?.name.length > 20 ? dt?.name.slice(0, 20) + "..." : dt?.name;

      rowsData.push({
        id: dt?.id,
        tender_name: <td title={dt?.name}>{tNameTitle}</td>,
        vendor_name:
          splitArray.length > 15 ? (
            <a
              href="javascript:void(0)"
              title={newArray2.join()}
              onClick={(e: any) => {
                e.preventDefault();
                if (splitArray.length <= 15) return;
                setToggle(true);
                setVenderNames(dt.tender_vendors);
              }}
            >
              {splitArray}
            </a>
          ) : (
            splitArray
          ),
        department_name: dt?.department?.name,
        work_order_start_date: customFunctions.getDate(dt?.start_date),
        work_order_end_date: customFunctions.getDate(dt?.end_date),
        labour_count: dt?.labour_count,
        // middle_name: dt?.middle_name,
        tender_amount: dt?.tender_amount,
        labour_rate: dt?.Min_wages,
        // status: (
        //   <>
        //     <div className="switch-check">
        //       <div
        //         className="form-check form-switch"
        //         style={{ marginBottom: "0" }}
        //       >
        //         <input
        //           className="form-check-input"
        //           type="checkbox"
        //           role="switch"
        //           id="flexSwitchCheckDefault"
        //           checked={dt?.status == "1" ? true : false}
        //           onClick={() => {
        //             setStatusToggle(true);
        //             setStatusId(dt?.status == "2" ? "1" : "2");
        //             setTenderId(dt?.id);
        //           }}
        //           title={dt?.status == "1" ? "Active" : "Inactive"}
        //         />
        //       </div>
        //     </div>
        //   </>
        // ),
        action: (
          <>
            {/* <Row className="ms-1"> */}
            {/* <Col sm={6}> */}
            {[1, 3].includes(currentUser) && (
              <a
                href="/"
                className="pl-2 ms-1"
                title={"Edit"}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  navigate("/edit-tender", {
                    state: { id: dt.id, pageType: "edit" },
                  });
                }}
              >
                <Image src="images/edit-button.svg" alt="edit" />
              </a>
            )}
            {/* </Col> */}
            {/* <Col sm={6}> */}
            {Number(currentUser) === 1 && (
              <Image
                title={"Delete"}
                src="images/delete.png"
                alt="delete"
                className="pl-2 ms-1"
                onClick={(e: any) => {
                  e.preventDefault();
                  e.stopPropagation();
                  swal({
                    title: "Are you sure?",
                    text: "you want to delete this tender record.",
                    icon: "warning",
                    buttons: ["Cancel", true],
                    dangerMode: true,
                  }).then((willDelete) => {
                    if (willDelete) {
                      deleteTender(dt?.id);
                    }
                  });
                }}
              />
            )}
            {/* </Col> */}
            {/* </Row> */}
          </>
        ),
      });
      return dt;
    });
    setRows([...rowsData]);
  };

  const changeStatus = async () => {
    // const postData: any = {
    //   id: Number(tenderId),
    //   status: statusId,
    // };
    // if (!tenderId) return;
    // setShowLoader(true);
    // const endpoint: any = `${Api.tenderUrl}/change-tender-status?id=${tenderId}`;
    // const { data, message } = await AuthApi.deleteDataFromServer(endpoint, postData);
    // if (data && data.success === true) {
    //   setStatusToggle(false);
    //   swal("Tender status changed successfully!", "", "success", {
    //     closeOnClickOutside: false,
    //   }).then(() => {
    //     setTenderId(null)
    //     setStatusId("");
    //     getTenderList(1);
    //   });
    // } else {
    //   setShowLoader(false);
    //   swal( message, "", "error").then(() => {
    //     setTenderId(null)
    //     setStatusId("");
    //     getTenderList(1);
    //   });
    // }
  };

  const filterSearch = () => {
    let filterSearchErr: string = "";
    let isValid: boolean = true;
    if (customFunctions.checkIfEmpty(selectedDepartment)) {
      filterSearchErr = "Please select department";
      setFilterErr(filterSearchErr);
      isValid = false;
    }
    if (isValid) {
      setFilterErr("");
      if ([2, 3].includes(currentUser)) {
        getTenderByVendorId();
      } else if ([1].includes(currentUser)) getTenderList(1);
    }
  };

  const onClear = (e: any) => {
    e.preventDefault();
    setFilterErr("");
    setSelectedDepartment("");
    if (currentUser && [2, 3].includes(Number(currentUser))) {
      getTenderByVendorId(1, true);
    } else if (currentUser && [1].includes(Number(currentUser))) {
      getTenderList(1, true);
    }
  };

  return (
    <>
      <Helmet title={title} />
      <div className="page-header">
        <div className="title-breadcrumb-section">
          <h2 className="main-content-title tx-24 mg-b-5">{title}</h2>
          <Breadcrumb items={items} />
        </div>
      </div>
      {/* {statusToggle && (
        <div className="sweetalert">
          <SweetAlert
            title={"Are you sure, do you want to change the status ?"}
            warning
            showCancel
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            // reverseButtons={true}
            onConfirm={() => {
              setStatusToggle(false);
              changeStatus();
            }}
            onCancel={() => {
              setStatusToggle(false);
            }}
          />
        </div>
      )} */}
      <Loader showLoader={isShowLoader} needFullPage={false} />
      <section className="content-area">
        {Number(currentUser) !== 3 && (
          <Accordion defaultActiveKey="1">
            <Accordion.Item eventKey="1">
              <Accordion.Header>
                <h5 className="card-title mb-0">Filters</h5>
              </Accordion.Header>
              <Accordion.Body>
                {filterErr && (
                  <div className="p-3  bg-danger text-white bg-danger-main table-red-bg mb-3">
                    <div className="img-text">
                      <img
                        style={{ cursor: "pointer" }}
                        onClick={(e) => onClear(e)}
                        src="/images/fail.svg"
                        alt="Success"
                      />{" "}
                      {filterErr}
                    </div>
                  </div>
                )}
                <Row className="mt-3">
                  <Col sm={3} md={3} lg={3}>
                    <SelectAsyncPaginate
                      label={undefined}
                      // asterisk="*"
                      // isMulti
                      value={selectedDepartment}
                      clearSelected
                      onSelectChange={(select: any) => {
                        setSelectedDepartment(select);
                      }}
                      selectPlaceholder="Select department"
                      endpoint={
                        currentUser == 1
                          ? `${Api.departmentUrl}`
                          : `${Api.tenderUrl}/getTenderList`
                      }
                      apiname={
                        currentUser == 1 ? "departmentUrl" : "getTenderUrl"
                      }
                      isClearable={true}
                      fromComponent={"tender"}
                      vendorId={
                        currentUser && Number(currentUser) == 2
                          ? loginVendor
                          : ""
                      }
                      departmentId={undefined}
                      key={undefined}
                      className={undefined}
                      disabled={undefined}
                      isSearch={undefined}
                      isMulti={undefined}
                      clearCacheOnID={undefined}
                      clearSelectedByParent={undefined}
                      asterisk={undefined}
                      errorMessage={undefined}
                      adminType={undefined}
                      roleId={undefined}
                      currentUserId={undefined}
                      selectedTenderId={undefined}
                      //   errorMessage={validator.current.message(
                      //   "clinical service",
                      //   patientDetails?.injurySection?.selectedClinicalService,
                      //   "required"
                      // )}
                      id="departmentName"
                    />
                    {/* <DropdownInput
                      placeholder="Select department"
                      options={departmentList}
                      defaultValue={selectedDepartment}
                      onSelectHandler={(select: any) => {
                        setSelectedDepartment(select);
                      }}
                      fromComponent="tender"
                    /> */}
                  </Col>

                  <Col sm={3} md={3} lg={3}>
                    <div className="filter-btn pull-right filter-btn-report">
                      <Button
                        id="submit_btn"
                        variant="primary"
                        type="button"
                        className="ms-4"
                        onClick={(e) => {
                          e.preventDefault();
                          filterSearch();
                        }}
                      >
                        Submit
                      </Button>
                      <Button
                        id="clear_btn"
                        variant="secondary"
                        type="button"
                        className="ms-4"
                        onClick={(e) => onClear(e)}
                      >
                        Clear
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        )}
        <Card className="mt-3">
          <UserRestrictions permittedUsers={[1, 3]} roleId={currentUser}>
            <Card.Header>
              <div className="d-flex align-items-center justify-content-end">
                <a
                  id="add-tender"
                  href="/add-tender"
                  className="underline"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate("/add-tender", {
                      state: { pageType: "add" },
                    });
                  }}
                >
                  Add Tender
                </a>
              </div>
            </Card.Header>
          </UserRestrictions>
          <Card.Body>
            <Table
              columns={columns}
              rows={rows}
              // showCheckbox={currentUser == 1 ? true : false}
              // bulkActionsLabel="Apply"
              // bulkActions={
              //   currentUser &&
              //   currentUser == 1 && [
              //     { actionTitle: "Delete", actionCallback: handleBulkDelete },
              //   ]
              // }
              showPagination
              paginationProps={{
                ...pagination,
                activePage: pagination.currentPage,
                onPageChange: (pageNo: any) => {
                  getTenderList(pageNo);
                },
              }}
              dropdownContainerClass="mb-3"
              emptyRender={() => (
                <p className="text-center mb-0">No record found</p>
              )}
            />
          </Card.Body>
        </Card>
      </section>
      {toggle && (
        <ListPopUp
          toggle={toggle}
          data={venderNames}
          popupClose={() => setToggle(false)}
        />
      )}
    </>
  );
};

export default TenderListing;
