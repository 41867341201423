import React, { useEffect, useRef, useState } from "react";
import SimpleReactValidator from "simple-react-validator";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Card, Col, Row } from "react-bootstrap";
import { TextInput, Helmet, DropdownInput } from "../../components/FormInputs";
import Breadcrumb from "../../components/Breadcrumb/breadcrumb.component";
import swal from "sweetalert";
import Api from "../../helper/api";
import AuthApi from "../../helper/authApi";
import customFunctions from "../../helper/customFunctions";
import Loader from "../../components/Loader";
import SelectAsyncPaginate from "../../components/FormInputs/SelectAsyncPaginate";
import { useDispatch, useSelector } from "react-redux";
import { filter, filterValue } from "../../store/redux_slice";

const AddEmployee = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const selector = useSelector<any, any>((state) => state);
  const validator = useRef(new SimpleReactValidator());
  const { state } = useLocation();

  const [empId, setEmpId] = useState<any>(null);
  const [pageType, setPageType] = useState<string>("add");
  const [title, setTitle] = useState<string>("Add Employee");
  const [count, forceUpdate] = useState<number>(0);
  const [disableButton, setDisableButton] = useState<boolean>(false);
  const [isShowLoader, setShowLoader] = useState<boolean>(false);
  const [currentUser, updateCurrentUser] = useState<any>(0);
  const [loginVendor, updateLoginVendor] = useState<any>("");
  const [deptId, setDeptId] = useState<any>();

  const [empDetails, setEmpDetails] = useState<any>({
    employeeId: null,
    tender: "",
    vendor: "",
    firstName: "",
    middleName: "",
    lastName: "",
    mobileNumber: "",
    aadharNumber: "",
    pfNumber: "",
    esicNumber: "",
    email: "",
    bankName:"",
    bankAccountNumber:"",
    bankIfscCode:"",
    dailyWages:""
  });

  const [tenderValues, setTenderValues] = useState<any>([]);
  const [vendorValues, setVendorValues] = useState<any>([]);
  const items: any = [
    {
      to: [1, 3].includes(Number(currentUser)) ? "/" : "/vendor-dashboard",
      label: "Dashboard",
    },
    { to: "/employee", label: "Employees" },
    { label: title },
  ];

  const getUserRole = async () => {
    const roleId = await customFunctions.getLocalStorage("role_id");
    const loginVendor = await customFunctions.getLocalStorage("login_vendor");
    const departmentId = await customFunctions.getLocalStorage("dept_id");
    setDeptId(Number(departmentId));
    updateCurrentUser(Number(roleId));
    updateLoginVendor(Number(loginVendor));
  };

  useEffect(() => {
    getUserRole();
    // getTenderData();
  }, []);

  useEffect(() => {
    if (currentUser) {
      // getTenderData();
    }
  }, [currentUser]);

  useEffect(() => {
    if (state && state?.fromComponent === "vendorDashboard") {
      // oninit();
      const tender = {
        label: state?.tenderData?.label,
        value: state?.tenderData?.value,
      };
      const vendor = {
        label: state?.venderData?.label,
        value: state?.venderData?.value,
        tender_vendor_id: state?.venderData?.vendor_tender_id,
      };
      setEmpDetails({
        ...empDetails,
        tender: state?.tenderData,
        vendor: vendor,
      });
    }
  }, [state]);

  useEffect(() => {
    // getTenderData();
    if (state && state?.pageType === "add") return;
    if (state?.pageType === "edit") {
      setEmpId(state?.id);
      setPageType("edit");
      setTitle("Edit Employee");
    }
  }, [state]);

  useEffect(() => {
    if (!empId) return;
    getEmployee();
  }, [empId]);

  const getTenderData = async () => {
    setShowLoader(true);
    const endPoint: any = `${Api.tenderUrl}/getTenderList`;

    const params: any = {
      vendorId: [2].includes(currentUser) ? loginVendor : "",
      departmentId: [3].includes(currentUser) ? deptId : "",
    };

    const url: any = customFunctions.generateUrl(endPoint, params);

    const { data, message } = await AuthApi.getDataFromServer(url);
    if (data && data.success === true) {
      let tender = data.data.response;
      setShowLoader(false);
      if (data && data.data) {
        let Arr: any = [];
        tender &&
          tender.length > 0 &&
          tender.map((item: any) => {
            Arr.push({
              label: item?.name,
              value: item?.id,
              tender_vendor_id: item?.tender_vendors[0]?.id,
            });
          });
        setTenderValues(Arr);
      } else {
        setTenderValues([]);
      }
    } else {
      setShowLoader(false);
      setTenderValues([]);
      swal(message, "", "error");
    }
  };

  const getVenderData = async (id?: any) => {
    setShowLoader(true);
    const endPoint: any = `${Api.tenderUrl}/id?id=${id}&limit=50`;
    const url: any = customFunctions.generateUrl(endPoint);
    const { data, message } = await AuthApi.getDataFromServer(url);
    if (data && data.success === true) {
      let vender = data.data.response[0].tender_vendors;
      setShowLoader(false);
      if (data && data.data) {
        let Arr: any = [];
        vender &&
          vender.length > 0 &&
          vender.map((item: any, i: any) => {
            if (item?.vendor) {

              Arr.push({
                label: item?.vendor?.comp_name,
                value: item?.vendor?.id,
                tender_vendor_id: item?.id,
              });
            }
          });
        setVendorValues(Arr);
      } else {
        setVendorValues([]);
      }
    } else {
      setShowLoader(false);
      setVendorValues([]);
      swal(message, "", "error");
    }
  };

  const getEmployee = async () => {
    setShowLoader(true);
    const { data, message } = await AuthApi.getDataFromServer(
      `${Api.employeeUrl}/getAllEmployee?id=${empId}`
    );
    if (data && data?.success === true) {
      setShowLoader(false);
      if (data && data?.data) {
        let employeeData: any = data?.data?.response[0];
        setEmpDetails({
          employeeId: employeeData?.epm_id,
          tender: {
            name: employeeData?.tv_employees[0]?.tender_vendor?.tender?.name,
            id: employeeData?.tv_employees[0]?.tender_vendor?.tender?.id,
          },
          vendor: {
            label: employeeData?.vendor?.comp_name,
            value: employeeData?.vendor?.id,
            tender_vendor_id: employeeData?.tv_employees[0]?.tenderVendorId,
          },
          firstName: employeeData?.fname,
          middleName: employeeData.mname,
          lastName: employeeData?.lname,
          mobileNumber: employeeData?.phone,
          aadharNumber: employeeData?.adhar_card,
          pfNumber: employeeData?.PF_no,
          esicNumber: employeeData?.ESIC_no,
          email: employeeData?.email,
        });
      }
    } else {
      setShowLoader(false);
      swal(message, "", "error");
    }
  };

  const onSubmit = (e: any) => {
    e.preventDefault();
    if (disableButton) return;
    setDisableButton(true);
    if (validator.current.allValid()) {
      // labourCountValidation();
      addEmployee();
      validator.current.hideMessages();
    } else {
      setDisableButton(false);
      validator.current.showMessages();
      forceUpdate(count + 1);
    }
  };

  const labourCountValidation = async () => {
    if (pageType == "edit") {
      addEmployee();
      return;
    }

    let venId = empDetails?.vendor ? empDetails?.vendor?.value : loginVendor;
    let tenId = empDetails?.tender ? empDetails?.tender?.value : "";

    setShowLoader(true);
    const { data, message } = await AuthApi.getDataFromServer(
      `${Api.employeeUrl}/getAllEmployee?vendor_id=${venId}&tender_id=${tenId}`
    );
    if (data && data?.success === true) {
      setShowLoader(false);
      if (data && data?.data) {
        let employeeData: any = data?.data?.response[0]?.tv_employees[0];

        if (
          currentUser == 2
            ? loginVendor
            : empDetails?.vendor?.tender_vendor_id ==
            employeeData?.tenderVendorId &&
            data?.data?.count >= employeeData?.tender_vendor?.labour_allocated
        ) {
          swal(
            `Your labour count is ${employeeData?.tender_vendor?.labour_allocated}, So you cannot add an employee to this vendor`,
            "",
            "error"
          );
          return;
        } else {
          addEmployee();
        }
      }
    } else {
      setShowLoader(false);
      swal(message, "", "error");
    }
  };

  const addEmployee = async () => {
    setShowLoader(true);
    let tvid =
      state?.fromComponent === "vendorDashboard"
        ? state?.tenderVendorId
        : empDetails?.tender?.tender_vendor_id;

    const postData: any = {
      fname: empDetails?.firstName,
      mname: empDetails?.middleName ? empDetails?.middleName : null,
      lname: empDetails?.lastName,
      email: empDetails?.email,
      phone: empDetails?.mobileNumber,
      adhar_card: empDetails?.aadharNumber,
      epm_id: empDetails?.employeeId,
      PF_no: empDetails?.pfNumber,
      ESIC_no: empDetails?.esicNumber,
      vendorId: empDetails?.vendor ? empDetails?.vendor?.value : loginVendor,
      tender_vendorId:
        Number(currentUser) == 2 ? tvid : empDetails?.vendor?.tender_vendor_id,
      status: "1",
    };
    if (empId) {
      postData.id = empId;
    }

    const endPoint: any = empId ? `${Api.employeeUrl}` : `${Api.employeeUrl}`;
    const callback: any = empId
      ? AuthApi.putDataToServer
      : AuthApi.postDataToServer;
    const { data, message } = await callback(endPoint, postData);
    if (data && data.success === true) {
      setShowLoader(false);
      setDisableButton(false);
      swal(
        empId
          ? "Employee updated successfully!"
          : "Employee created successfully!",
        "",
        "success"
      ).then(() => {
        // dispatch(filter([]));
        // dispatch(filterValue({}));
        // navigate(state.fromComponent == "vendorDashboard" ? "/vendor-dashboard" : "/employee");
        navigate("/employee");
      });
    } else {
      setDisableButton(false);
      setShowLoader(false);
      swal(message, "", "error");
    }
  };

  return (
    <>
      <Helmet title={title} />
      <div className="page-header">
        <div className="title-breadcrumb-section">
          <h2 className="main-content-title tx-24 mg-b-5">{title}</h2>
          <Breadcrumb items={items} />
        </div>
      </div>
      <Loader showLoader={isShowLoader} needFullPage={false} />
      <section className="content-area"></section>
      <Card>
        <Card.Body>
          <Row>
            <Col sm={4} md={4} lg={4}>
              {/* <DropdownInput
                label="Select Tender"
                asterisk="*"
                placeholder="Select tender"
                options={tenderValues}
                defaultValue={empDetails?.tender}
                disabled={pageType === "edit" || state?.fromComponent === 'vendorDashboard'}
                onSelectHandler={(select: any) => {

                  empDetails.tender = select;
                  empDetails.vendor = '';
                  if (!select) {
                    setVendorValues([])
                  }
                  setEmpDetails({ ...empDetails });
                  {
                    [1, 3].includes(currentUser) && select != null &&
                      getVenderData(select?.value)
                  }
                }}
                isClearable={true}
                errorMessage={validator.current.message(
                  "select tender",
                  empDetails.tender,
                  "required"
                )}
                id="tenderValue"
              /> */}

              <SelectAsyncPaginate
                label="Select tender"
                asterisk="*"
                // isMulti
                value={empDetails?.tender}
                // disabled={[5, 6].includes(roleId) || referralStatus == "1"}
                clearSelected
                selectedTenderId={""}
                onSelectChange={(select: any) => {
                  empDetails.tender = select;
                  empDetails.vendor = "";
                  if (!select) {
                    setVendorValues([]);
                  }
                  setEmpDetails({ ...empDetails });
                  {
                    [1, 3].includes(currentUser) &&
                      select != null &&
                      getVenderData(select?.id);
                  }
                }}
                selectPlaceholder="Select tender"
                endpoint={`${Api.tenderUrl}/getTenderList`}
                apiname={"tenderUrl"}
                key={undefined}
                isClearable={true}
                className={undefined}
                disabled={pageType === "edit" ||
                  state?.fromComponent === "vendorDashboard"}
                isSearch={undefined}
                clearCacheOnID={undefined}
                clearSelectedByParent={undefined}
                adminType={undefined}
                roleId={undefined}
                currentUserId={undefined}
                isMulti={undefined}
                errorMessage={validator.current.message(
                  "select tender",
                  empDetails.tender,
                  "required"
                )}
                id="tenderValue"
                vendorId={[2].includes(currentUser) ? loginVendor : ""}
                departmentId={[3].includes(currentUser) ? deptId : ""}
                fromComponent={undefined}
              />
            </Col>

            {[1, 3].includes(currentUser) && (
              <Col sm={4} md={4} lg={4}>
                <DropdownInput
                  id="vendorValue"
                  label="Select Company/Vendor"
                  asterisk="*"
                  placeholder="Select company/vendor"
                  options={vendorValues}
                  defaultValue={empDetails?.vendor}
                  disabled={
                    pageType === "edit" ||
                    state?.fromComponent === "vendorDashboard"
                  }
                  onSelectHandler={(select: any) => {
                    empDetails.vendor = select;
                    setEmpDetails({ ...empDetails });
                  }}
                  isClearable={true}
                  errorMessage={validator.current.message(
                    "select vendor",
                    empDetails.vendor,
                    "required"
                  )}
                />
              </Col>
            )}

            <Col sm={4} md={4} lg={4}>
              <TextInput
                onInputChange={(
                  e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                ) => {
                  const re = /^[a-z A-Z ]*$/;
                  if (!e.target.value || re.test(e.target.value)) {
                    empDetails.firstName = e.target.value;
                    setEmpDetails({ ...empDetails });
                  }
                }}
                id="firstName"
                label="First Name"
                asterisk="*"
                containerClass="form-group"
                type="text"
                name="firstName"
                value={empDetails?.firstName}
                disabled={pageType === "view"}
                placeholder="Enter first name"
                errorMessage={validator.current.message(
                  "first name",
                  empDetails.firstName,
                  "required"
                )}
                autoComplete="new-firstName"
              />
            </Col>

            <Col sm={4} md={4} lg={4}>
              <TextInput
                onInputChange={(
                  e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                ) => {
                  const re = /^[a-z A-Z ]*$/;
                  if (!e.target.value || re.test(e.target.value)) {
                    empDetails.middleName = e.target.value;
                    setEmpDetails({ ...empDetails });
                  }
                }}
                id="middleName"
                label="Middle Name"
                containerClass="form-group"
                type="text"
                name="middleName"
                value={empDetails.middleName}
                disabled={pageType === "view"}
                placeholder="Enter middle name"
                autoComplete="new-middleName"
              />
            </Col>

            <Col sm={4} md={4} lg={4}>
              <TextInput
                onInputChange={(
                  e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                ) => {
                  const re = /^[a-z A-Z ]*$/;
                  if (!e.target.value || re.test(e.target.value)) {
                    empDetails.lastName = e.target.value;
                    setEmpDetails({ ...empDetails });
                  }
                }}
                id="lastName"
                label="Last Name"
                asterisk="*"
                containerClass="form-group"
                type="text"
                name="lastName"
                value={empDetails.lastName}
                disabled={pageType === "view"}
                placeholder="Enter last name"
                autoComplete="new-lastName"
                errorMessage={validator.current.message(
                  "last name",
                  empDetails.lastName,
                  "required"
                )}
              />
            </Col>

            {/* {
              [1, 3].includes(currentUser) && */}
            <Col sm={4} md={4} lg={4}>
              <TextInput
                onInputChange={(
                  e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                ) => {
                  setEmpDetails({ ...empDetails, email: e.target.value });
                }}
                id="email"
                asterisk="*"
                label="Email"
                containerClass="form-group"
                type="text"
                name="email"
                disabled={pageType === "edit"}
                value={empDetails.email}
                placeholder="Enter email"
                autoComplete="new-email"
                errorMessage={validator.current.message(
                  "email",
                  empDetails.email,
                  "required|email"
                )}
              />
            </Col>
            {/* } */}

            <Col sm={4} md={4} lg={4}>
              <TextInput
                onInputChange={(e: any) => {
                  const re = /^[0-9]*$/;
                  if (!e.target.value || re.test(e.target.value)) {
                    empDetails.mobileNumber = e.target.value;
                    setEmpDetails({ ...empDetails });
                  }
                }}
                id="mobileNumber"
                label="Mobile Number"
                asterisk="*"
                inputVarible={"+91"}
                containerClass="form-group"
                type="tel"
                name="mobileNumber"
                value={empDetails.mobileNumber}
                disabled={pageType === "view"}
                placeholder="Enter mobile number"
                maxlength={10}
                minlength={10}
                errorMessage={validator.current.message(
                  "mobile number",
                  empDetails.mobileNumber,
                  "required|regex:^([6-9]){1}([0-9]){9}$",
                  {
                    messages: {
                      required: "The mobile number field is required.",
                      regex: "Invalid mobile number.",
                    },
                  }
                )}
                autoComplete="new-mobileNumber"
              />
            </Col>

            <Col sm={4} md={4} lg={4}>
              <TextInput
                onInputChange={(e: any) => {
                  const re = /^[0-9]*$/;
                  if (!e.target.value || re.test(e.target.value)) {
                    empDetails.aadharNumber = e.target.value;
                    setEmpDetails({ ...empDetails });
                  }
                }}
                id="aadharNumber"
                label="Aadhar Number"
                asterisk="*"
                containerClass="form-group"
                type="text"
                name="aadharNumber"
                value={empDetails.aadharNumber}
                // disabled={pageType === "edit"}
                placeholder="Enter aadhar number"
                errorMessage={validator.current.message(
                  "aadhar number",
                  empDetails.aadharNumber,
                  "required|regex:^([0-9]){12}$",
                  {
                    messages: {
                      required: "The aadhar number field is required.",
                      regex: "Invalid aadhar number.",
                    },
                  }
                )}
                autoComplete="new-aadharNumber"
                maxlength={12}
                minlength={12}
              />
            </Col>

            <Col sm={4} md={4} lg={4}>
              <TextInput
                onInputChange={(e: any) => {
                  const re = /^[0-9 A-Z a-z]*$/;
                  if (!e.target.value || re.test(e.target.value)) {
                    empDetails.employeeId = e.target.value;
                    setEmpDetails({ ...empDetails });
                  }
                }}
                id="employeeId"
                asterisk="*"
                label="Employee Id"
                containerClass="form-group"
                type="text"
                name="employeeId"
                value={empDetails.employeeId}
                disabled={pageType === "edit"}
                placeholder="Enter employee id"
                autoComplete="new-employeeId"
                // maxlength={8}
                errorMessage={validator.current.message(
                  "Employee Id",
                  empDetails.employeeId,
                  "required"
                )}
              />
            </Col>

            <Col sm={4} md={4} lg={4}>
              <TextInput
                onInputChange={(e: any) => {
                  const re = /^[ a-z 0-9 A-Z ]*$/;
                  if (!e.target.value || re.test(e.target.value)) {
                    empDetails.pfNumber = e.target.value.toUpperCase();
                    setEmpDetails({ ...empDetails });
                  }
                }}
                id="pfNumber"
                asterisk="*"
                label="PF Number"
                containerClass="form-group"
                type="text"
                name="pfNumber"
                // disabled={pageType === "edit"}
                value={empDetails.pfNumber}
                placeholder="Enter PF number"
                autoComplete="new-pfNumber"
                errorMessage={validator.current.message(
                  "pf number",
                  empDetails.pfNumber,
                  "required",
                  // "required|regex:^[A-Z]{2}[\\s\\/]?[A-Z]{3}[\\s\\/]?[0-9]{7}[\\s\\/]?[0-9]{3}[\\s\\/]?[0-9]{7}$",
                  {
                    messages: {
                      // regex: "Invalid pf number.",
                      required: "The pf number field is required.",
                    },
                  }
                )}
                maxlength={22}
                minlength={22}
              />
            </Col>

            <Col sm={4} md={4} lg={4}>
              <TextInput
                onInputChange={(e: any) => {
                  const re = /^[0-9]*$/;
                  if (!e.target.value || re.test(e.target.value)) {
                    empDetails.esicNumber = e.target.value;
                    setEmpDetails({ ...empDetails });
                  }
                }}
                id="esicNumber"
                asterisk="*"
                label="ESIC Number"
                containerClass="form-group"
                type="text"
                name="esicNumber"
                // disabled={pageType === "edit"}
                value={empDetails.esicNumber}
                placeholder="Enter ESIC number"
                autoComplete="new-esicNumber"
                errorMessage={validator.current.message(
                  "ESIC Number",
                  empDetails.esicNumber,
                  "required"
                )}
              />
            </Col>
            {/*  ----------------------------------------------------------------------- */}
            <Col sm={4} md={4} lg={4}>
              <TextInput
                onInputChange={(
                  e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                ) => {
                  const re = /^[a-z A-Z ]*$/;
                  if (!e.target.value || re.test(e.target.value)) {
                    empDetails.bankName = e.target.value;
                    setEmpDetails({ ...empDetails });
                  }
                }}
                id="bankName"
                label="Bank Name"
                asterisk="*"
                containerClass="form-group"
                type="text"
                name="bankName"
                value={empDetails?.bankName}
                disabled={pageType === "view"}
                placeholder="Enter bank name"
                // errorMessage={validator.current.message(
                //   "Bank Name",
                //   empDetails.bankName,
                //   "required"
                // )}
                autoComplete="new-bankName"
              />
            </Col>
            <Col sm={4} md={4} lg={4}>
              <TextInput
                onInputChange={(e: any) => {
                  const re = /^[0-9]*$/;
                  if (!e.target.value || re.test(e.target.value)) {
                    empDetails.bankAccountNumber = e.target.value;
                    setEmpDetails({ ...empDetails });
                  }
                }}
                // id="esicNumber"
                id="accountNumber"
                asterisk="*"
                label="Bank Account Number"
                containerClass="form-group"
                type="text"
                name="accountNumber"
                // disabled={pageType === "edit"}
                value={empDetails.bankAccountNumber}
                placeholder="Enter Bank Account number"
                autoComplete="new-accountNumber"
                // errorMessage={validator.current.message(
                //   "Bank Account Number",
                //   empDetails.bankAccountNumber,
                //   "required"
                // )}
              />
            </Col>

            <Col sm={4} md={4} lg={4}>
              <TextInput
                onInputChange={(e: any) => {
                  const re = /^[0-9]*$/;
                  if (!e.target.value || re.test(e.target.value)) {
                    empDetails.bankIfscCode = e.target.value;
                    setEmpDetails({ ...empDetails });
                  }
                }}
                id="ifscCode"
                asterisk="*"
                label="IFSC Code"
                containerClass="form-group"
                type="text"
                name="ifscCode"
                // disabled={pageType === "edit"}
                value={empDetails.bankIfscCode}
                placeholder="Enter IFSC Code"
                autoComplete="new-ifscCode"
                // errorMessage={validator.current.message(
                //   "IFSC Code",
                //   empDetails.bankIfscCode,
                //   "required"
                // )}
              />
            </Col>

            <Col sm={4} md={4} lg={4}>
              <TextInput
                onInputChange={(e: any) => {
                  const re = /^[0-9]*$/;
                  if (!e.target.value || re.test(e.target.value)) {
                    empDetails.dailyWages = e.target.value;
                    setEmpDetails({ ...empDetails });
                  }
                }}
                id="dailyWages"
                asterisk="*"
                label="Daily wages"
                containerClass="form-group"
                type="text"
                name="dailyWages"
                // disabled={pageType === "edit"}
                value={empDetails.dailyWages}
                placeholder="Enter Daily wages"
                autoComplete="new-dailyWages"
                // errorMessage={validator.current.message(
                //   "Daily wages",
                //   empDetails.dailyWages,
                //   "required"
                // )}
              />
            </Col>
          </Row>

          {pageType !== "view" && (
            <Col>
              <div style={{ float: "left" }} className="form-group mt-3">
                <Button
                  id="submit-btn"
                  type="submit"
                  variant="primary"
                  onClick={(e) => {
                    e.preventDefault();
                    onSubmit(e);
                  }}
                  disabled={disableButton}
                >
                  Submit
                </Button>
                <Button
                  id="cancel-btn"
                  onClick={(e) => {
                    e.preventDefault();
                    // navigate(state.fromComponent == "vendorDashboard" ? "/vendor-dashboard" : "/employee");
                    navigate(-1);
                  }}
                  variant="secondary"
                  className="ms-2"
                >
                  Cancel
                </Button>
              </div>
              <br />
            </Col>
          )}
        </Card.Body>
      </Card>
    </>
  );
};

export default AddEmployee;
