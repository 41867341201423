import React, { useEffect, useRef, useState } from "react";
import { Row, Col, Button, Card, Image } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Datepicker,
  DropdownInput,
  Helmet,
  TextInput,
} from "../../components/FormInputs";
import Loader from "../../components/Loader";
import Api from "../../helper/api";
import AuthApi from "../../helper/authApi";
import customFunctions from "../../helper/customFunctions";
import Breadcrumb from "../../components/Breadcrumb/breadcrumb.component";
import swal from "sweetalert";
import SelectAsyncPaginate from "../../components/FormInputs/SelectAsyncPaginate";
import { saveAs } from "file-saver";
import SimpleReactValidator from "simple-react-validator";

const EmployeeAttendenceReportComponent = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const validator = useRef(new SimpleReactValidator());
  const title = "Employee Attendence";
  const [isShowLoader, setShowLoader] = useState<boolean>(false);
  const [currentUser, updateCurrentUser] = useState<any>(0);
  const [loginVendor, updateLoginVendor] = useState<any>("");
  const [loginVendorName, updateLoginVendorName] = useState<any>("");
  const [loginDepartment, updateLoginDepartment] = useState<any>("");
  const [uploadDocument, setUploadDocument] = useState<any>({
    file: "",
    name: "",
    file_url: "",
    file_name: "",
    id: "",
  });
  const [count, forceUpdate] = useState<number>(0);
  const [selectedMonth, setSelectedMonth] = useState<any>("");
  const [selectedVendor, setSelectedVendor] = useState<any>("");
  const [selectedTender, setSelectedTender] = useState<any>("");
  const [vendorValues, setVendorValues] = useState<any>([]);
  const [fileUploadVendorValues, setFileUploadVendorValues] = useState<any>([]);
  const [fileUploadFilter, setFileUploadFilter] = useState<any>({
    selectedTender: "",
    selectedVendor: "",
    selectedMonth: "",
  });
  const [tenderErrMsg, setTenderErrMsg] = useState<string>("");
  const [vendorErrMsg, setVendorErrMsg] = useState<string>("");
  const [monthErrMsg, setMonthErrMsg] = useState<string>("");
  const [errMsg, setErrMsg] = useState<string>("");
  const items: any = [
    {
      to: [1, 3].includes(Number(currentUser)) ? "/" : "/vendor-dashboard",
      label: "Dashboard",
    },
    { label: title },
  ];

  useEffect(() => {
    if (state && state?.selectedTender) {
      setSelectedTender(state?.selectedTender);
    }
    if (state && state?.selectedVendor) {
      setSelectedVendor(state?.selectedVendor);
    }
    if (state && state?.selectedMonth) {
      setSelectedMonth(state?.selectedMonth);
    }
  }, [state]);

  useEffect(() => {
    getUserRole();
  }, []);

  const getUserRole = async () => {
    const roleId = await customFunctions.getLocalStorage("role_id");
    const loginVendor = await customFunctions.getLocalStorage("login_vendor");
    const vname = await customFunctions.getLocalStorage("login_vendorName");
    const deptId = await customFunctions.getLocalStorage("dept_id");
    updateCurrentUser(Number(roleId));
    updateLoginVendor(Number(loginVendor));
    updateLoginDepartment(Number(deptId));
    updateLoginVendorName(vname);
  };

  const onsubmit = () => {
    if (currentUser == 2) {
      validator.current.errorMessages.vendor = null;
      validator.current.fields.vendor = true;
    }

    if (validator.current.allValid()) {
      downLoadSampleCSV();
    } else {
      validator.current.showMessages();
      forceUpdate(count + 1);
    }
  };

  useEffect(() => {
    if (!selectedTender) return;
    if ([1, 2].includes(currentUser)) {
      getVenderData();
    }
  }, [selectedTender]);

  const getVenderData = async () => {
    setShowLoader(true);
    const endPoint: any = `${Api.tenderUrl}/id?id=${selectedTender?.id}&limit=50`;

    const url: any = customFunctions.generateUrl(endPoint);

    const { data, message } = await AuthApi.getDataFromServer(url);
    if (data && data.success === true) {
      let vender = data.data.response[0]?.tender_vendors;
      setShowLoader(false);
      if (data && data.data) {
        let Arr: any = [];
        vender &&
          vender.length > 0 &&
          vender.map((item: any) => {
            if (currentUser == 2) {
              setSelectedVendor({
                label: item?.vendor?.comp_name,
                value: item?.vendor?.id,
                start_date: item?.start_date,
                end_date: item?.end_date,
              });
              return;
            } else {
              if (item?.vendor) {
                Arr.push({
                  label: item?.vendor?.comp_name,
                  value: item?.vendor?.id,
                  start_date: item?.start_date,
                  end_date: item?.end_date,
                });
              }
            }
          });
        setVendorValues(Arr);
      } else {
        setVendorValues([]);
      }
    } else {
      setShowLoader(false);
      setVendorValues([]);
      swal(message, "", "error");
    }
  };

  useEffect(() => {
    if (!fileUploadFilter?.selectedTender) return;
    if ([1, 2].includes(currentUser)) {
      getFileVenderData();
    }
  }, [fileUploadFilter?.selectedTender]);

  const getFileVenderData = async () => {
    setShowLoader(true);
    const endPoint: any = `${Api.tenderUrl}/id?id=${fileUploadFilter?.selectedTender?.id}&limit=50`;

    const url: any = customFunctions.generateUrl(endPoint);

    const { data, message } = await AuthApi.getDataFromServer(url);
    if (data && data.success === true) {
      let vender = data.data.response[0]?.tender_vendors;
      setShowLoader(false);
      if (data && data.data) {
        let Arr: any = [];
        vender &&
          vender.length > 0 &&
          vender.map((item: any) => {
            if (currentUser == 2) {
              setFileUploadFilter({
                ...fileUploadFilter,
                selectedVendor: {
                  label: item?.vendor?.comp_name,
                  value: item?.vendor?.id,
                  start_date: item?.start_date,
                  end_date: item?.end_date,
                },
              });
              return;
            } else {
              if (item?.vendor) {
                Arr.push({
                  label: item?.vendor?.comp_name,
                  value: item?.vendor?.id,
                  start_date: item?.start_date,
                  end_date: item?.end_date,
                });
              }
            }
          });
        setFileUploadVendorValues(Arr);
      } else {
        setFileUploadVendorValues([]);
      }
    } else {
      setShowLoader(false);
      setFileUploadVendorValues([]);
      swal(message, "", "error");
    }
  };

  const downLoadSampleCSV = async () => {
    setShowLoader(true);

    const endPoint = Api.sampleDownloadCSVUrl;
    const params = {
      tender_id: selectedTender && selectedTender?.id ? selectedTender?.id : "",
      vendor_id:
        currentUser == 2
          ? loginVendor
          : selectedVendor?.value
          ? selectedVendor?.value
          : "",
      salary_month: customFunctions.getDate(selectedMonth),
      isCsv: 1,
    };

    const url = customFunctions.generateUrl(endPoint, params);
    const { data, message } = await AuthApi.getDataFromServer(url);

    if (data && data.success == true) {
      setShowLoader(false);
      if (data) {
        if (data.data != "Record not found.") {
          let filename = data.data.split("/");
          let len = filename[filename.length - 1];
          saveAs(data.data, len);
          swal("File Downloaded Successfully!", "", "success");
          setSelectedTender("");
          setSelectedVendor("");
          setSelectedMonth("");
          setVendorValues([]);
          validator.current.hideMessages();
          forceUpdate(count + 1);
        } else {
          swal(data.data, "", "error");
          validator.current.hideMessages();
          forceUpdate(count + 1);
        }
      }
    } else {
      setShowLoader(false);
      swal(message, "", "error");
      validator.current.hideMessages();
      forceUpdate(count + 1);
    }
  };

  const onDocumentUpload = () => {
    let isValid = true;

    // if (!fileUploadFilter?.selectedTender) {
    //   isValid = false;
    //   setTenderErrMsg("The tender field is required");
    // }

    // if (fileUploadFilter?.selectedTender && !fileUploadFilter?.selectedVendor) {
    //   isValid = false;
    //   setVendorErrMsg("The company/vendor field is required");
    // }

    // if (
    //   fileUploadFilter?.selectedTender &&
    //   fileUploadFilter?.selectedVendor &&
    //   !fileUploadFilter?.selectedMonth
    // ) {
    //   isValid = false;
    //   setMonthErrMsg("The month and year field is required");
    // }

    if (
      !uploadDocument?.file_name
      // &&
      // fileUploadFilter?.selectedTender &&
      // fileUploadFilter?.selectedVendor &&
      // fileUploadFilter?.selectedMonth
    ) {
      isValid = false;
      setErrMsg("Upload file field is required.");
    }

    if (isValid) {
      setTenderErrMsg("");
      setVendorErrMsg("");
      setMonthErrMsg("");
      setErrMsg("");
      uploadCSV();
    }
  };

  const uploadCSV = async () => {
    setShowLoader(true);
    const csvData = new FormData();
    csvData.append("file_name", uploadDocument?.file);

    const endPoint: any = `${Api.documentUploadUrl}`;
    const params = {
      // tender_id:
      //   fileUploadFilter?.selectedTender && fileUploadFilter?.selectedTender?.id
      //     ? fileUploadFilter?.selectedTender?.id
      //     : "",
      // vendor_id:
      //   currentUser == 2
      //     ? loginVendor
      //     : fileUploadFilter?.selectedVendor?.value
      //     ? fileUploadFilter?.selectedVendor?.value
      //     : "",
      // salary_month: customFunctions.getDate(fileUploadFilter?.selectedMonth),
      File: uploadDocument?.file,
    };
    const callback: any = AuthApi.postDataToServer;

    const { data, message } = await callback(endPoint, params);

    if (data && data.success === true) {
      setShowLoader(false);
      swal("Document uploaded successfully!", "", "success");
      setUploadDocument({
        file: "",
        name: "",
        file_url: "",
        file_name: "",
        id: "",
      });
      // setFileUploadFilter({
      //   selectedTender: "",
      //   selectedVendor: "",
      //   selectedMonth: "",
      // });

      forceUpdate(count + 1);
    } else {
      setShowLoader(false);
      swal(message ? message : "Please Select Downloaded CSV", "", "error");
    }
  };

  return (
    <>
      <Helmet title={title} />
      <div className="page-header">
        <div className="title-breadcrumb-section">
          <Button
            className="float-end mb-3 ms-2"
            variant="btn btn-sm btn-primary"
            onClick={() => {
              navigate("/bulk-attendence");
            }}
          >
            {"< Back"}
          </Button>
          <h2 className="main-content-title tx-24 mg-b-5">{title}</h2>
          <Breadcrumb items={items} />
        </div>
      </div>
      <Loader showLoader={isShowLoader} needFullPage={false} />
      <section className="content-area accordion-main">
        <Card className="mt-3">
          <Card.Body>
            <h6 className="section-title">Download CSV</h6>
            <hr />
            <Row>
              <Col sm={3}>
                <div
                  className={
                    currentUser == 1 && !isShowLoader
                      ? "select-role-option"
                      : ""
                  }
                >
                  <SelectAsyncPaginate
                    label="Select Tender"
                    asterisk="*"
                    // isMulti
                    value={selectedTender}
                    // disabled={[5, 6].includes(roleId) || referralStatus == "1"}
                    clearSelected
                    selectedTenderId={""}
                    onSelectChange={(select: any) => {
                      setSelectedTender(select);
                      setSelectedVendor("");
                      setSelectedMonth("");
                      setVendorValues([]);
                    }}
                    selectPlaceholder="Select tender"
                    endpoint={`${Api.tenderUrl}/getTenderList`}
                    apiname={"tenderUrl"}
                    key={undefined}
                    isClearable={selectedTender}
                    className={undefined}
                    disabled={undefined}
                    isSearch={undefined}
                    clearCacheOnID={undefined}
                    clearSelectedByParent={undefined}
                    errorMessage={validator.current.message(
                      "tender",
                      selectedTender,
                      "required"
                    )}
                    id={undefined}
                    adminType={undefined}
                    roleId={undefined}
                    currentUserId={undefined} // errorMessage={validator.current.message(
                    // "clinical service",
                    isMulti={undefined} // patientDetails?.injurySection?.selectedClinicalService,
                    vendorId={currentUser == 2 ? loginVendor : undefined}
                    departmentId={undefined} // "required"
                    fromComponent={undefined} // )}
                    // id="clinicalservice"
                  />
                </div>
              </Col>
              {currentUser == 1 && (
                <Col sm={3}>
                  <div
                    className={
                      currentUser == 1 && !isShowLoader
                        ? "select-role-option"
                        : ""
                    }
                  >
                    <DropdownInput
                      id="vendorValue"
                      label="Select Company/Vendor"
                      asterisk="*"
                      placeholder="Select company/vendor"
                      options={vendorValues}
                      defaultValue={selectedVendor}
                      onSelectHandler={(select: any) => {
                        setSelectedVendor(select);
                        setSelectedMonth("");
                      }}
                      isClearable={true}
                      errorMessage={validator.current.message(
                        "vendor",
                        selectedVendor,
                        "required"
                      )}
                      disabled={!selectedTender}
                    />
                  </div>
                </Col>
              )}
              <Col sm={3}>
                {/* <div
                  className={
                    currentUser == 2 && !isShowLoader
                      ? "select-role-option"
                      : ""
                  }
                > */}
                  <Datepicker
                    peekNextMonth={true}
                    showMonthDropdown={true}
                    isClearable={selectedMonth}
                    showYearDropdown={true}
                    dropdownMode="select"
                    label="Select Month And Year"
                    className="form-control"
                    selected={selectedMonth}
                    asterisk="*"
                    placeholder="Select month and year"
                    dateFormat="yyyy-MM"
                    minDate={
                      selectedVendor?.start_date &&
                      customFunctions.getDatefromMonth(
                        selectedVendor?.start_date
                      )
                    }
                    maxDate={
                      selectedVendor?.end_date &&
                      customFunctions.getDatefromMonth(selectedVendor?.end_date)
                    }
                    onChange={(e: any) => {
                      setSelectedMonth(e);
                      forceUpdate(count + 1);
                    }}
                    disabled={
                      (currentUser == 2 && !selectedTender) || !selectedVendor
                    }
                    showMonthYearPicker
                    errorMessage={validator.current.message(
                      "month and year",
                      selectedMonth,
                      "required"
                    )}
                  />
                {/* </div> */}
              </Col>
              <Col sm={1}>
                <Button
                  className="mt-4"
                  onClick={(e: any) => {
                    e.preventDefault();
                    onsubmit();
                  }}
                >
                  Download
                </Button>
              </Col>
            </Row>
            <h6 className="section-title">Upload CSV</h6>
            <hr />
            <div className="mb-4">
              <Row>
                <Col sm={3}>
                  <div className="file-input-hide">
                    <TextInput
                      style={{ opacity: 0, zIndex: 999 }}
                      className="justify-content-between"
                      title={
                        uploadDocument?.file_name
                          ? uploadDocument?.file_name
                          : ""
                      }
                      onInputChange={(e: any) => {
                        setShowLoader(true);
                        if (!["", undefined, null].includes(e.target.value)) {
                          setUploadDocument({
                            file: e.target.files[0],
                            name: e.target.files[0].name,
                            file_url: "",
                            file_name: e.target.files[0].name,
                            id: "",
                          });
                          setErrMsg("");
                          setShowLoader(false);
                        } else {
                          setUploadDocument({
                            file: "",
                            name: "",
                            file_url: "",
                            file_name: "",
                            id: "",
                          });
                          setShowLoader(false);
                        }
                      }}
                      label={"Upload File"}
                      asterisk="*"
                      type="file"
                      name="file"
                      accept=".csv"
                      // accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                      autocomplete="new-files"
                      errorMessage={errMsg}
                    />
                  </div>
                  <>
                    <div className={"input-group mt-4 border-input-file"}>
                      <span className="choose-file">{"Choose file"}</span>
                      <span className="choosed-file-name-f">
                        <img />
                        {uploadDocument?.file_name
                          ? `${
                              uploadDocument?.file_name?.length > 7
                                ? uploadDocument?.file_name.slice(0, 7) + "..."
                                : uploadDocument?.file_name
                            }`
                          : "No file"}
                      </span>
                    </div>
                  </>
                </Col>
                <Col>
                  <Button
                    className={currentUser == 1 ? "mt-4" : "mt-4"}
                    onClick={(e: any) => {
                      e.preventDefault();
                      onDocumentUpload();
                    }}
                  >
                    Upload
                  </Button>
                </Col>
              </Row>
            </div>
          </Card.Body>
        </Card>
      </section>
    </>
  );
};

export default EmployeeAttendenceReportComponent;
