import { Component } from 'react';

export default class Api extends Component {
  static getBaseUrl = () => {
    const env: string = 'dev';

    let url = '';
    switch (env) {
      case 'production':
        // url = 'https://ca-api.reviewtestlink.com/api/v1/';
        break;

      // case 'dev':
      //   url = 'https://cvportalapi.reviewtestlink.com/api/v1/';
      //   break;

      default:
        url = 'https://cvportalapi.reviewtestlink.com/api/v1/';
        // url = 'http://192.168.0.233:8080/api/v1/'; 
        break;
    }
    return url;
  };

  static s3UrlnoProfile =
    'https://empportal-serverless.s3.ap-south-1.amazonaws.com/noProfile.jpeg';

  static baseUrl = Api.getBaseUrl();
  static loginUrl = `${Api.baseUrl}auth/login`;
  static adminDashboardUrl = `${Api.baseUrl}admin_dashboard`;
  static tokenVerifyUrl = `${Api.baseUrl}auth/verifyToken`;
  static forgotPasswordUrl = `${Api.baseUrl}auth/forgotpassword`;
  static changePasswordUrl = `${Api.baseUrl}auth/createnewpassword`;
  static profileUrl = `${Api.baseUrl}profile`;
  static userUrl = `${Api.baseUrl}user`;
  static employeeUrl = `${Api.baseUrl}employee`;
  static departmentUrl = `${Api.baseUrl}department`;
  static vendorUrl = `${Api.baseUrl}vendor`;
  static resetPasswordUrl = `${Api.baseUrl}auth/resetpassword`;
  static tenderUrl = `${Api.baseUrl}tender`;
  static role = `${Api.baseUrl}role`;
  static generateCertificateUrl = `${Api.baseUrl}tender/generate/certificate`;
  // static documentUploadUrl = `${Api.baseUrl}employee/upload-attendance-csv-report`;
  static attendenceUrl = `${Api.baseUrl}attendence`;
  static csvMapUrl = `${Api.baseUrl}csvMap`;
  static attendenceAddUrl = `${Api.baseUrl}employee-attendance/bulk-employee-attendance`;
  static sampleDownloadCSVUrl = `${Api.baseUrl}employee-attendance/getVendorEmployee`;
  static documentUploadUrl = `${Api.baseUrl}employee-attendance/upload-attendance-csv-report`;

  
}
