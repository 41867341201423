import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import Api from "../../helper/api";
import AuthApi from "../../helper/authApi";
import customFunctions from "../../helper/customFunctions";
import SharedService from "../../services/SharedService/sharedServices";
import swal from "sweetalert";
import { changeLogin } from "../../store/redux_slice";

function Header() {
  const date = new Date().toDateString().split(" ");
  const formattedDate = `${date[0]}, ${date[1]} ${date[2]} ${date[3]} `;
  // const [imageProfile, setImageProfile] = useState<any>("");
  // const [userRole, setUserRole] = useState<any>("");
  // const [currentUserId, setCurrentUserId] = useState<any>(null);
  const [roleId, setRoleId] = useState<any>(null);
  const [name, setName] = useState<any>(null);
  // const [userRoleName, setUserRoleName] = useState<any>("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const event = new Event("Login", { isLogin: false });
  let token = localStorage.getItem("access_token");
  const employeeName = localStorage.getItem("userName");
  // const eventMenu = new Event("MenuChange", { isUpdated: true });

  useEffect(() => {
    const roleId = localStorage.getItem("role_id");
    const dep_name = localStorage.getItem("dep_name");
    const vendor_name = localStorage.getItem("login_vendorName");
    // const userId = localStorage.getItem("id");
    // setCurrentUserId(userId);
    setRoleId(Number(roleId));

    if(Number(roleId) == 1){
      setName('Admin')
    }
    if(Number(roleId) == 2){
      setName(vendor_name)
    }if(Number(roleId) == 3){
      setName(dep_name)
    }
  }, []);

  useEffect(() => {
    let profileImageChange = SharedService.profileImageChange.subscribe(
      (value: any) => {
        getProfileData();
      }
    );

    return () => {
      if (profileImageChange) profileImageChange.unsubscribe();
    };
  }, []);

  // useEffect(() => {
  //   if (!currentUserId) return;
  //   getProfileData();
  // }, [currentUserId]);

  const getProfileData = async () => {
    // const userId = localStorage.getItem("id");

    const { data, message } = await AuthApi.getDataFromServer(
      // `${Api.profileUrl}?id=${currentUserId || userId}`
      `${Api.profileUrl}`
    );

    if (data && data.success === true) {
      if (
        data &&
        data.data &&
        data.data.response &&
        data.data.response.length
      ) {
        // const profileData = data?.data?.response[0];
        // setUserRoleName(profileData.staff_role?.name);
        // setImageProfile(profileData?.profile_picture)
      }
    } else {
      swal(message || "Something went wrong", "", "error");
      return;
    }
  };

  useEffect(() => {
    // const uRole = localStorage.getItem("group");
    // setUserRole(Number(uRole));
    if (!token) {
      const path = window.location.pathname.split("/");
      const extractedPath = path.length > 1 ? path[1].toString() : "";
      if (
        extractedPath !== "login" &&
        extractedPath !== "forget-password" &&
        extractedPath !== "password"
      ) {
        navigate("/login");
      }
    } else {
      // dispatch({ type: "LOGIN", value: true });
      dispatch(changeLogin(true));
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  const onMenuChange = (e: any) => {
    document.dispatchEvent(e);
    return true;
  };

  return (
    <>
      <div className="main-header side-header sticky sticky-pin">
        <div className="container-fluid d-flex align-items-center justify-content-end h-100">
          <div className="me-auto siderbar-toggle-wrapper">
            <img src="/images/hamburger.png" alt="" />
          </div>
          <div className="main-header-right">
            {![1, 2, 7].includes(roleId) ? (
              <div className="day-date">
                <h5>{localStorage.getItem("businessName")}</h5>
              </div>
            ) : null}

            <div className="day-date">
              <h5>{formattedDate}</h5>
            </div>

            <div className="profile-name-img align-items-center">
              <div>
                <h6>{name}</h6>
                {/* <h6>{employeeName}</h6> */}
              </div>
              <div>
                {/* <span style={{ fontSize: "11px" }}>
                  {[1, 2].includes(roleId) ? "Admin" : "Employee"}
                </span> */}
              </div>
            </div>

            {/* <div className="profile-name-img d-flex align-items-center">
              <div className="profile-img">
                <a href="!#">
                  {imageProfile ? (
                    <img src="/images/CA_logo.jpeg" alt="Profile" />
                  ) : (
                    <img src={Api.s3UrlnoProfile} alt="Edit Profile" />
                  )}
                  <img src="/images/user.svg" alt="Edit Profile" />
                </a>
              </div>
            </div> */}

            <div className="dropdown main-header-notification">
              {/* <a
                className="nav-link icon"
                onClick={() => onNotification(0)}
              >
                <img src="/images/notification.png" alt="notification" />
                <span className="badge badge-danger nav-link-badge">
                  {notificationCount === 0 ? '' : notificationCount}
                </span>
              </a>
              <div className="dropdown-menu">
                <div className="header-navheading">
                  <p className="main-notification-text">
                    You have {notificationCount} unread notification
                    <span className="badge badge-pill badge-primary ml-3" onClick={() => {
                      onNotification(1); // eslint-disable-line
                    }}
                    >
                      View all
                    </span>
                  </p>
                </div>
                <div className="main-notification-list">
                  {notification.map(
                    (
                      note,
                      i //eslint-disable-line
                    ) => (
                      <>
                        <div className="media new">
                          <div className="media-body">
                            <p onClick={() => { onMessageClick(note.id); onNotification(1); }} className={note.is_read === false ? 'active-media' : ''} // eslint-disable-line
                            >
                              {note.message}
                            </p>
                            <span>{note.time}</span>
                          </div>
                        </div>
                      </>
                    )
                  )}
                </div>
              </div> */}
            </div>

            {/* <div className="dropdown d-md-flex header-settings">
              <a
                href="!#"
                className="nav-link icon"
                data-toggle="sidebar-right"
                data-target=".sidebar-right"
              >
                <img src="/images/profile-setting.svg" alt="profile" />
              </a>

              <div className="main-header-menu-icon">
                <div className="dropdown-menu">
                  <div className="header-navheading text-center">
                    <h5 className="main-notification-title">
                      {employeeName || ""}
                    </h5>
                  </div>
                  <ul className="m-0 list-unstyled p-0">
                    <li>
                      <Link
                        className="dropdown-item border-top"
                        to={
                          [1, 2, 3].includes(roleId)
                            ? "/profile"
                            : "/employee-profile"
                        }
                        onClick={onMenuChange}
                      >
                        <img
                          src="/images/user.svg"
                          alt="User"
                          className="svg"
                        />{" "}
                        My Profile
                      </Link>
                    </li>

                    <li>
                      <a
                        className="dropdown-item"
                        href="#"
                        onClick={(e:any) => {
                          // customFunctions.clearLocalStorage();
                          // customFunctions.onLogout();
                          // document.dispatchEvent(e);
                          // dispatch({ type: "LOGIN", value: false });
                          navigate("/company-profile");
                        }}
                      >
                        <img
                           src="/images/user.svg"
                           alt="User"
                           className="svg"
                        />{" "}
                        Company profile
                      </a>
                    </li>

                    <li>
                      <Link
                        className="dropdown-item"
                        to="/change-password"
                        onClick={onMenuChange}
                      >
                        <img
                          src="/images/change-password.svg"
                          alt="Change"
                          className="svg"
                        />{" "}
                        Change Password
                      </Link>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        href="/login"
                        onClick={(e: any) => {
                          customFunctions.clearLocalStorage();
                          customFunctions.onLogout();
                          document.dispatchEvent(e);
                          // dispatch({ type: "LOGIN", value: false });
                          dispatch(changeLogin(false));
                          navigate("/login");
                        }}
                      >
                        <img
                          src="/images/logout-profile.svg"
                          alt="Log Out"
                          className="svg"
                        />{" "}
                        Log Out
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default Header;
