import React from 'react';
import Spinner from 'react-bootstrap/Spinner';

function Loader(props:any) {
  const { showLoader } = props;

  return showLoader ? (
    <div className="text-center preloader ">
      <Spinner animation="border" className="center" />
    </div>
  ) : null;
}

export default Loader;