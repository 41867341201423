import React, { useEffect, useRef, useState } from "react";
import { Modal, Row, Col, Button, Form } from "react-bootstrap";
import { DropdownInput, TextInput } from "../../components/FormInputs";
import customFunctions from "../../helper/customFunctions";
import AuthApi from "../../helper/authApi";
import Api from "../../helper/api";
import Loader from "../../components/Loader";
import swal from "sweetalert";
import SimpleReactValidator from "simple-react-validator";
import axios from "axios";

const AttendencePopUp = (props: any) => {
  const validator = useRef(new SimpleReactValidator());
  const [monthsName, setMonthsName] = useState<any>([
    {
      label: "January",
      value: "January",
      month: "01",
    },
    {
      label: "February",
      value: "February",
      month: "02",
    },
    {
      label: "March",
      value: "March",
      month: "03",
    },
    {
      label: "April",
      value: "April",
      month: "04",
    },
    {
      label: "May",
      value: "May",
      month: "05",
    },
    {
      label: "June",
      value: "June",
      month: "06",
    },
    {
      label: "July",
      value: "July",
      month: "07",
    },
    {
      label: "August",
      value: "August",
      month: "08",
    },
    {
      label: "September",
      value: "September",
      month: "09",
    },
    {
      label: "October",
      value: "October",
      month: "10",
    },
    {
      label: "November",
      value: "November",
      month: "11",
    },
    {
      label: "December",
      value: "December",
      month: "12",
    },
  ]);
  const [count, forceUpdate] = useState(0);
  const [isShowLoader, setShowLoader] = useState<any>(false);
  const [attendenceData, setAttendenceData] = useState([
    {
      month: "",
      count: "",
      monthList: monthsName,
    },
  ]);

  const onsubmit = () => {
    if (validator.current.allValid()) {
      generateReport();
    } else {
      validator.current.showMessages();
      forceUpdate(count + 1);
    }
  };

  const generateReport = async () => {
    //     setShowLoader(true);
    let attendence: any = [];

    attendenceData &&
      attendenceData.map((item: any) => {
        attendence.push({
          employeeId: Number(props?.employeeData?.id),
          employeeName: `${props?.employeeData?.fname} ${props?.employeeData?.lname}`,
          DateFormatYMD: `${new Date().getFullYear()}-${item?.month?.month}-01`,
          working_days: `${item?.count}`,
        });
      });

    const postData: any = {
      employee_array: attendence
    };

    const endPint = Api.attendenceAddUrl;
    const { data, message } = await AuthApi.postDataToServer(
      endPint,
      postData
    );

    if (data && data.success == true) {
      setShowLoader(false);
      swal("Attendence Added Successfully!", "", "success");
      props.popupClose();
    } else {
      setShowLoader(false);
      swal(message || "Something went wrong", "", "error");
    }
  };

  const addMoreData = () => {
    const payload = {
      month: "",
      count: "",
      monthList: monthsName,
    };

    const newData = [...attendenceData, payload];
    setAttendenceData(newData);
  };

  const removeData = (index: number) => {
    let previousData = [...attendenceData];
    previousData.splice(index, 1);
    setAttendenceData(previousData);
    forceUpdate(count + 1);
  };

  const removeMonthData = (pIndex: any) => {
    const serviceDetailsArr = [...attendenceData];
    if (serviceDetailsArr && serviceDetailsArr.length) {
      let filterArr = monthsName.filter((sr: any) => {
        return serviceDetailsArr.findIndex(
          (serv: any, i: any) =>
            sr?.value === serv?.month?.value && i !== pIndex
        ) === -1
          ? true
          : false;
      });
      serviceDetailsArr[pIndex].monthList = filterArr?.length ? filterArr : [];
    }
    setAttendenceData(serviceDetailsArr);
    forceUpdate(count + 1);
  };

  return (
    <>
      <div>
        <Loader showLoader={isShowLoader} needFullPage={false} />
        <Modal
          className={"filter-popup"}
          size="lg"
          centered
          onHide={props.popupClose}
          show={true}
          keyboard={false}
          backdrop="static"
        >
          <div className="add-task-popup schedule-filter-popup">
            <Modal.Header className="col-12">
              <span style={{ fontWeight: "bold" }}>Add Attendence</span>
              <button
                onClick={() => props.popupClose()}
                type="button"
                className="btn-close"
              ></button>
            </Modal.Header>
            <Modal.Body>
              <>
                {attendenceData &&
                  attendenceData.length &&
                  attendenceData.map((item: any, index: any) => (
                    <>
                      <Row>
                        <Col sm={4}>
                          <DropdownInput
                            label="Month"
                            asterisk="*"
                            placeholder="Select month"
                            options={item?.monthList}
                            defaultValue={item?.month}
                            onMenuOpen={() => removeMonthData(index)}
                            onSelectHandler={(select: any) => {
                              let newData = attendenceData;
                              newData[index].month = select;
                              setAttendenceData(newData);
                              forceUpdate(count + 1);
                            }}
                            isClearable
                            errorMessage={validator.current.message(
                              "month",
                              item?.month,
                              "required"
                            )}
                          />
                        </Col>
                        <Col sm={4}>
                          <TextInput
                            onInputChange={(e: any) => {
                              let newData = attendenceData;
                              newData[index].count = e.target.value;
                              setAttendenceData(newData);
                              forceUpdate(count + 1);
                            }}
                            asterisk="*"
                            id="localOfficeAddress"
                            label="Total Days"
                            containerClass="form-group"
                            type="text"
                            name="localOfficeAddress"
                            value={item?.count}
                            placeholder="Enter total days"
                            autoComplete="new-localOfficeAddress"
                            errorMessage={validator.current.message(
                              "total days",
                              item?.count,
                              "required"
                            )}
                          />
                        </Col>
                        <Col sm={4}>
                          {/* <div className={index === 0 ? "form-group mt-4" : ""}> */}
                          {attendenceData.length > 1 && index !== 0 && (
                            <button
                              type="button"
                              style={{
                                width: "30px",
                                height: "30px",
                                color: "white",
                                backgroundColor: "#0086cc",
                                border: "#0086cc",
                                marginLeft: "-7px",
                              }}
                              onClick={(e) => {
                                e.preventDefault();
                                removeData(index);
                              }}
                              //   disabled={disableButton}
                            >
                              <h3>-</h3>
                            </button>
                          )}
                          {attendenceData.length && (
                            <button
                              type="button"
                              className={"ml-10 mt-4"}
                              style={{
                                width: "30px",
                                height: "30px",
                                color: "white",
                                backgroundColor: "#0086cc",
                                border: "#0086cc",
                              }}
                              onClick={(e) => {
                                e.preventDefault();
                                addMoreData();
                              }}
                              //   disabled={disableButton}
                            >
                              <h3>+</h3>
                            </button>
                          )}
                          {/* </div> */}
                        </Col>
                      </Row>
                    </>
                  ))}
                <Row>
                  <Col style={{ textAlign: "center", marginTop: "20px" }}>
                    <Button
                      onClick={(e) => {
                        onsubmit();
                      }}
                    >
                      Submit
                    </Button>
                  </Col>
                </Row>
              </>
            </Modal.Body>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default AttendencePopUp;
